// @ts-nocheck
import { Row } from "antd";
import { getIntFromString } from "modules/layout/Layout";
import React from "react";
import deleteContent from '../../assets/deleteContent.svg'

const GivenKudos = props => {
    const { givenKudos, setGivenKudos, deleteBadgeAward, fromGiveFeedback,feedbackById,newKudosAdded, buttonStyle } = props

   
    const handleGivenKudos = async () => {
       
        if (givenKudos && !newKudosAdded) {
            let res = await deleteBadgeAward({ badgeAwardId: getIntFromString(feedbackById?.badgeAward?.edges[0]?.node?.id) })
            if (res) {
                setGivenKudos();
            }
        } else if (givenKudos && newKudosAdded) {
            setGivenKudos()
        } else {
            setGivenKudos()
        }

    }

    return (
        <>
           
            <img src={deleteContent} style={ buttonStyle ? buttonStyle : {}} className="delete-given-kudos-button-give-feedback"  onClick={(e) => handleGivenKudos(e)} />
            {
                fromGiveFeedback ?
                    <img src={givenKudos?.image} className="given-kudos-container"/>
                    :
                    <img src={givenKudos?.image} className="given-kudos-container"/>
                    // <div className="given-kudos-container" >{givenKudos?.badge?.title}</div>
            }
          
           
        </>
    );
};

export default GivenKudos;

