// @ts-nocheck
import React from 'react';
import { Row, Col, Card, Dropdown, Menu, message, Spin, Switch, Pagination, Empty } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { compose } from '../../../core';
import { withApollo } from '@apollo/client/react/hoc'
import { GET_TOKENS_LIST_BY_SURVEY } from '../../../survey/graphql/getTokenBySurvey.gql';
import { withTokenDelete, withCreateTokenBySurvey, with360SurveyByid } from '../../../survey/containers/SurveyOperations'
import { getIntFromString, getResponsesBase64StringFromInt, getSurveyBase64StringFromInt, globalPermissionValidator } from 'modules/look/components/functions';
import Survey360View from '../component/Survey360View';
import { GET_SURVEY_BY_ID } from 'modules/survey-module/graphql/survey.gql';
import { INVITE_LIST_SUBSCRIPTION } from '../graphql/inviteListSubscription.gql';
import { token_360_permission } from 'Permissions/token.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';

const Survey360Container = props => {
    const { client, me, history,userPermission} = props
    const [ tokenList, settokenList ] = React.useState()
    const [ survey_detial, setSurveyDetail ] = React.useState()
    const [ loading, setLoading ] = React.useState( false )
    const [permission, setPermission] = React.useState()
    const mountedRef = React.useRef( true )
    let inviteSub = undefined
    const dataRef = React.useRef()
    React.useEffect( () => {
        return () => {
            if ( inviteSub ) {
                inviteSub.unsubscribe();
            }
        }
    } )

    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(token_360_permission, userPermission)
            setPermission(permission)
        }
    }, [userPermission])

    React.useEffect( () => {
        return () => {
            mountedRef.current = false
        }
    }, [] )

    React.useEffect( () => {
        mountedRef.current = true
    }, [] )

    const getSurveyById = async ( id ) => {
        const { data } = await client.query( {
            query: GET_SURVEY_BY_ID,
            variables: { id: id }
        } );
        if ( data?.SurveyById ) {
            setSurveyDetail( data?.SurveyById )
        }
    }

    const getSurveyTokenList = async ( filterData ) => {
        setLoading( true )
        const { data } = await client.query( {
            query: GET_TOKENS_LIST_BY_SURVEY,
            variables: filterData,
            fetchPolicy: 'network-only'
        } );
        if ( data?.tokenListBySurvey ) {
            settokenList( data?.tokenListBySurvey?.edges?.map( ( { node } ) => node ) )
            dataRef.current = data?.tokenListBySurvey?.edges?.map( ( { node } ) => node )
            setLoading( false )
        }
        inviteSub = client.subscribe( {
            query: INVITE_LIST_SUBSCRIPTION,
            variables: { id: filterData }
        } ).subscribe( {
            next( result ) {
                let cachedata = dataRef.current
                switch ( result.data.surveyTokenSubscription.mutation ) {
                    case 'CREATE':
                        const deletedIfExists = cachedata?.filter( item =>
                            item.id === result.data.surveyTokenSubscription?.token.id
                                ? false
                                : true ).concat( [ result.data.surveyTokenSubscription?.token ] )
                            settokenList( deletedIfExists )
                            dataRef.current = deletedIfExists
                        break
                    case 'DELETE':
                        const deleted = cachedata?.filter( item =>
                            item.id === result.data.surveyTokenSubscription?.token.id
                                ? false
                                : true )
                            settokenList( deleted )
                            dataRef.current = deleted
                        break
                    default:
                        break
                }
            }
        } )

    }
    React.useEffect( () => {
        if ( me ) {
            if ( props?.match?.params?.surveyid ) {
                let id = getSurveyBase64StringFromInt( props?.match?.params?.surveyid )
                let responseid = getResponsesBase64StringFromInt( props?.match?.params?.responseid )

                getSurveyTokenList( { survey: id, responseSourceFor: responseid } )
                getSurveyById( id )
            }
        }
        else if ( me === null ) {
            history.push( '/user/login' )
        }
    }, [ me ] )

    return (


        <>
            {(permission && permission?.required_permission) && (
                 <Survey360View {...props}
                 tokenList={tokenList}
                 onchange={( e ) => getSurveyTokenList( e )}
                 survey_detial={survey_detial}
                 loading={loading}
                 getSurveyTokenList={( filter ) => getSurveyTokenList( filter )}/>
            )}

            {(permission && !permission?.required_permission) && (
                <NoPermissionView />
            )}

            {(!permission) && (
                <div align="center" style={{ padding: "200px" }}>
                    <Spin spinning={true} size='large' />
                </div>
            )}
        </>

       
    )

};

export default compose( withApollo, withTokenDelete, withCreateTokenBySurvey )( Survey360Container );
