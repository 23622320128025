import React from 'react';
import { UserOutlined } from '@ant-design/icons';

import UserAdminPanel from './containers/User';

import Login from './containers/Login';
import Register from './containers/Register';
import Profile from './containers/Profile';
import ActivateAccount from './containers/ActivateAccount';
import MyResponse from './containers/MyResponse';
import RedirectAuthenticate from './containers/RedirectAuthenticate';

import AddUser from './containers/AddUser';
import AddMultipleUser from './containers/AddMultipleUser';
import EditUser from './containers/EditUser';
import PermissionContainer from './containers/permissionContainer'
import ROUTE from './route';

export default [
  {
    name: 'User',
    path: ROUTE.user,
    component: UserAdminPanel,
    adminDropdown: true,
    icon: <UserOutlined />,
    admin: true
  },
  {
    name: 'Login',
    path: ROUTE.login,
    component: Login,
    admin: false,
    protect: false
  },
  // {
  //   name: 'Register',
  //   path: ROUTE.register,
  //   component: Register,
  //   protect: false
  // },
  {
    name: 'Profile',
    path: ROUTE.profile,
    component: Profile,
    protect: false
  },
  {
    name: 'ActivateAccount',
    path: ROUTE.activateAccount,
    component: ActivateAccount
  },
  {
    name: 'MyResponse',
    path: ROUTE.myResponse,
    component: MyResponse,
    protect: true
  },
  {
    name: 'RedirectAuthenticate',
    path: ROUTE.redirectAuthenticate,
    component: RedirectAuthenticate,
    protect: false
  },
  {
    name: 'AddUser',
    path: ROUTE.addUser,
    component: AddUser,
    admin: true
  },
  {
    name: 'AddMultipleUser',
    path: ROUTE.addMultipleUser,
    component: AddMultipleUser,
    admin: true
  },
  {
    name: 'EditUser',
    path: ROUTE.editUser,
    component: EditUser,
    admin: true
  },
  {
    name: 'permissions',
    path: ROUTE.permissions,
    component: PermissionContainer,
  }
];
