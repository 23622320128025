import React from 'react'
import ROUTE from '../route';
import USER_ROUTE from '../../user/route';
import { Spin } from 'antd';
import { compose, removeItem } from '../../core';
// import { withCreateResponse } from '../../survey/containers/SurveyOperations';
import { withCreateResponse, withSurveyByIdNewOptimized } from './diversityOperations'
import DiversityQuizView from '../components/DiversityQuizView';
import { getIntFromString } from '../../look';
import { withCreateResponseOptimized} from '../../survey/containers/SurveyOperations'
import SurveyTermsAndCondition from '../../survey/components/SurveyTermsAndCondition'
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
const DiversityHome = (props) => {
    const { error, history, createResponse, orgSurveyById, SurveyById } = props;
    const startTime = new Date().getTime();
    const [prevTotalDuration, setPrevTotalDuration] = React.useState(0);
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [showTermsAndCondition, setshowTermsAndCondition] = React.useState();
    if (error) {
        if (error.message === 'GraphQL error: Permission Denied.') {
            // setRedirect(true);
            setTimeout(3000, history.push(USER_ROUTE.login));
        }
    }
    const handleSubmit = async answers => {
        console.log("answers",answers)
        setSubmitLoading(true);
        const endTime = new Date().getTime();
        try {
            const response = await createResponse({
                answers,
                surveyId:
                    (orgSurveyById && getIntFromString(orgSurveyById.id)) ||
                    (SurveyById && getIntFromString(SurveyById.id)),
                totalDuration: prevTotalDuration + (endTime - startTime) / 1000 // returns time in seconds
            });
            response && setSubmitted(true);
            response && removeItem(orgSurveyById ? orgSurveyById.id : SurveyById.id);
            if (response) history.push(`/survey/response/${getIntFromString(response.id)}`);
        } catch (e) {
            throw new Error(e.message);
        }
        setSubmitLoading(false);
    };

    React.useEffect(()=>{
        if(SurveyById && SurveyById?.termsConditions){
          setshowTermsAndCondition(true)
        }
   
     },[SurveyById])

    return (
        // <Spin spinning={submitLoading} size="large">
        <>
        {SurveyById&&SurveyById==null?<NoPermissionView content={"You Don't Have Permission To View This Survey."}/>:
        <>
        {
            showTermsAndCondition && SurveyById?.termsConditions && (
              <SurveyTermsAndCondition
                termsAndConditon={SurveyById?.termsConditions}
                accept={()=>{setshowTermsAndCondition(false)}}
                back={()=>{history.goBack()}}
              />
            )
          }
        <DiversityQuizView
            {...props}
            submitted={submitted}
            onSubmit={handleSubmit}
            setPrevTotalDuration={setPrevTotalDuration}
            quizStartTime={startTime}
        />
        </>}
        </>
        // </Spin>
    )
}

export default compose(withCreateResponseOptimized, withSurveyByIdNewOptimized)(DiversityHome)
