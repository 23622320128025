// @ts-nocheck
import React from "react";
import { Table, Button, Spin } from "antd";
import styled from "styled-components";
import { MetaTags, getIntFromString, displayUtcToUserTimezone, parseMarkDownText } from "../../look";
import '../css/newEngagementDesign.css';
import grow_route from '../route/index'
import CoachingEngagementSearchView from "./growSearchView";

import Growpagination from "./growPagination";
const GrowHomePage = props => {

  const { history, me, loading, allCoachingConversation,coachingConversation, queryCount, getAllCustomUserCommitments, paginationData, navigateRoute, grow_page_info } = props
  const [ dataSource, setdataSource ] = React.useState( [] )
  React.useEffect( () => {
    if ( allCoachingConversation) {
      setdataSource( allCoachingConversation )
    }
  }, [ allCoachingConversation ] )



  const filter_data = [
    {
      key: "coach_User_FirstName_Icontains",
      label: "Coach",
      ui_flow: "input_box",
      default: true
    },
    {
      key: "coachee_User_FirstName_Icontains",
      label: "Coachee",
      ui_flow: "input_box",
    },
  ]

  return (
    <>
      <MetaTags
        title="grow engagements page"
        description="grow engagements page."
      />
      <div className='sc-main-content-container sa-home-page-section' style={{ background: "#fff", boxShadow: " 0px 10px 15px 0px rgba(170, 186, 198, 0.2)", marginTop: "1em" }}>
        <div className="engagement-space-between">
          <Button className="enggement-main-button" onClick={() => { navigateRoute( grow_route?.grow_create ) }}>New Engagement</Button>
          <div className='rd-search-container'>
            <CoachingEngagementSearchView filterData={filter_data} onfilterChange={e => coachingConversation( e )} />
          </div>
        </div>
        <div className='sa-table-container'>

          <div className='sc-table-header-container'>
            <h4 className='rd-th-label' style={{ width: "30%", paddingLeft: "1.25em" }}>Name </h4>
            <h4 className='rd-th-label' style={{ width: "50%", textAlign: "center" }}>Coachee's Goal Statement</h4>
            <h4 className='rd-th-label' style={{ width: "20%", textAlign: "center" }}>FollowUp on</h4>
          </div>

          <Spin spinning={loading}>
            {
              dataSource?.length > 0 ? dataSource?.map( item => (
                <div className='s-i-table-body-container' style={{cursor:"pointer"}} onClick={() => navigateRoute( grow_route?.grow_detail?.replace( ":convoId", getIntFromString( item?.id ) ) )}>
                  <div style={{ width: "30%", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
                    {item?.user?.user_details?.profile?.profileImage ? <img style={{ height: "3.5em", width: "3.5em", borderRadius: "50%" }} src={item?.user?.user_details?.profile?.profileImage} alt="profile_2" /> :
                      <div style={{ height: "3em", width: "3em", borderRadius: "50%", background: "rgba(217, 217, 217, 1)", fontFamily: "Poppins", fontWeight: "500", fontSize: "1.25em", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {item?.user?.user_details?.firstName?.charAt( 0 )}</div>}
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
                      <h4 className='s-i-table-body-label' style={{ paddingLeft: "1em", textAlign: 'left', fontSize: "1.2em" }}>{item?.user?.user_details?.firstName}</h4>
                      <h4 className='s-i-table-body-label' style={{ color: "rgba(137, 152, 162, 1)", fontSize: ".85em", paddingLeft: "1.5em", textAlign: 'left' }}>{item?.user?.type}</h4>
                    </div>
                  </div>
                  <div className='s-i-table-body-label' style={{ width: "50%", display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: "pointer", fontSize: "1.25em" }}>{parseMarkDownText( item?.response?.answerSet?.edges[ 0 ]?.node?.answer )}</div>
                  <div className='s-i-table-body-label' style={{ width: "20%", fontSize: "1.25em", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item?.followUpOn ? displayUtcToUserTimezone( item?.node?.followUpOn, 'DD MMM YYYY' ) : '-'}</div>
                </div>
              ) ) :
                ( !loading && dataSource?.length === 0 && <div className='rd-nodata'>
                  <h4 className='rd-nodata-label'>No data found</h4>
                </div> )
            }
          </Spin>
        </div>

        {( grow_page_info?.hasNextPage && grow_page_info?.cursor ) && <div align='center'>
          <Growpagination on_pagination={() => { coachingConversation( { cursor: grow_page_info?.cursor } ) }} />
        </div>}

      </div>
    </>
  );
};

export default GrowHomePage

export const MyTable = styled( Table )`
  table {
    border-spacing: 0 20px;
    thead{
      tr,th{
        background:#B0CB1F;
      }
      th h4{
        color:#fff;
        font-size:20px !important;
        font-weight:700;
        text-align:center;
        margin:0;
      }
      tr{
        height: 60px;
        th:first-child {
          border-top-left-radius: 11px !important;
          border-bottom-left-radius: 11px !important;
        }
        th:last-child {
          border-bottom-right-radius: 11px !important;
          border-top-right-radius: 11px !important;
        }
      }
    
    }
    tbody {
      tr {
        height: 60px;
        background: #fff;
        td {
          border: 1px solid #AFAFAF !important;
          border-style: solid none !important;
          div{
            font-weight: 400;
            font-size: 16px;
            line-height: 33px;
            color: #000000;
  
          }
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 11px !important;
          border-bottom-left-radius: 11px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 11px !important;
          border-top-right-radius: 11px !important;
        }
      }
    }
  }
`;