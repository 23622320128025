// @ts-nocheck
import React, { useState } from "react";
import { Row, Spin, Button, message, Modal, Checkbox } from "antd";
import '../Css/impact-narrative.css'
import { MetaTags } from "../../look";
import AddToolToRecent from "./AddToolToRecent";
import { useHistory } from "react-router-dom";
import './uiFlowTool.css';
import impact_logo from '../../assets/impact_logo.svg'
import User_profile from '../../assets/user-profile.svg'
import edit_green from '../../assets/in_edit_green.svg'
import edit_blue from '../../assets/in_edit_blue.svg'
import TeamNarrativeUI from "./TeamNarrativeView";
import impact_mobile_logo from '../../assets/impact-mobile-logo.svg'
import ImpactNarrativeMobileTab from "./ImpactNarrativeMobileTab";
import { impact_narrative_cookie } from "config";
import { getItem } from "modules/core";

const ImpactNarrativeUI = (props) => {
  const { narrativeloading, admin = false, removeAIGenData, user_narrative, generateImpactNarrative, location, getToolById, getuserImpactNarrative, data_clean_up, generating_loader, sucess_socket_connection, show_save, OnSaveAction, OnUpdateAction, previous_compare, user_narrative_preview, setSelectedNarratives, selected_narratives_submit ,error_socket_connection,data_loader,app_sidebar_expand,startLoader} = props;
  const [headerItems, setHeaderItems] = useState();
  const [sub_content_items, setSubContentItems] = useState([]);

  const [headerItems_prev, setHeaderItemsPrev] = useState();
  const [sub_content_items_prev, setSubContentItemsPrev] = useState([]);
  const [selectedTab, setselectedTab] = useState('MY_NARRATIVE');
  let [fistEmployeeId, setFistEmployeeId] = React.useState()
  let [secondEmployeeId, setSecondEmployeeId] = React.useState()
  const [visible, setVisibile] = React.useState(false)
  const [edit_detail, setEditDetail] = useState();
  const history = useHistory();
  React.useEffect(() => {
    data_clean_up('first')
    data_clean_up('second')
    setFistEmployeeId(null)
    setSecondEmployeeId(null)
  }, [selectedTab])

  React.useEffect(() => {
    if (fistEmployeeId) {
      getuserImpactNarrative(fistEmployeeId?.empid, 'first')
    } else {
      data_clean_up('first')
    }
  }, [fistEmployeeId])

  React.useEffect(() => {
    if (secondEmployeeId) {
      getuserImpactNarrative(secondEmployeeId?.empid, 'second')
    } else {
      data_clean_up('second')
    }
  }, [secondEmployeeId])


  const key_values = {
    communication: { label: 'Communication', order: 2 },
    collaboration: { label: 'Collaboration', order: 3 },
    decisionMaking: { label: 'Decision Making', order: 1 },
    relationshipBuilding: { label: 'Relationship Building', order: 4 },
  }

  React.useEffect(() => {
    if (edit_detail) {
      if (edit_detail?.key == "overview") {
        let overview_detial = { ...headerItems }
        overview_detial['edit_text'] = overview_detial?.text
        setHeaderItems(overview_detial)
      } else {
        let list = sub_content_items.concat([])
        let detial = list?.find(i => i?.key == edit_detail?.key)
        if (detial) {
          detial['edit_text'] = detial?.text
          setSubContentItems(list)
        }

      }
    }
  }, [edit_detail])

  React.useEffect(() => {
    if (user_narrative) {
      let unwanted_fields = ['id', 'overview', '__typename']
      let sub_list = []
      Object.entries(user_narrative).forEach(i => {
        if (!unwanted_fields?.includes(i[0])) {
          sub_list.push({
            text: i[1],
            key: i[0],
            title: key_values[i[0]]?.label || i[0]?.replaceAll("_", " "),
            order: key_values[i[0]]?.order
          })
        }
      })
      sub_list = sub_list?.sort((a, b) => a?.order - b?.order)
      setSubContentItems(sub_list)
      if (user_narrative?.overview) {
        setHeaderItems({
          text: user_narrative?.overview,
          key: "overview",
          title: 'Impact Narrative'
        })
      }else{
        setHeaderItems(null)
      }

    }
  }, [user_narrative])

  React.useEffect(() => {
    if (user_narrative_preview) {
      let unwanted_fields = ['id', 'overview', '__typename']
      let sub_list = []
      // setSubContentItems(items?.filter((data, idx) => idx !== 0))
      Object.entries(user_narrative_preview).forEach(i => {
        if (!unwanted_fields?.includes(i[0])) {
          sub_list.push({
            text: i[1],
            key: `${i[0]}_prev`,
            title: key_values[i[0]]?.label || i[0]?.replaceAll("_", " "),
            order: key_values[i[0]]?.order
          })
        }
      })
      sub_list = sub_list?.sort((a, b) => a?.order - b?.order)
      setSubContentItemsPrev(sub_list)
      if (user_narrative_preview?.overview) {
        setHeaderItemsPrev({
          text: user_narrative_preview?.overview,
          key: "overview_prev",
          title: 'Impact Narrative'
        })
      }else{
        setHeaderItemsPrev(null)
      }

    }
  }, [user_narrative_preview])


  const changeSelection = (key) => {
    let list = selected_narratives_submit?.concat([])
    if (!list?.includes(key)) {
      if (key?.includes("_prev")) {
        list.push(key)
        let rm = key?.replace("_prev", '')
        list = list?.filter(i => i != rm)
      } else {
        list.push(key)
        let rm = `${key}_prev`
        list = list?.filter(i => i != rm)
      }
      setSelectedNarratives(list)
    }

  }

  const renderHeaders = (section, can_edit = true) => {
    return (
      <>
        <div className="toolcontent impact-header-card" >
          <div className="header-border-div">
            <div className="border-inner-content">
              <img className="profile-img" src={User_profile} alt="" />
              <h4 className="header-border-label">
                {
                  (section.key == edit_detail?.key && can_edit) ? <span className="header-border-label">Edit </span> : <span className="header-border-label">My </span>
                }
                {section?.title}</h4>

              {
                (section.key !== edit_detail?.key && can_edit) ? (
                  <img className="in-edit-icon" onClick={() => { setEditDetail(section) }} src={edit_green} alt="" />
                ) : ''
              }


              {
                (previous_compare && user_narrative_preview && show_save) && (
                  <div style={{ padding: "0px 0em 0.15em 0em" }} >
                    <Checkbox onChange={() => { changeSelection(section?.key) }} checked={selected_narratives_submit?.includes(section?.key)}></Checkbox>
                  </div>
                )
              }
            </div>
          </div>

          <div className={`${(previous_compare && user_narrative_preview) ? 'overflow-card' : ''}`}>
            {
              section.key !== edit_detail?.key && (
                <h4>{section?.text}</h4>
              )
            }
            {
              section.key === edit_detail?.key && (
                <div style={{ width: "100%" }}>
                  <textarea className="in-textarea" onChange={(e) => changeText(section, e?.target?.value)} value={section?.edit_text}></textarea>
                  <div className="row-btn-container">
                    <Button className="green-action-btn" style={{ margin: "0.75em" }} onClick={() => onSave(section)}>Save</Button>
                    <Button className="grey-action-btn" style={{ margin: "0.75em" }} onClick={() => setEditDetail(null)}>Cancel</Button>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </>
    )
  }


  const RenderCards = (section, can_edit = true) => {
    return (
      <>
        <div className="toolcontent card-gap impact-card" style={{ width: (previous_compare && user_narrative_preview) ? "100%" : "49%" }}>
          <div className="sub-border-div">
            <h4 className="sub-border-label">
              {
                (section.key == edit_detail?.key && can_edit) ? (
                  <span className="sub-border-label">Edit </span>
                ) : ''
              }
              {section?.title}</h4>
            {
              (section.key != edit_detail?.key && can_edit) ? (
                <img className="in-edit-icon" onClick={() => { setEditDetail(section) }} src={edit_blue} alt="" />
              ) : ''
            }

            {
              (previous_compare && user_narrative_preview && show_save) && (
                <div style={{ padding: "0px 0.5em 0.15em 0.5em" }} >
                  <Checkbox onChange={() => { changeSelection(section?.key) }} checked={selected_narratives_submit?.includes(section?.key)}></Checkbox>
                </div>
              )
            }

          </div>
          <div className='overflow-card'>
            {
              section.key !== edit_detail?.key && (
                <h4>{section?.text}</h4>
              )
            }
            {
              section.key === edit_detail?.key && (
                <div style={{ width: "100%" }}>
                  <textarea className="in-textarea" onChange={(e) => changeText(section, e?.target?.value)} value={section?.edit_text}></textarea>
                  <div className="row-btn-container">
                    <Button className="green-action-btn" style={{ margin: "0.75em" }} onClick={() => onSave(section)}>Save</Button>
                    <Button className="grey-action-btn" style={{ margin: "0.75em" }} onClick={() => setEditDetail(null)}>Cancel</Button>
                  </div>
                </div>
              )
            }
          </div>
        </div>

      </>
    )
  }


  const changeText = (section, text) => {
    if (section?.key == "overview") {
      let overview_detial = { ...headerItems }
      overview_detial['edit_text'] = text
      setHeaderItems(overview_detial)
    } else {
      let list = sub_content_items.concat([])
      let detial = list?.find(i => i?.key == section?.key)
      if (detial) {
        detial['edit_text'] = text
        setSubContentItems(list)
      }

    }
  }

  const onSave = (section) => {
    let update_text
    if (section?.key == "overview") {
      let overview_detial = { ...headerItems }
      if (overview_detial['edit_text'] && overview_detial['edit_text']?.trim() != "") {
        overview_detial['text'] = overview_detial['edit_text']
        update_text = overview_detial['edit_text']
        setHeaderItems(overview_detial)
        setEditDetail(null)
      } else {
        message.error('Please update the data')
      }

    } else {
      let list = sub_content_items.concat([])
      let detial = list?.find(i => i?.key == section?.key)
      if (detial) {
        if (detial['edit_text'] && detial['edit_text']?.trim() != "") {
          detial['text'] = detial['edit_text']
          update_text = detial['edit_text']
          setSubContentItems(list)
          setEditDetail(null)
        }
        else {
          message.error('Please update the data')
        }
      }

    }

    if (user_narrative?.id && update_text && !show_save) {
      let value = {
        id: user_narrative?.id
      }
      value[section.key] = update_text
      OnUpdateAction(value)
    }
  }

  const SaveImpactNarrative = () => {
    let keys = [...new Set(selected_narratives_submit)]
    let prev_keys = keys?.filter(i => i?.includes("_prev"))
    keys = keys?.filter(i => !i?.includes("_prev"))
    let list = []
    prev_keys.forEach(i => {
      let data = sub_content_items_prev?.find(d => d?.key == i)
      if (data) {
        list.push(data)
      }
    })
    keys.forEach(i => {
      let data = sub_content_items?.find(d => d?.key == i)
      if (data) {
        list.push(data)
      }
    })
    if (prev_keys?.includes("overview_prev")) {
      list.push(headerItems_prev)
    } else {
      list.push(headerItems)
    }

    let invalid = list?.find(i => !i.text || i?.text?.trim() == "")
    if (invalid) {
      message.error('Complete your impact narrative before submitting')
      return
    }
    if (list?.length) {
      let values = {}
      list.forEach(i => {
        values[i.key?.replace("_prev", '')] = i.text
      })
      if (user_narrative?.id) {
        values['id'] = user_narrative?.id
      }
      OnSaveAction(values)
    }
  }

  const RegenerateImpactNarrative=async()=>{
    let already_requested = await getItem(impact_narrative_cookie)
    if(already_requested){
      startLoader()
    }else{
      setSubContentItems([]); 
      setHeaderItems(null);
      setEditDetail(null); 
      generateImpactNarrative();
    }
  }
 
  return (
    <>
      <MetaTags title="Impact Narrative" description="Impact Narrative" />
      <Spin spinning={narrativeloading || data_loader} size="large" className={`impact-narrative-spin ${app_sidebar_expand?'impact-narrative-spin-left-align':''}`} tip={data_loader ? `You will see your brand new impact narrative in just a few seconds.${user_narrative_preview?' You will see the old and the new side by side. If you want to keep the new with some parts from the old, you can edit the new one and then save it.':''}` : ""}>
        <Row className="impact-body-padding"  >
          <AddToolToRecent />


          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom:"1em" }}>
           
            <h4 className="impat-tool-titile">
              <h4 className="soul-item-title">Impact Narrative </h4>
              </h4>
            
            {/* <div style={{width:"10px",height:"10px"}}></div> */}
          </div>

          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }} align="center" className="tool-body">

              <div className="tool-content">
                <img className="tool-logo web-screen" src={impact_logo} alt="" />
                <img className="tool-logo mobile-screen" src={impact_mobile_logo} alt="" />

                <div className="tab-container " style={{ display: "flex", flexDirection: "row" }}>
                  <Button onClick={() => { setselectedTab('MY_NARRATIVE') }} className={`tab-btn ${selectedTab === 'MY_NARRATIVE' ? 'selected-tab-btn' : ''}`}>My Narrative</Button>
                  <Button onClick={() => { setselectedTab('TEAM_NARRATIVE') }} className={`tab-btn mobile-screen ${selectedTab === 'TEAM_NARRATIVE' ? 'selected-tab-btn' : ''}`}>Team Narratives</Button>
                  <Button onClick={() => { setselectedTab('TEAM_NARRATIVE') }} className={`tab-btn web-screen ${(selectedTab !== 'MY_NARRATIVE') ? 'selected-tab-btn' : ''}`}>Team Narratives</Button>
                  <Button onClick={() => { setselectedTab('COMPARE') }} className={`tab-btn mobile-screen ${selectedTab === 'COMPARE' ? 'selected-tab-btn' : ''}`}>Compare</Button>
                </div>

                {
                  !narrativeloading && user_narrative && !generating_loader && selectedTab === 'MY_NARRATIVE' && (
                    <div align="center" style={{ width: "100%" }}>
                      <Button className="green-action-btn" disabled={!sucess_socket_connection} style={{ marginTop: "1em" }} onClick={() => { RegenerateImpactNarrative() }}>{
                      (!error_socket_connection && !sucess_socket_connection)?
                      <div>
                        <span style={{marginRight:"10px"}}>Connecting to AI</span>
                        <Spin spinning={true}></Spin>
                      </div>:
                      (error_socket_connection)?'Failed to connect to AI':'Regenerate Impact Narrative'
                      }</Button>
                    </div>
                  )
                }

                {
                  selectedTab !== 'MY_NARRATIVE' ?
                    <>
                      <TeamNarrativeUI {...props} selectedTab={selectedTab} fistEmployeeId={fistEmployeeId} setFistEmployeeId={(e) => { setFistEmployeeId(e) }} secondEmployeeId={secondEmployeeId} setSecondEmployeeId={(e) => { setSecondEmployeeId(e) }} key_values={key_values} />
                    </> :
                    <>
                      <div className="in-compare-section">


                        <div style={{ width: '100%' }}>
                          {
                            (previous_compare && user_narrative_preview) && (
                              <div align="center" style={{ width: "100%", marginTop: "1em" }}>
                                <Button className={`tab-btn selected-tab-btn`}>AI Generated</Button>
                              </div>
                            )
                          }

                          {
                            headerItems && (
                              <>
                                <div className="web-screen" style={{ width: "100%" }}>
                                  {renderHeaders(headerItems, true)}
                                </div>
                                <div className="mobile-screen" style={{ width: "100%" }}>
                                  {renderHeaders(headerItems, true)}
                                </div>
                              </>
                            )
                          }
                          {
                            sub_content_items?.length ?
                              <div className="card-wrapper web-screen" style={{ width: "100%" }} >
                                {
                                  sub_content_items?.map((value) => (

                                    RenderCards(value, true)

                                  ))
                                }
                              </div>
                              :
                              null
                          }

                          {
                            sub_content_items?.length ?
                              <div style={{ width: "100%" }} className="mobile-screen">
                                <ImpactNarrativeMobileTab content={sub_content_items} {...props} />
                              </div>
                              :
                              null
                          }
                        </div>
                        {
                          (previous_compare && user_narrative_preview) && (
                            <div style={{ width: '100%' }}>
                              <div align="center" style={{ width: "100%", marginTop: "1em" }}>
                                <Button className={`tab-btn selected-tab-btn`}>Previous Impact Narrative</Button>
                              </div>
                              {
                                headerItems_prev && (
                                  <>
                                    <div className="web-screen" style={{ width: "100%" }}>
                                      {renderHeaders(headerItems_prev, false)}
                                    </div>
                                    <div className="mobile-screen" style={{ width: "100%" }}>
                                      {renderHeaders(headerItems_prev, false)}
                                    </div>
                                  </>
                                )
                              }
                              {
                                sub_content_items_prev?.length ?
                                  <div className="card-wrapper web-screen" style={{ width: "100%" }}>
                                    {
                                      sub_content_items_prev?.map((value) => (

                                        RenderCards(value, false)

                                      ))
                                    }
                                  </div>
                                  :
                                  null
                              }

                              {
                                sub_content_items_prev?.length ?
                                  <div style={{ width: "100%" }} className="mobile-screen">
                                    <ImpactNarrativeMobileTab content={sub_content_items_prev} {...props} />
                                  </div>
                                  :
                                  null
                              }
                            </div>
                          )
                        }
                      </div>
                    </>
                }

              </div>

            </div>
          </div>

          {
            !narrativeloading && !user_narrative && !generating_loader && selectedTab === 'MY_NARRATIVE' && (
              <div align="center" style={{ width: "100%" }}>
                <Button className="green-action-btn" disabled={!sucess_socket_connection} onClick={() => generateImpactNarrative()}>
                  {
                      (!error_socket_connection && !sucess_socket_connection)?
                      <div>
                        <span style={{marginRight:"10px"}}>Connecting to AI</span>
                        <Spin spinning={true}></Spin>
                      </div>:
                      (error_socket_connection)?'Failed to connect to AI':'Generate Impact Narrative'
                  }
                  </Button>
              </div>
            )
          }

          {
            !narrativeloading && show_save && selectedTab === 'MY_NARRATIVE' && user_narrative && !generating_loader && (
              <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                <Button className="green-action-btn" disabled={edit_detail} onClick={() => setVisibile(true)} style={{ margin: "1.5em" }}>Save</Button>
                {
                  user_narrative_preview && (
                    <Button className="grey-action-btn" disabled={edit_detail} onClick={() => removeAIGenData()} style={{ margin: "1.5em" }}>Cancel</Button>
                  )
                }

              </div>
            )
          }

        </Row>
      </Spin>
      <Modal
        footer={null}
        centered
        destroyOnClose={true}
        closable={false}
        visible={visible}>
        <div className="impact-narative-warning-popup">
          {/* <h2 className="impact-narative-warning-title">Warning !</h2> */}
          <h4 className="impact-narative-warning-content">Saving the AI generated impact narrative will replace the previous impact narrative.</h4>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: '10px', alignItems: 'center', flexWrap: "nowrap", marginTop: "1em" }}>
            <button className="impact-narative-warning-btn" onClick={() => { setVisibile(false); SaveImpactNarrative() }}>Ok</button>
            <button className="impact-narative-warning-cancel-btn" onClick={() => setVisibile(false)}>Close</button>
          </div>

        </div>
      </Modal>
    </>
  );
};

export default ImpactNarrativeUI;