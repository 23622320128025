// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import IdpHomeView from '../components/IdpHomeView';
import { IDP_SUBSCRIPTION, MANAGER_PERMISSION, USER_IDP_LIST } from '../graphql/userIdpList.gql';
import { withDeleteIdp } from '../idpOperations';
import { message, Spin } from 'antd'
import { getIntFromString, globalPermissionValidator } from 'modules/look';
import { idp_permission } from 'Permissions/idp.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
const IDPHomeContainer = props => {
  const { me, client, deleteIdp, userPermission } = props
  const [ idp_page_info, SetIdpPageInfo ] = React.useState()
  const [ idp_lists, setIDPLists ] = React.useState( [] )
  const [ idp_list_loading, setIDPLoading ] = React.useState( false )
  const [ manager_permission, setManagerPermission ] = React.useState( false )
  const [ delete_idp, setDeleteIdp ] = React.useState()
  const [ permission, setPermission ] = React.useState()
  const filter_ref = React.useRef( {} )
  const idp_list_ref = React.useRef( [] )
  const mountedRef = React.useRef( true )
  let idpSub = undefined

  //Permission Check
  React.useEffect( () => {

    localStorage.removeItem('emailRoute')

    if ( userPermission?.length ) {
      let permission = globalPermissionValidator( idp_permission, userPermission )
      setPermission( permission )
    }
  }, [ userPermission ] )

  React.useEffect( () => {
    return () => {
      if ( idpSub ) {
        idpSub.unsubscribe();
      }
    }
  } )
  React.useEffect( () => {
    return () => {
      mountedRef.current = false
    }
  }, [] )

  React.useEffect( () => {
    mountedRef.current = true
  }, [] )

  React.useEffect( () => {
    idp_list_ref.current = idp_lists
  }, [ idp_lists ] )

  React.useEffect( () => {

    if ( me?.employee && permission?.required_permission ) {
      userIdpList( { employeeId: me?.employee?.id } )
      getManagerPermission()
    }

  }, [ me, permission ] )

  const getManagerPermission = async () => {
    const { data } = await client.query( {
      query: MANAGER_PERMISSION,
      fetchPolicy: 'network-only'
    } );
    if ( data?.me ) {
      if ( data?.me?.employee?.teamManagerEmployee?.totalCount > 0 || data?.me?.employee?.verticalHeadEmployee?.totalCount > 0 || data?.me?.employee?.orgPocEmployee?.totalCount > 0 || data?.me?.employee?.orgCeoEmployee?.totalCount > 0 ) {
        setManagerPermission( true )
      }
      else {
        setManagerPermission( false )
      }
    }
  }

  const userIdpList = async ( filter ) => {
    filter_ref.current = { ...filter, cursor: null } || {}
    setIDPLoading( true )
    const { data } = await client.query( {
      query: USER_IDP_LIST,
      variables: { ...filter, first: 10 },
      fetchPolicy: 'network-only'
    } );
    if ( data?.allIdp ) {
      SetIdpPageInfo( {
        hasNextPage: data?.allIdp?.pageInfo?.hasNextPage,
        cursor: data?.allIdp?.pageInfo?.endCursor,
      } )

      let idpLists = data?.allIdp?.edges?.map( ( { node } ) => node )
      if ( filter?.cursor ) {
        idpLists = idp_list_ref.current.concat( idpLists )
      }
      setIDPLists( idpLists )

      idpSub = client.subscribe( {
        query: IDP_SUBSCRIPTION,
        variables: { ...filter }
      } ).subscribe( {
        next( result ) {
          let idpCacheData = idp_list_ref.current
          switch ( result.data.IdpSubscription.mutation ) {
            case 'DELETE':
              const deleted = idpCacheData?.filter( item =>
                item.id === result.data.IdpSubscription.Idp.id
                  ? false
                  : true )
              if ( mountedRef.current ) {
                setIDPLists( deleted )
              }
              break
            case 'UPDATE':
              const updated = idpCacheData?.map( item =>
                item.id === result.data.IdpSubscription.Idp.id
                  ? result.data.IdpSubscription.Idp
                  : item
              )
              if ( mountedRef.current ) {
                setIDPLists( updated )
              }
              break
            default:
              break
          }
        }
      } )

    }
    setIDPLoading( false )
  }


  const deleteSelectedIdp = async ( id ) => {
    const response = await deleteIdp( { id: getIntFromString( id ) } )
    if ( response ) {
      setDeleteIdp()
      let lists =[...idp_lists]
      let index = lists?.findIndex(item => item?.id === id);
      lists.splice(index, 1)
      setIDPLists(lists)
      message.success( "Idp deleted successfully !!" )
    }
  }

  return (
    <>
      {
        ( permission && permission?.required_permission ) && (
          <IdpHomeView
            idp_lists={idp_lists}
            idp_list_loading={idp_list_loading}
            idp_page_info={idp_page_info}
            getUserIdpList={( filter ) => userIdpList( { ...filter_ref.current, ...filter } )}
            manager_permission={manager_permission}
            deleteSelectedIdp={( id ) => deleteSelectedIdp( id )}
            setDeleteIdp={( value ) => setDeleteIdp( value )}
            delete_idp={delete_idp}
            {...props} /> )
      }
      {( permission && !permission?.required_permission ) && ( <NoPermissionView /> )}
      {
        !permission && (
          <div className="role-no-permission-container">
            <Spin spinning={true} size="large"></Spin>
          </div>
        )
      }
    </>
  );
};

export default compose( withApollo, withDeleteIdp )( IDPHomeContainer );
