// @ts-nocheck
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'modules/core'
import React from 'react'
import OrgSurveyComponent from '../component/OrgSurveyComponent'
import { all_survey_icon } from 'modules/survey-module/icon'
import { getIntFromString, globalPermissionValidator } from 'modules/look'
import { survey_permission } from 'Permissions/survey.permission'
import { ORG_SURVEY_LIST } from 'modules/survey-module/graphql/survey.gql'
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent'
import { Spin } from 'antd'
import { SURVEY_ID_3CS,VALUE_SUERVEY_PART_1,PERSONALITY_PART_1,STRENGTH_PART_1,KNOWLEDGE_PART_1,grow_survey_id ,SURVEY_READINESS_LEVEL,SURVEY_WHOLE_IDENTITY,feedback_survey_id, report_tool_data, feedback_1_1_survey_id, USER_MANUAL_SURVEY_ID, effectiveness_indicator_one_on_one} from '../../../../config'


const OrgSurveyContainer = (props) => {
    const { userPermission, me, client } = props
    const [permission, setPermission] = React.useState()
    const [survey_loading, setLoading] = React.useState(true)
    const [all_surveys, SetAllSurveys] = React.useState([])

    let skip_survey=[SURVEY_ID_3CS,VALUE_SUERVEY_PART_1,PERSONALITY_PART_1,STRENGTH_PART_1,KNOWLEDGE_PART_1,grow_survey_id,SURVEY_READINESS_LEVEL,SURVEY_WHOLE_IDENTITY,feedback_survey_id,feedback_1_1_survey_id,USER_MANUAL_SURVEY_ID,effectiveness_indicator_one_on_one]
    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(survey_permission, userPermission)
            setPermission(permission)
        }
    }, [userPermission])

    React.useEffect(() => {
        if (permission && permission?.required_permission && me) {
            getSurveyList()
        }
    }, [permission, me])

    const getSurveyList = async () => {
        try {
            setLoading(true)
            const { data } = await client.query({
                query: ORG_SURVEY_LIST,
                variables: { user: me?.id, first: 30, last: 1,listing:true },
                fetchPolicy: 'network-only'
            });
            if (data?.allOrgSurveysByImplicitOrgId) {
                let list = data?.allOrgSurveysByImplicitOrgId?.edges?.map(({ node }) => {
                    let response_id = node?.responseSet?.edges[0]?.node?.id
                    let report_tool = report_tool_data?.find(t=>t.survey_id==getIntFromString(node.id))
                    return {
                        id: getIntFromString(node.id),
                        title: node?.name,
                        icon: node?.logo || all_survey_icon.survey_icon,
                        is_360_available: node?.multisourceResponse,
                        response_id: response_id ? getIntFromString(response_id) : null,
                        info: node?.description,
                        report_tool_id:report_tool?.tool_id,
                        reportAvailable:node?.reportAvailable
                    }
                })

                list = list?.filter(i=>!skip_survey?.includes(i?.id))
                setLoading(false)
                SetAllSurveys(list)
            }
        } catch (error) {
        }
    }
    return (

        <>
            {(permission && permission?.required_permission) && (
                survey_loading ?
                    <div align="center" style={{ padding: "200px" }}>
                        <Spin spinning={true} size='large'/>
                    </div> :
                    <OrgSurveyComponent all_surveys={all_surveys} survey_loading={survey_loading} permission={permission} {...props} />
            )}

            {(permission && !permission?.required_permission) && (
                <NoPermissionView />
            )}

            {(!permission) && (
                <div align="center" style={{ padding: "200px" }}>
                    <Spin spinning={true} size='large' />
                </div>
            )}
        </>

    )
}

export default compose(withApollo)(OrgSurveyContainer)