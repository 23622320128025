// @ts-nocheck
import React from 'react';
import { Row, Button, Spin, Input, message, Modal } from 'antd';
import styled from 'styled-components';
import ConfirmationModal from 'modules/look-v2/Components/confirmationModal';
import { all_survey_icon } from 'modules/survey-module/icon';
import survey_module_route from 'modules/survey-module/routes';
import { getResponsesBase64StringFromInt, getSurveyBase64StringFromInt } from 'modules/look';
const Survey360View = props => {

  const { tokenList, history, deleteToken, survey_detial, getInvitesList, navigateRoute, loading, getSurveyTokenList } = props
  const [ confirmationModalVisible, setConfirmationModalVisible ] = React.useState( false );
  const [ deleteLoader, setdeleteLoader ] = React.useState( false );
  const [ selectedForDelete, setselectedForDelete ] = React.useState();
  const [ visible, setVisible ] = React.useState( false );
  const [ info_warning, setInfoWarning ] = React.useState( false );

  const back = () => {
    history.goBack();
  };

  const displayBooleanStatus = ( bool ) => {
    if ( !bool ) {
      return (
        <div className="s-i-complete-button">
          Accepted
        </div>
      );
    } else
      return (
        <div className='s-i-pending-button'>
          Pending
        </div>
      );
  };

  const copyToClipboard = ( record ) => {
    navigator.clipboard.writeText( record?.link );
    message.success( 'link copied' )
  }

  const deleteRecord = ( record ) => {
    setselectedForDelete( record )
    setConfirmationModalVisible( true )
  }

  const DeleteToken = async ( value ) => {
    try {
      setdeleteLoader( true )
      const response = await deleteToken( { token: value?.token } );
      if ( response?.id ) {
        // let id =props?.match?.params?.surveyid
        // onchange({ survey:id})
        // let data = tokenList.find( item => selectedForDelete?.id === item?.id )
        // if ( data ) {
        //   let index = tokenList.indexOf( data )
        //   dataSource.splice( index, 1 )
        //   setDataSource( [].concat( dataSource ) )
        // }
      }
      setdeleteLoader( false )
      setselectedForDelete( null )
    }
    catch {
      setdeleteLoader( false )
    }

  }


  const RemoveToken = ( value ) => {

    setConfirmationModalVisible( false )
    if ( value ) {
      let token = selectedForDelete.link.split( '/' ).at( -1 )
      let id = selectedForDelete?.id
      DeleteToken( { token, id } )
    }
    else {
      setselectedForDelete( null )
    }
  }
  const navigateToAdd = () => {
    let url = survey_module_route.survey_360_add?.replace( ":surveyid", props?.match?.params?.surveyid )?.replace( ":responseid", props?.match?.params?.responseid )
    navigateRoute( url )
  }

  return (
    <>
      <Spin spinning={deleteLoader}>
        <div className="invite-container-padding">
          {
            confirmationModalVisible && (

              <ConfirmationModal
                titile={`You are about to delete the invitation for ${selectedForDelete?.user?.firstName}. Are you sure?`}
                onConfirm={( e ) => RemoveToken( e )}
                visible={confirmationModalVisible}
              />
            )
          }
          <RowDiv >
            <img src={all_survey_icon?.back_arrow} alt="back_arrow" onClick={back} style={{ cursor: "pointer", width: "20px" }} />
            <h4 className='survey-invite-title' style={{ marginLeft: "1em" }}> {survey_detial?.name}  360 survey invitation list</h4>
          </RowDiv>
          <div className='sc-main-content-container sa-home-page-section' style={{ background: "#fff", boxShadow: " 0px 10px 15px 0px rgba(170, 186, 198, 0.2)", marginTop: "1em" }}>
            <div className='s-i-button-container'>
              <Button className='sa-create-btn-large' onClick={() => navigateToAdd()}>Invite</Button>
              <div className="dashboard-member-search-section" style={{ border: "1px solid rgba(170, 186, 198, 1)", background: "#fff", height: '3em', width: "20em" }}>
                <img src={all_survey_icon?.search_icon} alt="search_icon" />
                <Input className="dashboard-member-search-input" placeholder="Search" onChange={( e ) => getSurveyTokenList( { survey: getSurveyBase64StringFromInt( props?.match?.params?.surveyid ), responseSourceFor: getResponsesBase64StringFromInt( props?.match?.params?.responseid ), user_FirstName_Icontains: e?.target?.value } )} />
              </div>
            </div>

            <div className='sa-table-container'>
              <div className='sc-table-header-container'>
                <h4 className='sa-table-header-label' style={{ fontSize: "1.35em", width: "25%", paddingLeft: "1.25em" }}>Name </h4>
                <h4 className='rd-table-header-label' style={{ fontSize: "1.35em", width: "30%", textAlign: "center" }}>Survey Status </h4>
                <h4 className='rd-table-header-label' style={{ fontSize: "1.35em", width: "30%", textAlign: "center" }}>Email </h4>
                <h4 className='sa-table-header-label' style={{ fontSize: "1.35em", width: "15%", textAlign: "center", paddingRight: ".5em" }}>Actions</h4>
              </div>
              <Spin spinning={loading}>
                {
                  tokenList?.length > 0 && tokenList?.map( item => (
                    <div className='s-i-table-body-container'>
                      <h4 className='s-i-table-body-label' style={{ width: "25%", paddingLeft: "1em", textAlign: 'left' }}>{item?.user?.firstName}</h4>
                      <div style={{ width: "30%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{displayBooleanStatus( item.valid )}</div>
                      <h4 className='s-i-table-body-label' style={{ width: "30%" }}>{item?.user?.email}</h4>
                      <div style={{ width: "15%", display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1em' }}>
                        {/* <Tooltip placement="top" title={'Your 360 invitation is ready! Copy the unique survey link and share it for them to take the assessment!'} >
                          <Button shape="circle" type="primary" onClick={() => copyToClipboard( item )} >
                            <CopyOutlined />
                          </Button>
                        </Tooltip> */}
                        <img src={all_survey_icon?.delete_icon} style={{ cursor: "pointer", width: "20px" }} onClick={() => deleteRecord( item )} alt="delete_icon" />
                      </div>
                    </div>
                  ) )
                }
              </Spin>
              {!loading && tokenList?.length === 0 && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: "5em", marginBottom: "5em" }}><h4 className='sa-table-body-label-des'>No Invitations Found</h4></div>}
            </div>
          </div>
        </div>
      </Spin>
      <Modal
        visible={visible}
        footer={false}
        closable={false}
        destroyOnClose={true}
        centered={true}
      >
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", gap: "10px", padding: "1em", width: "100%" }}>
          <p className='rd-file-upload-label'>Are you sure,you want to cancel this form</p>
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', gap: "10px", width: "100%", marginTop: "1em" }}>
            <Button type="danger" style={{ width: "80px" }} onClick={() => setVisible( false )}>
              No
            </Button>
            <Button type="primary" style={{ width: "80px" }} onClick={() => { setVisible( false ) }}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={info_warning}
        footer={false}
        closable={false}
        destroyOnClose={true}
        centered={true}
      >
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", gap: "10px", padding: "1em", width: "100%" }}>
          <p className='rd-file-upload-label'>A link was generated plese copy and send the other person</p>
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', gap: "10px", width: "100%", marginTop: "1em" }}>
            <Button type="primary" style={{ width: "100px" }} onClick={() => setInfoWarning( false )}>
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )

};

export default Survey360View

const RowDiv = styled( Row )`
        display:flex;
        flex-direction:row;
        justify-content:start;
        align-items:center;
        margin-bottom:20px,
        `