// @ts-nocheck
import React from "react";
import { Row, Col, Button, Tooltip, Menu, Spin, Input, Form, Dropdown, Checkbox, Select, Switch } from "antd";
import styled from "styled-components";
import { MetaTags, getIntFromString, scrolltoview, withMarkdownFieldValue, placeholderTextForCkeditor, getSelectedFeedbackType, ToolTipTitle } from "../../look";
import { DownOutlined } from "@ant-design/icons";
import ReactMarkdown from '../../look/components/ReactMarkdown'
import Agenda_close from '../../assets/agenda-close.svg'
import CKEditorFieldOneOnOne from "modules/look/components/form-components/CKEditor-one-on-one";
import GivenKudos from "./GivenKudos";
import info_button from '../../assets/info-button.svg'
import green_badge from '../../assets/green_badge.svg'
import '../components/css/FeedBackPopupStyles.css'


const GiveFeedbackView = props => {
    const { allOrganizationEmployees, SurveyById, okrDetails, me, handleSearch, okrByrecipiant, okrloading, setFeedbackOperationsModalVisible, setGiveKudosPopUpVisible, givenKudos,
        Recipient, setRecipient,
        recipientName, setrecipientName,
        okrList, setokrList,
        form, tabArrayDetailed,
        feedBackType, setFeedBackType,
        fillInOption, setfillInOption,
        questionList, setquestionList,
        answersList, setAnswers, setTabArrayDetailed,
        question1Field1, setquestion1Field1,
        question1Field2, setquestion1Field2,
        question2Field1, setquestion2Field1,
        question2Field2, setquestion2Field2,
        question2Field3, setquestion2Field3,
        question3Field1, setquestion3Field1,
        question3Field2, setquestion3Field2,
        question4Field1, setquestion4Field1,
        question5Field2, setquestion5Field2,
        question5Field1, setquestion5Field1,
        navigationFromOkr, setnavigationFromOkr,
        hideselection,
        employeeList, setemployeeList,
        invalidFeedbackType, setInvalidFeedbackType,
        selectedTabValue, setSelectedTabValue,
        selectedOkr, setSelectedOkr,
        checkBoxSelection, setCheckBoxSelection,
        questionSet, setQuestionSet,
        tabArray, setTabArray, setGivenKudos,
        feedbackPermission,
        setOkrNeeded,
        loading, feedBackTypeEnum,
        receipientNameInvalid, setReceipientNameInvalid, resetStateOfElemets, resetFeedbackForm,
        setFeedbackPreviewDisplay, setFeedbackDetailsLoading, setFeedbackModalVisible, setFeedbackDetails,
        handleValidationwithFillin, setFeedbackDetailSide, getEmployeeById
    } = props


    const [invalidOkr,setInvalidOkr] = React.useState(false)

    React.useEffect(() => {
        if (allOrganizationEmployees && me) {
            let filtered = allOrganizationEmployees?.edges.filter(item => item?.node?.user?.employee?.id !== me?.employee?.id)
            setemployeeList(filtered)
        }
    }, [allOrganizationEmployees, me])

    React.useEffect(() => {
        if (okrByrecipiant) {
         
            setokrList(okrByrecipiant?.edges?.map(({ node }) => { return { label: node?.okr?.title, id: node?.okr?.id, okrType: node?.okr?.okrType, title: node?.okr?.title,isDiscarded:node?.okr?.isDiscarded } }))
        }
    }, [okrByrecipiant])

    React.useEffect(() => {
        if (SurveyById) {
            let list = SurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges?.map(({ node }) => node)
            setquestionList(list)
            // setloading(false)
        }
    }, [SurveyById])

    function handleMenuClick(value, options) {
        let userData = employeeList?.find(item => item?.node?.user?.employee?.id === value?.key)
        let node
        if (userData) {
            node = userData?.node
        }
        if (node) {
            if (!feedbackPermission?.okr_permission) {
                setOkrNeeded(false)
            } else {
                setOkrNeeded(true)

            }
            if (Recipient) {
                resetStateOfElemets()
            }
            setRecipient(node)
            // setRecipient(userNodeVal)

            setrecipientName(`${node?.user?.firstName} ${node?.user?.lastName}`)
            handleUserNodeChange(node?.user?.employee?.id)
        }
    }

    function handleUserNodeChange(userNodeVal) {
    }

   

   

    const FeedBackTypemenu = () => (
        <Menu >
            <Menu.Item className="poppins" key="1" onClick={() => HandleTypeChange("1")}>
                Motivational/Recognition
            </Menu.Item>
            <Menu.Item className="poppins" key="2" onClick={() => HandleTypeChange("2")}>
                Developmental/Constructive
            </Menu.Item>
        </Menu>
    );


    React.useEffect(() => {

        if (question1Field1?.trim() !== "" && question1Field2?.trim() !== "") {
            let temp = [...tabArrayDetailed]
            temp = tabArrayDetailed?.map(obj =>
                obj.value == 'C' ? { ...obj, active: false } : obj
            )
            setTabArrayDetailed(temp)
        }
        if (question2Field1?.trim() !== "" && question2Field2?.trim() !== "" && question2Field3?.trim() !== "") {
            setTabArrayDetailed(tabArrayDetailed?.map(obj =>
                obj.value == 'L' ? { ...obj, active: false } : obj
            ))
        }
        if (question3Field1?.trim() !== "" && question3Field2?.trim() !== "") {
            setTabArrayDetailed(tabArrayDetailed?.map(obj =>
                obj.value == 'A' ? { ...obj, active: false } : obj
            ))
        }
        if (question4Field1?.trim() !== "") {
            setTabArrayDetailed(tabArrayDetailed?.map(obj =>
                obj.value == 'P' ? { ...obj, active: false } : obj
            ))
        }




    }, [question1Field1, question1Field2, question2Field1, question2Field2, question2Field3, question3Field1, question3Field2, question4Field1, fillInOption])



    const HandleTypeChange = (type) => {
        if (feedBackType && feedBackType !== type) {
            resetFeedbackForm()
        }

        if (type === "1") {
            setFeedBackType("1")
            if (!question5Field1) {

                setquestion5Field1("You should try and do this more often and if possible, even try and help the team adopt this behavior.")
            }
        }
        else if (type === "2") {

            setFeedBackType("2")
            if (!question5Field2) {
                setquestion5Field2("If it’s helpful, I can continue to observe and provide feedback if I see you try something differently. I am happy to catch up again in some time, so you can bounce off any challenges you may be facing. Let me know.")
            }
        }
        setInvalidFeedbackType(false)

    }

    const submitAnswer = (data, value, arr, detailed, setArr) => {

        let title;
        if (detailed) {
            title = data
        } else {
            title = data?.questionText?.replace(/<[^>]*>/g, '')
        }


        if (title?.startsWith('C') && value) {
            setArr(arr?.map(obj =>
                obj.value == 'C' ? { ...obj, active: false } : obj
            ))
        }
        if (title?.startsWith('L') && value) {
            setArr(arr?.map(obj =>
                obj.value == 'L' ? { ...obj, active: false } : obj
            ))
        }
        if (title?.startsWith('A') && value) {
            setArr(arr?.map(obj =>
                obj.value == 'A' ? { ...obj, active: false } : obj
            ))
        }
        if (title?.startsWith('S') && value) {
            setArr(arr?.map(obj =>
                obj.value == 'S' ? { ...obj, active: false } : obj
            ))
        }
        if (feedBackType == '2' && title.startsWith('P') && value) {
            setArr(arr?.map(obj =>
                obj.value == 'P' ? { ...obj, active: false } : obj
            ))
        }
        let feed = withMarkdownFieldValue("answer", { answer: value })
        let answerSet = {
            questionId: getIntFromString(data?.id),
            ...feed
        }


        if (!detailed) {


            let tempAnswer = [].concat(answersList)
            let previous = tempAnswer.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
            if (previous) {
                let index = tempAnswer.indexOf(previous)
                tempAnswer.splice(index, 1)
                tempAnswer.push(answerSet)
            }
            else {
                tempAnswer.push(answerSet)
            }

            setAnswers(tempAnswer)
            if (!feedBackType) {
                setInvalidFeedbackType(true)
            }

        }
    }

    React.useEffect(() => {
        if (selectedTabValue == 4) {
            setTabArrayDetailed(tabArrayDetailed?.map(obj =>
                obj.value == 'S' ? { ...obj, active: false } : obj
            ))
        }
    }, [selectedTabValue])






    React.useEffect(() => {
        if (Recipient) {
            setReceipientNameInvalid(false)
        }
    }, [Recipient])


 

  




  

 
    const onChnageOfCheckBoxOkr = (checked) => {

        if (checked?.target?.checked) {
            setCheckBoxSelection("okr")
        } else {
            setCheckBoxSelection(undefined)
            setSelectedOkr(undefined)
        }
    }

    const onChnageOfCheckBoxMeeting = (checked) => {
        if (checked?.target?.checked) {
            setCheckBoxSelection('1_1')
        } else {
            setCheckBoxSelection(undefined)
        }
    }


    React.useEffect(() => {
        if (checkBoxSelection === "okr" && !Recipient) {
            setReceipientNameInvalid(true)
        } else if (checkBoxSelection === "okr" && !Recipient) {
            setReceipientNameInvalid(false)
        } else if (!checkBoxSelection && !Recipient) {
            setReceipientNameInvalid(false)
        }
    }, [checkBoxSelection])


    const checkCreateContextForEmptyText = () => {

        if (question1Field1?.trim() === "" && question1Field2?.trim() === "") {
            return true
        }
        if (question1Field1?.trim() !== "" && question1Field2?.trim() !== "") {
            return false
        }
        else {
            return true
        }
    }




    const checkLaoutContextForEmptyForm = () => {


        if (question2Field1?.trim() === "" && question2Field2?.trim() === "" && question2Field3?.trim() === "") {
            return true
        }
        if (question2Field1?.trim() !== "" && question2Field2?.trim() !== "" && question2Field3?.trim() !== "") {
            return false
        }
        else {
            return true
        }

    }

    const checkArticulateForEmptyForm = () => {


        if (question3Field1?.trim() === "" && question3Field2?.trim() === "") {
            return true
        }
        if (question3Field1?.trim() !== "" && question3Field2?.trim() !== "") {
            return false
        }
        else {
            return true
        }

    }

    const checkProbeForEmptyForm = () => {


        if (question4Field1?.trim() === "") {
            return true
        }
        if (question4Field1?.trim() !== "") {
            return false
        }
        else {
            return true
        }

    }

    const checkSupportForEmptyForm = () => {


        if (question5Field1?.trim() === "") {
            return true
        }
        if (question5Field1?.trim() !== "") {
            return false
        }
        else {
            return true
        }

    }






    const checkFillInOptionsForText = (index) => {

        switch (index) {
            case 0:
                return checkCreateContextForEmptyText()
            case 1:
                return checkLaoutContextForEmptyForm()
            case 2:
                return checkArticulateForEmptyForm()
            case 3:
                return checkProbeForEmptyForm()
            case 4:
                return checkSupportForEmptyForm()
            default:
                break;
        }
    }

    const checkAnswerText = (index) => {

        if (fillInOption) {

            return checkFillInOptionsForText(index)
        } else {
            let ck_editor_to_check = answersList[index]?.answer.replace(/\&nbsp;/g, '').replace(/(<([^>]+)>)/ig, '');
            if (ck_editor_to_check?.trim() !== "") {
                return true
            } else {
                return false
            }
        }


    }


    const handleDetailedTextEntered = (enteredValue, indexToSet) => {
        let arr = questionSet;
        let sss = arr[selectedTabValue]
        sss[indexToSet] = enteredValue
        setQuestionSet(arr)
    }

    const checkDisblaeButton = (value, index, arr) => {

        if (feedBackType == '1') {
            if (value === "C" && checkAnswerText(index)) {
                return false
            }
            if (value !== "C" && arr[selectedTabValue]?.value === value && checkAnswerText(index)) {
                return false
            }
            if (value !== "C" && arr[selectedTabValue]?.value !== value && !arr[index]?.active && checkAnswerText(index)) {
                return false
            }
            if (value !== "C" && feedBackType == '2' && arr[selectedTabValue]?.value !== value && checkAnswerText(index)) {
                return false
            }

            if (value !== "C" && feedBackType == '2' && arr[selectedTabValue]?.value !== value && arr[selectedTabValue]?.active && checkAnswerText(index)) {
                return true
            }

            else {
                return true
            }

        } else if (!feedBackType) {
            return true
        } else if (feedBackType == '2') {

            if (value !== "C" && arr[selectedTabValue]?.value === value && checkAnswerText(index)) {
                return false
            }
            if (value !== "C" && arr[selectedTabValue]?.value === value && arr[index]?.active && checkAnswerText(index)) {
                return false
            }
            if (value !== "C" && arr[selectedTabValue]?.value !== value && arr[index]?.active && checkAnswerText(index)) {
                return true
            }
        }

    }



    const checkDisblaeButtonWithFillInOption = (value, index, arr) => {

        if (arr[selectedTabValue]?.value === value) {
            return false
        }
        if (!arr[index]?.active) {
          
            return false
        }
        else {
            return true
        }

    }




    React.useEffect(() => {

        if (selectedTabValue && fillInOption) {
            let node = document.getElementById("feedbackContainer")
            if (node) {
                node.scrollIntoView({ block: 'start', inline: 'start', behavior: "smooth" })
            }
        }

    }, [selectedTabValue, fillInOption])


    const handleNextSubmit = (e, nextTab) => {


        setSelectedTabValue((feedBackType == '1' && selectedTabValue == 2) ? selectedTabValue + 2 : selectedTabValue + 1)

        if (fillInOption && feedBackType) {
            if (feedBackType === "1" && selectedTabValue == 2) {
                setTabArrayDetailed(tabArrayDetailed?.map(obj =>
                    obj.id == 4 ? { ...obj, active: false } : obj
                ))
            } else if (feedBackType === "2" && selectedTabValue == 2) {
                setTabArrayDetailed(tabArrayDetailed?.map(obj =>
                    obj.id == 3 ? { ...obj, active: false } : obj
                ))
            }
            else if (feedBackType === "2" && selectedTabValue == 4) {
                setTabArrayDetailed(tabArrayDetailed?.map(obj =>
                    obj.id == 4 ? { ...obj, active: false } : obj
                ))
            }
        }
    }

    const handleDetailedFormToggle = (e) => {

        setSelectedTabValue(0);
        setfillInOption(!fillInOption)
        resetFeedbackForm();

    }


   



    const showQuestion = (data) => {

        if (!data?.questionAddress) {
            return (
                <Col span={24}>
                    <Row style={{ marginTop: '15px', width: '100%' }}>
                        <h4 className='feedback-pop-up-form-title' style={{ margin: "0px 0px 6px 15px" }}><span style={{ color: 'rgb(255, 77, 79)', marginRight: '3px', marginBottom: '6px' }}>* </span><ReactMarkdown >{data?.questionText}</ReactMarkdown></h4>
                    </Row>
                    <div className={feedBackType ? "feedback-pop-up-ck-editor focused-border" : "feedback-pop-up-ck-editor normal-border"}   >
                        <p></p>
                        <CKEditorFieldOneOnOne
                            key={data?.id}
                            editorPlaceholder={placeholderTextForCkeditor(data)}
                            initialValue={answersList?.length ? answersList?.find(item => item?.questionId === getIntFromString(data?.id))?.answer : ''}
                            changeHandler={(e) => { submitAnswer(data, e, tabArray, false, setTabArray) }}
                        />
                    </div>
                </Col>
            )
        }
        else {
            let validations = data?.questionAddress?.split(':')
            if (validations?.includes(feedBackType)) {
                return (
                    <Col span={24}>
                        <Row style={{ marginTop: '15px' }}>
                            <h4 className='feedback-pop-up-form-title' style={{ margin: "0px 0px 6px 15px" }}><span style={{ color: 'rgb(255, 77, 79)', marginRight: '3px', marginBottom: '6px' }}>* </span><ReactMarkdown >{data?.questionText}</ReactMarkdown></h4>
                        </Row>
                        <div className={feedBackType ? "feedback-pop-up-ck-editor focused-border" : "feedback-pop-up-ck-editor normal-border"} style={{ paddingRight: '20px', paddingBottom: '15px', borderRadius: "8px" }}>
                        <p></p>
                            <CKEditorFieldOneOnOne
                                initialValue={answersList?.length ? answersList?.find(item => item?.questionId === getIntFromString(data?.id))?.answer : ''}
                                key={data?.id}
                                editorPlaceholder={placeholderTextForCkeditor(data)}
                                changeHandler={(e) => { submitAnswer(data, e, tabArray, false, setTabArray) }}
                            />
                        </div>
                    </Col>
                )
            }
        }

    }





    const WithNoOption = (
        <Col span={24}>
            {
                questionList?.length && (
                    showQuestion(questionList[selectedTabValue])
                )
            }

        </Col>
    )

    const WithfillOption = (
        <Col span={24}>
            {selectedTabValue == 0 && <div style={{ marginTop: '15px' }}>
                <Row wrap={false}>
                    <h4 className='feedback-pop-up-form-title' style={{ margin: "0px 0px 6px 15px" }}>
                        <ReactMarkdown >Create Safety</ReactMarkdown>
                    </h4>
                </Row>

                <Col span={24}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }} wrap={false}>
                        <p className="feedback-form-title" style={{ marginTop: "10px" }}>I want to commend you for</p>
                        <Tooltip title={'doing/saying something of value'} >
                            <Input
                                style={{
                                    border: '1px solid #D9D9D9',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: "8px",
                                    width: "100%",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: '#0E0E2C',
                                    fontFamily: 'Poppins'
                                }}
                                value={question1Field1 ? question1Field1 : ""}
                                autoComplete='off'
                                onChange={e => { (setquestion1Field1(e.target.value)); handleDetailedTextEntered(e.target.value, 0) }}
                            />
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                        <p className="feedback-form-title" style={{ marginTop: "10px" }}>in / during</p>
                        <Tooltip title={'a specific meeting, presentation, project'} >
                            <Input
                                style={{
                                    border: '1px solid #D9D9D9',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: "8px",
                                    width: "100%",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: '#0E0E2C',
                                    fontFamily: 'Poppins'
                                }}
                                value={question1Field2 ? question1Field2 : ""}
                                autoComplete='off'
                                onChange={e => { setquestion1Field2(e.target.value); handleDetailedTextEntered(e.target.value, 1) }}
                            />
                        </Tooltip>
                        <p className="feedback-form-title" style={{ marginTop: "10px" }}>. Is this a good time?</p>
                    </div>
                </Col>
            </div>}
            {/* question 2 */}
            {selectedTabValue == 1 && <div style={{ marginTop: '15px' }}>
                <Row wrap={false}>
                    <h4 className='feedback-pop-up-form-title' style={{ margin: "0px 0px 6px 15px" }}>
                        <ReactMarkdown >Lay Out Context</ReactMarkdown>
                    </h4>
                </Row>

                <Col span={24} style={{ paddingBottom: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                        <p className="feedback-form-title" style={{ marginTop: "10px" }}>I am referring to the</p>
                        <Tooltip title={'event referred to above with more details if needed to make it clearly identifiable'} >
                            <Input
                                style={{
                                    border: '1px solid #D9D9D9',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: "8px",
                                    width: "100%",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: '#0E0E2C',
                                    fontFamily: 'Poppins'
                                }}
                                value={question2Field1 ? question2Field1 : ""}
                                autoComplete='off'
                                onChange={e => { setquestion2Field1(e.target.value); handleDetailedTextEntered(e.target.value, 0) }}
                            />
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                        <p className="feedback-form-title" style={{ marginTop: "10px" }}>with</p>
                        <Tooltip title={'names of people that participated in the event'} >
                            <Input
                                style={{
                                    border: '1px solid #D9D9D9',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: "8px",
                                    width: "100%",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: '#0E0E2C',
                                    fontFamily: 'Poppins'
                                }}
                                value={question2Field2 ? question2Field2 : ""}
                                autoComplete='off'
                                onChange={e => { setquestion2Field2(e.target.value); handleDetailedTextEntered(e.target.value, 1) }}
                            />
                        </Tooltip>

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0px', width: "100%" }}>
                        <p className="feedback-form-title" style={{ marginTop: "10px" }}>on</p>
                        <Tooltip title={'Optional date and time'} >
                            <Input
                                style={{
                                    border: '1px solid #D9D9D9',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: "8px",
                                    width: "100%",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: '#0E0E2C',
                                    fontFamily: 'Poppins'
                                }}
                                value={question2Field3 ? question2Field3 : ""}
                                autoComplete='off'
                                onChange={e => { setquestion2Field3(e.target.value); handleDetailedTextEntered(e.target.value, 2) }}
                            />
                        </Tooltip>
                    </div>
                </Col>
            </div>}
            {/* question 3 */}
            {selectedTabValue == 2 && <div style={{ marginTop: '15px' }}>
                <Row wrap={false}>
                    <h4 className='feedback-pop-up-form-title' style={{ margin: "0px 0px 6px 15px" }}>
                        <ReactMarkdown >Articulate behavioral evidence and then, impact</ReactMarkdown>
                    </h4>
                </Row>
                <Col span={24} style={{ marginBottom: "20px" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                        <p className="feedback-form-title" style={{ marginTop: "10px" }}>You</p>
                        <Tooltip title={'write verbatim what the person said or did, not your interpretation of it'} >
                            <Input
                                style={{
                                    border: '1px solid #D9D9D9',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: "8px",
                                    width: "100%",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: '#0E0E2C',
                                    fontFamily: 'Poppins'
                                }}
                                value={question3Field1 ? question3Field1 : ""}
                                autoComplete='off'
                                onChange={e => { setquestion3Field1(e.target.value); handleDetailedTextEntered(e.target.value, 0) }}

                            />
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                        <p className="feedback-form-title" style={{ marginTop: "10px" }}>. It felt like</p>
                        <Tooltip title={'describe the impact of their behavior on you, others and/or business, e.g. you were being disrespectful.'} >
                            <Input
                                style={{
                                    border: '1px solid #D9D9D9',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: "8px",
                                    width: "100%",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: '#0E0E2C',
                                    fontFamily: 'Poppins'
                                }}
                                value={question3Field2 ? question3Field2 : ""}
                                autoComplete='off'
                                onChange={e => { setquestion3Field2(e.target.value); handleDetailedTextEntered(e.target.value, 1) }}

                            />
                        </Tooltip>

                    </div>
                </Col>
            </div>
            }
            {/* question 4 */}
            {(feedBackType === '2' && selectedTabValue == 3) && (
                <div style={{ marginTop: '15px' }}>
                    <Row>
                        <h4 className='feedback-pop-up-form-title' style={{ margin: "0px 0px 6px 15px" }}>
                            <ReactMarkdown >Probe for possible alternatives</ReactMarkdown>
                        </h4>
                    </Row>

                    <Col span={24} style={{ marginBottom: '20px' }}>
                        <p className="feedback-form-title">How else could you have handled that? Here are my suggestions.</p>
                        <div style={{ display: 'flex', flexDirection: 'raw', gap: '0px' }}>
                            <Tooltip title={'Alternative ways of saying or doing it differently.'} >
                                <Input
                                    style={{
                                        border: '1px solid #D9D9D9',
                                        width: '100%',
                                        height: '40px',
                                        borderRadius: "8px",
                                        width: "100%",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: '#0E0E2C',
                                        fontFamily: 'Poppins'
                                    }}
                                    value={question4Field1 ? question4Field1 : ""}
                                    autoComplete='off'
                                    onChange={e => { setquestion4Field1(e.target.value); handleDetailedTextEntered(e.target.value, 0) }}
                                />
                            </Tooltip>

                        </div>
                    </Col>
                </div>
            )
            }
            {/* question 5 */}
            {selectedTabValue == 4 && <div style={{ marginTop: '15px' }}>
                <h4 className='feedback-pop-up-form-title' style={{ margin: "0px 0px 10px 15px", lineHeight: '30px' }}>
                    <ReactMarkdown >Support for next steps and commitments</ReactMarkdown>
                </h4>
                <Col span={24} style={{ marginBottom: '30px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {feedBackType === "1" ?
                            <Input
                                style={{
                                    border: '1px solid #D9D9D9',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: "8px",
                                    width: "100%",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: '#0E0E2C',
                                    fontFamily: 'Poppins'
                                }}
                                value={question5Field1}
                                autoComplete='off'
                                onChange={e => { setquestion5Field1(e.target.value); handleDetailedTextEntered(e.target.value, 0) }}

                            />

                            : feedBackType === "2" ?
                                <Input
                                    style={{
                                        border: '1px solid #D9D9D9',
                                        width: '100%',
                                        height: '40px',
                                        borderRadius: "8px",
                                        width: "100%",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: '#0E0E2C',
                                        fontFamily: 'Poppins'
                                    }}
                                    value={question5Field2}
                                    autoComplete='off'
                                    onChange={e => { setquestion5Field2(e.target.value); handleDetailedTextEntered(e.target.value, 1); handleDetailedTextEntered(e.target.value, 0) }}
                                /> : ""

                        }
                    </div>

                </Col>
            </div>}
        </Col>
    )


    const disabledButon = (value) => {
        let filterList
        if (!feedBackType) {
            return true
        } else {

            switch (fillInOption) {
                case true:
                    filterList = tabArrayDetailed?.find((item) => item?.id === value)
                    return checkFillInOptionsForText(value)
                case false:
                    filterList = tabArray?.find((item) => item?.id === value)
                    return filterList?.active || !checkAnswerText(value)
            }

        }

    }


    

    const Relatedmenu = () => (

        <Menu style={{ maxHeight: '200px', overflowY: 'auto', width: "100%", maxWidth: '400px' }}>
            {
                okrList?.length ?
                    okrList.map((data) =>
                        <Menu.Item 
                        className="feedback-okr-selection-drop"
                        className={ data?.isDiscarded === null ? "feedback-okr-selection-drop" : "feedback-okr-selection-drop discarded-okr" }
                         onClick={() => {setSelectedOkr(data);setInvalidOkr(false)}} >
                            <Row align='middle' wrap={false}>
                                <div style={{ display: 'flex', gap: "4px" }}>
                                    {
                                        data?.okrType === "OBJECTIVE" &&
                                        <div style={{ border: '1px solid black', opacity: '0.4', paddingLeft: '5px', paddingRight: '5px', marginRight: '5px', fontSize: '10px',
                                         borderColor: data?.isDiscarded === null ?  '#4CCB1F' : 'rgb(190, 67, 67)',
                                         color: data?.isDiscarded === null ?  '#4CCB1F' : 'rgb(190, 67, 67)'
                                         }}>{data?.isDiscarded === null ?  "o" : <div>Discarded</div>}</div>
                                        ||
                                        data?.okrType === "KEY_RESULT" &&
                                        <div style={{ border: '1px solid black', opacity: '0.4', paddingLeft: '3px', paddingRight: '3px', marginRight: '5px', fontSize: '10px',
                                         borderColor: data?.isDiscarded === null ?  '#009AF1' : 'rgb(190, 67, 67)',
                                         color: data?.isDiscarded === null ?  '#009AF1' : 'rgb(190, 67, 67)'
                                         }}>{data?.isDiscarded === null ?  "KR" : <div>Discarded</div>}</div>
                                        ||
                                        data?.okrType === "MILESTONE" &&
                                        <div style={{ border: '1px solid black', opacity: '0.4', paddingLeft: '5px', paddingRight: '5px', marginRight: '5px', fontSize: '10px', 
                                        borderColor: data?.isDiscarded === null ?  'rgb(250, 197, 60)' : 'rgb(190, 67, 67)',
                                         color: data?.isDiscarded === null ?  'rgb(250, 197, 60)' : 'rgb(190, 67, 67)'
                                    }}>{data?.isDiscarded === null ?  "M" : <div>Discarded</div>}</div>
                                    }
                                </div>
                                <div className="poppins" style={{ textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: 'nowrap' }}>
                                    {data?.label}
                                </div>

                            </Row>
                        </Menu.Item>
                    )
                    :
                    okrloading ?
                        <Menu.Item  >
                            <Spin spinning={true}></Spin>
                        </Menu.Item> :
                        <Menu.Item  >
                            no data found
                        </Menu.Item>


            }
        </Menu>
    );




    const handleFeedbackPreview = () => {
        
        if(checkBoxSelection === 'okr' && !selectedOkr){

            setInvalidOkr(true)

        }else{
            setInvalidOkr(false)
        setFeedbackPreviewDisplay(true)
        setFeedbackOperationsModalVisible(false);
        setFeedbackModalVisible(true);
        setFeedbackDetailSide("submitted")
        setFeedbackDetailsLoading(true);
        let answers = [];
        if (fillInOption) {
            answers = handleValidationwithFillin(false)
        }

        let answersTofilter = [];
        if (answers?.length) {
            answersTofilter = [...answers]

        } else {
            answersTofilter = [...answersList]
        }






        let answersForPreview = answersTofilter?.map((answer) => {
            let indexOfQuestion = questionList?.findIndex((question) => getIntFromString(question?.id) === answer?.questionId)
            return { node: { ...answer, question: { questionText: questionList[indexOfQuestion]?.questionText } } }
        })

        

        let preview = {
            badgeAward: { edges: givenKudos ? [{ node: { badge: givenKudos } }] : [] },
            detailFillIn: fillInOption,
            feedbackFrom: me?.employee,
            feedbackTo: Recipient,
            feedbackType:feedBackTypeEnum[feedBackType],
            okr: selectedOkr,
            response: {
                answerSet: { edges: answersForPreview }
            }
        }

        setFeedbackDetails(preview);
        setFeedbackDetailsLoading(false);

        }

        

    }





    return (
        <>
            <>
                <MetaTags
                    title="Give Feedback"
                    description="This is Create Feedback page."
                />
                  {/* Header of pop-up */}
                  <Row style={{ marginBottom: '30px', marginTop: '10px',width:loading ? '460px' : '100%'}} align="middle" justify="space-between">
                            <Col span={21}>
                                <Row justify="center">
                                    <div className="feedback-pop-up-header poppins">New Feedback</div>
                                </Row>
                            </Col>
                            <Col span={3}>
                                <img src={Agenda_close} style={{ cursor: 'pointer'}} onClick={(e) => { setFeedbackOperationsModalVisible(false); resetStateOfElemets(); setemployeeList([]) }} alt="" />
                            </Col>
                        </Row>

                <Row justify="center" align="top" className="feedback-pop-up-container" style={{ width: '100%',
            overflow:'auto', minHeight: '500px', maxHeight: '550px' }} >
                    <Spin style={{ marginTop: '150px' }} spinning={loading}>

                      

                        {
                            SurveyById && (
                                <div style={{}}>
                                    <Form
                                        form={form}
                                        name={"value"}
                                        style={{ width: '460px',maxHeight:'500px',overflow:'auto' }}
                                    // onFinish={() => !fillInOption ? handleValidation() : handleValidationwithFillin()}
                                    >

                                        {/* Wrapper of elements except survey component */}
                                        <div className="feedback-selection-elements-wrapper" >

                                            {/* Select receipient employee */}
                                            <Row justify="space-between">
                                                {
                                                    navigationFromOkr ?
                                                        <Row>
                                                            <p className="fw-500-feedback feedback-input-box-label poppins" style={{ fontSize: '15px' }}>Recipient : {recipientName}</p>

                                                        </Row> :
                                                        <Col className="feedback-receipient-selector poppins" >
                                                            <p className="fw-500-feedback feedback-input-box-label poppins" style={{ fontSize: '15px' }}><span style={{ color: '#ff4d4f' }}>*</span> Recipient</p>

                                                            <Select
                                                                showSearch={true}
                                                                onSearch={(e) => { handleSearch(e) }}
                                                                style={{
                                                                    width: '250px',
                                                                    borderColor: "red"
                                                                }}
                                                                className={receipientNameInvalid ? "red-bordered" : "normal-bordered"}
                                                                defaultValue={recipientName}
                                                                placeholder="Select Recipient"
                                                                onChange={(value, options) => handleMenuClick(options)}
                                                                id="fd_recipient" onClick={() => { scrolltoview('fd_recipient') }}
                                                            >

                                                                {
                                                                    employeeList?.map(item => {
                                                                        return <Select.Option className="poppins" key={item?.node?.user?.employee?.id} value={`${item?.node?.user?.firstName} ${item?.node?.user?.lastName}`} >{item?.node?.user?.firstName} {item?.node?.user?.lastName}</Select.Option>
                                                                    }
                                                                    )
                                                                }

                                                            </Select>
                                                            {
                                                                receipientNameInvalid && <p className="poppins" style={{ color: 'red', margin: '10px 0px 0px 0px', fontSize: '12px' }}>This Field is rquired</p>
                                                            }

                                                        </Col>
                                                }
                                            </Row>

                                            {/* Feedback checkBox panel */}
                                            <Row className="checkbox-panel-feedback" align="middle">
                                                {feedbackPermission?.okr_permission && <Row align="middle" className="okr-checkbox-selector">
                                                    <Checkbox checked={checkBoxSelection === "okr" ? true : false} onChange={(e) => onChnageOfCheckBoxOkr(e)}></Checkbox>
                                                    <div className="poppins" style={{ marginLeft: '20px', marginTop: '5px' }}>OKR</div>
                                                </Row>}
                                            </Row>


                                            {/* Activated dropdown on basis of selected checkbox */}
                                            {
                                                !navigationFromOkr && !hideselection && recipientName && checkBoxSelection === 'okr' && (
                                                    <Row wrap={false}>
                                                        <Col style={{ marginBottom: '10px' }} >
                                                            {
                                                                Recipient && (
                                                                    <Row gutter={10} style={{ marginTop: '15px' }}>
                                                                        <Col lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }} >
                                                                            <p style={{ fontSize: '14px' }} className="poppins  fw-500-feedback feedback-input-box-label" >OKR</p>
                                                                            <Dropdown overlay={Relatedmenu} trigger={['click']}>
                                                                                <DropdownButton

                                                                                    style={{
                                                                                        borderRadius: "8px",
                                                                                        height: "40px",
                                                                                    }}>
                                                                                    <p className="feedback-okr-selection-drop poppins" style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', fontSize: '14px', marginBottom: '0px' }}>
                                                                                        {selectedOkr ? <span >{selectedOkr?.label}</span>
                                                                                            :
                                                                                            <span style={{ color: "rgb(217, 217, 217)" }}>Select OKR</span>
                                                                                        }

                                                                                    </p>
                                                                                    <DownOutlined style={{ color: "#BFBFBF" }} />
                                                                                </DropdownButton>
                                                                            </Dropdown>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }
                                                            {
                                                                  invalidOkr && 
                                                                  <div className="poppins" style={{color:'red',fontSize:'1em',marginTop:'0.5em'}}>Please select a valid Okr</div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                )

                                            }

                                            <Row className="space-between-elements-feedback" justify={givenKudos ? "center" : "start"} align="bottom"
                                                style={{ gap: givenKudos ? "0px" : '20px', position: 'relative', right: givenKudos ? '35px' : '0px' }}
                                                wrap={false}>
                                                <div className="feedback-type-selector">
                                                    <p className="fw-500-feedback feedback-input-box-label poppins" style={{ fontSize: '15px' }}><span style={{ color: '#ff4d4f' }}>*</span> Type of Feedback</p>
                                                    <Dropdown overlay={FeedBackTypemenu} trigger={['click']} >
                                                        <DropdownButton
                                                            style={{
                                                                border: invalidFeedbackType ? '1px solid red' : '1px solid #D9D9D9',
                                                                borderRadius: "8px",
                                                                height: "40px",
                                                                background: "#FFF",
                                                                paddingTop: "20px",

                                                            }}
                                                        >
                                                            <p className="feedback-input-field-style poppins" style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>{feedBackType ? getSelectedFeedbackType(feedBackTypeEnum,feedBackType) : <span style={{ color: "#D9D9D9" }}>Select type of feedback</span>}</p>
                                                            <DownOutlined style={{ color: "#BFBFBF", marginTop: "-10px" }} />
                                                        </DropdownButton>
                                                    </Dropdown>
                                                    {
                                                        invalidFeedbackType && <div style={{ color: 'red', marginTop: '7px' }}>This Field is Required</div>
                                                    }
                                                </div>

                                                {
                                                    givenKudos ? <GivenKudos setGivenKudos={setGivenKudos} fromGiveFeedback={true} givenKudos={givenKudos} {...props} />
                                                        :

                                                        feedBackType === "1" ?
                                                            feedbackPermission?.kudos_permission && <Button onClick={(e) => { setFeedbackOperationsModalVisible(false); setGiveKudosPopUpVisible(true) }} className='give-kudos-button'><Row style={{ marginTop: '3px', gap: '5px' }} wrap={false} justify="center" align="middle"><div className='poppins'> Give kudos</div> <img src={green_badge} /></Row> </Button>
                                                            : null
                                                }
                                            </Row>
                                            <Row align="middle" style={{ marginTop: '10px' }} >
                                                <p style={{ marginTop: '8px', marginLeft: '20px' }} className="feedback-form-title">Detailed</p>
                                                <Switch style={{ marginLeft: '14px' }} checked={fillInOption} onChange={(e) => {
                                                    handleDetailedFormToggle(e)

                                                }} disabled={!feedBackType ? true : false} defaultValue={fillInOption} />
                                            </Row>


                                        </div>




                                        {/* survey component */}
                                        {

                                            <div id="surveyComponent" style={{ width: "100%" }}>
                                                {
                                                    !fillInOption && (
                                                        <div>
                                                            <Row justify="center" align="center" style={{ width: "100%", gap: "20px", flexWrap: "nowrap", marginBottom: '20px', marginTop: '25px' }}>
                                                                {tabArray?.length && tabArray?.map((item, index) => (
                                                                    item?.value == 'S' ?
                                                                        <Row className="feedback-tab-array-container">
                                                                            <Button className={item?.id == selectedTabValue ? "feedback-tab-btn-focus" : !item?.active ? "feedback-tab-btn-active" : "feedback-tab-btn"} disabled={feedBackType == '1' && item?.value == 'P' || checkDisblaeButton(item?.value, index, tabArray) || (!feedBackType && item?.value == 'P')} onClick={() => { setSelectedTabValue(item?.id) }}
                                                                            >
                                                                                <Row style={{ paddingLeft: item?.value === "L" ? "2px" : "0px" }}>
                                                                                    <div>
                                                                                        {item?.value}
                                                                                    </div>

                                                                                </Row>
                                                                            </Button>
                                                                            <Tooltip title={ToolTipTitle} placement='topLeft'  overlayClassName='feedback-info-tooltip'>
                                                                                <img style={{ position: 'relative', top: '-48px', left: '40px', width: '15px', zIndex: '1500' }} src={info_button} />
                                                                            </Tooltip>
                                                                        </Row>
                                                                        :
                                                                        <Button className={item?.id == selectedTabValue ? "feedback-tab-btn-focus" : !item?.active ? "feedback-tab-btn-active" : "feedback-tab-btn"} disabled={feedBackType == '1' && item?.value == 'P' || checkDisblaeButton(item?.value, index, tabArray) || (!feedBackType && item?.value == 'P')} onClick={() => { setSelectedTabValue(item?.id) }}>
                                                                            <Row style={{ paddingLeft: item?.value === "L" ? "2px" : "0px" }}>
                                                                                {item?.value}

                                                                            </Row>
                                                                        </Button>

                                                                ))}
                                                            </Row>
                                                            {WithNoOption}
                                                        </div>
                                                    )
                                                }
                                                {
                                                    fillInOption && (
                                                        <div>
                                                            <Row justify="center" align="center" style={{ width: "100%", gap: "20px", flexWrap: "nowrap", marginBottom: '20px', marginTop: '25px' }}>
                                                                {tabArrayDetailed?.length && tabArrayDetailed?.map((item, index) => (
                                                                    item?.value == 'S' ?
                                                                        <Row className="feedback-tab-array-container">
                                                                            <Button className={item?.id == selectedTabValue ? "feedback-tab-btn-focus" : !item?.active ? "feedback-tab-btn-active" : "feedback-tab-btn"}
                                                                                disabled={feedBackType == '1' && item?.value == 'P' || checkDisblaeButtonWithFillInOption(item?.value, index, tabArrayDetailed) || (!feedBackType && item?.value == 'P')} onClick={() => { setSelectedTabValue(item?.id) }}
                                                                            >
                                                                                <Row style={{ paddingLeft: item?.value === "L" ? "2px" : "0px" }}>
                                                                                    <div>
                                                                                        {item?.value}
                                                                                    </div>

                                                                                </Row>
                                                                            </Button>
                                                                            <Tooltip title={ToolTipTitle} placement='topLeft'  overlayClassName='feedback-info-tooltip'>
                                                                                <img style={{ position: 'relative', top: '-48px', left: '40px', width: '15px', zIndex: '1500' }} src={info_button} />
                                                                            </Tooltip>
                                                                        </Row>
                                                                        :
                                                                        <Button className={item?.id == selectedTabValue ? "feedback-tab-btn-focus" : !item?.active ? "feedback-tab-btn-active" : "feedback-tab-btn"} disabled={feedBackType == '1' && item?.value == 'P' || checkDisblaeButtonWithFillInOption(item?.value, index, tabArrayDetailed) || (!feedBackType && item?.value == 'P')} onClick={() => { setSelectedTabValue(item?.id) }}>
                                                                            <Row style={{ paddingLeft: item?.value === "L" ? "2px" : "0px" }}>
                                                                                {item?.value}

                                                                            </Row>
                                                                        </Button>

                                                                ))}
                                                            </Row>
                                                            {WithfillOption}
                                                        </div>
                                                    )
                                                }
                                            </div>

                                        }

                                       
                                    </Form>

                                   
                                </div>
                            )
                        }

                    </Spin>



                </Row>
                                        <div align="center"  id="feedbackContainer">
                                         {selectedTabValue === questionList?.length - 1 &&
                                                <Row justify="center" align="middle" style={{ gap: '20px', marginTop: '30px' }}>
                                                    <Button style={{ padding: '0px 21px',marginTop:'0px' }} className="feedback-save-btn-modal" onClick={(e) => handleFeedbackPreview()} disabled={!fillInOption ? disabledButon(selectedTabValue) : false} >Preview</Button>
                                                </Row>
                                            }
                                            {selectedTabValue !== questionList?.length - 1 &&
                                                <Row justify="center" align="center" style={{ gap: "10px", paddingTop: 'auto' }}>
                                                    <Button className="feedback-cancel-btn-modal" type="primary" style={{ marginTop: '30px' }} onClick={() => { setFeedbackOperationsModalVisible(false); setSelectedTabValue(); setAnswers(); resetStateOfElemets(); setemployeeList([]) }}>Cancel</Button>
                                                    <Button className="feedback-save-btn-modal" disabled={disabledButon(selectedTabValue, fillInOption ? tabArrayDetailed : tabArray)} style={{ marginTop: '30px' }} onClick={(e) => handleNextSubmit(e, feedBackType == '1' && selectedTabValue === 2 ? selectedTabValue + 2 : selectedTabValue + 1)
                                                    }>Next</Button>
                                                </Row>}
                                        </div>
                                       
                
                
            </>
        </>
    );
};

export default GiveFeedbackView;

const DropdownButton = styled(Button)`
width:230px;
display:flex;
justify-content:space-between;
align-items:center;
gap:5px
`

