import React from 'react';

import { compose } from '../../core';

import GenericToolView from '../components/GenericToolView';
import { withApollo } from '@apollo/client/react/hoc';
import { TOOL_BY_ID_OPTIMIZED } from '../graphql/ToolByidOptimized.gql';
import { WHOLE_IDENTITY_ASSESSMENT_IDENTIFIER } from 'config';
import { SURVEY_PERMISSION_CHECK } from '../graphql/SurveyPermission.gql';
import { WHOLE_GENERIC_TOOL_ID } from 'config';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { getIntFromString, getSurveyBase64StringFromInt } from 'modules/look';
import { Spin } from 'antd';
import { DIVERSITY_SURVEY_TOOL_ID } from 'config';
import { WHOPOSHORO_SURVEY_TOOL_ID } from 'config';
import { WHOLE_SURVEY_TOOL_ID } from 'config';
import { DIVERSITY_PROFILE_IDENTIFIER } from 'config';
import { WHOPOSHORO_ASSESSMENT_SURVEY_IDENTIFIER } from 'config';
import { SIMPLE_SURVEY_BY_ID } from '../graphql/surveyByID.gql';
import { SURVEY_WHOPOSHORO } from 'config';

const GenericTool = props => {

  const { client, me,tool_id } = props
  const [getToolById, setToolData] = React.useState()
  const [loading, setloading] = React.useState(true)
  const [isSurveyTool, setIsSurveyTool] = React.useState(false)
  const [genericToolPermitted, setGenericToolPermitted] = React.useState(false)
  const [permissionLoading,setPermissionLoading] = React.useState(true)
  const [survey_360_detail,SetSurvey360Detail] = React.useState()

  const withToolById = async (value) => {
    try {
      let id = value;
      const { data } = await client.query({
        query: TOOL_BY_ID_OPTIMIZED,
        variables: { toolId: Number(id) },
        fetchPolicy: 'network-only'
      });
  
      if (data?.getToolById) {
        setToolData(data?.getToolById)
      }
      setTimeout(()=>{
        setloading(false)
      },1000)
    } catch (error) {
      
    }
  }


  React.useEffect(() => {

    if (parseInt(props.match.params.id) === DIVERSITY_SURVEY_TOOL_ID ||
      parseInt(props.match.params.id) === WHOLE_GENERIC_TOOL_ID ||
      parseInt(props.match.params.id) === WHOPOSHORO_SURVEY_TOOL_ID
    ) {
      setIsSurveyTool(true)
    }
  }, [props.match.params.id])


  const getSurveyPermission = async () => {
    setPermissionLoading(true)
    let identifier;
    if (parseInt(props.match.params.id) === DIVERSITY_SURVEY_TOOL_ID) {
      identifier = DIVERSITY_PROFILE_IDENTIFIER
    } else if (parseInt(props.match.params.id) === WHOLE_GENERIC_TOOL_ID) {
      identifier = WHOLE_IDENTITY_ASSESSMENT_IDENTIFIER
    }
    else if (parseInt(props.match.params.id) === WHOPOSHORO_SURVEY_TOOL_ID) {
      identifier = WHOPOSHORO_ASSESSMENT_SURVEY_IDENTIFIER
    }


    try {
      const { data } = await client.query({
        query: SURVEY_PERMISSION_CHECK,
        variables: { identifier: identifier },
        fetchPolicy: 'network-only'
      });

      if (data?.allSurveys) {
        let count =
          data?.allSurveys?.totalCount;

        if (count > 0) {
          setGenericToolPermitted(true)
        }
      }
    } catch (e) {
      console.error(e)
    }
    setPermissionLoading(false)
  }


  React.useEffect(() => {
    if (me && isSurveyTool) {
      getSurveyPermission()

    }
  }, [me, isSurveyTool])

  React.useEffect(() => {
    
    let id = tool_id||props.match.params.id
    if(id){
      withToolById(id)
    }
    if (!isSurveyTool) {
      withToolById(id)
    } 

  }, [genericToolPermitted])

React.useEffect(()=>{
  if(props.match.params.id && me){
    let invite_tool = [WHOPOSHORO_SURVEY_TOOL_ID]
    if(invite_tool?.includes(parseInt(props.match.params.id))){
      let filter = {
        id:getSurveyBase64StringFromInt(SURVEY_WHOPOSHORO),
        user:me?.id
      }
      surveyDetail(filter)
    }
    
  }
},[props.match.params.id,me])

const surveyDetail = async (filter) => {
  try {
    const { data } = await client.query({
      query: SIMPLE_SURVEY_BY_ID,
      variables: filter,
      fetchPolicy: 'network-only'
    });
    if(data?.SurveyById){
      let survey = data?.SurveyById
      if(survey?.multisourceResponse && survey?.responseSet?.edges?.length){

        SetSurvey360Detail({
          id:survey?.id,
          response_id:survey?.responseSet?.edges[0]?.node?.id
        })
      }
    }
  } catch (error) {
    
  }
}

  return (
    <>
      {
        isSurveyTool ?
          <div>
            {
              !permissionLoading &&
           
                <div>
                  {
                    <div>
                      {
                        (!genericToolPermitted || (getToolById==null&&!loading)) ?
                          <NoPermissionView content={"You Don't Have Permission To View This Tool."}/>
                          :
                          <GenericToolView {...props} getToolById={getToolById} loading={loading} survey_360_detail={survey_360_detail}/>   
                      }
                    </div>
                  }
                </div>
            }
          </div>
          :
          <div>
              {
                (getToolById==null&&!loading) ?
                  <NoPermissionView content={"You Don't Have Permission To View This Tool."}/>
                  :
                <GenericToolView {...props} getToolById={getToolById} loading={loading}  /> 
              }
            </div>
          
      }

    </>
  );
};

export default compose(withApollo)(GenericTool);

