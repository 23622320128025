import React from 'react';
import { compose } from '../../core';
import RedinessHomeView from '../components/ReadinessHomeComponent';
import NotFound from '../../page/containers/NotFound'
import { globalPermissionValidator } from 'modules/look';
import { readiness_permission } from 'Permissions/readiness.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { Spin } from 'antd';

const ReadinessHome = (props) => {
    const { userPermission } = props
    const [permission, setpermission] = React.useState()
    
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(readiness_permission, userPermission)
            setpermission(permission)
        }
    }, [userPermission])

    return (
        <>
            {(permission && permission?.required_permission) && (
                <RedinessHomeView {...props}  permission={permission} />
            )}

            {(permission && !permission?.required_permission) && (
                <NoPermissionView />
            )}
            {
                !permission && (
                   <div style={{width:"100%",minHeight:"80vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
                     <Spin spinning={true}></Spin>
                   </div>
                )
            }
        </>



    )

}
export default compose()(ReadinessHome);