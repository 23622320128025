import gql from 'graphql-tag';
import { CHOICE_TYPE } from './ChoiceType.gql';

export const EDIT_CHOICE = gql`
  ${CHOICE_TYPE}
  mutation editChoice($id: ID!, $inputType: String, $label: String, $questionId: Int, $sequence: Int, $value: String) {
    updateChoice(
      id: $id
      inputType: $inputType
      label: $label
      questionId: $questionId
      sequence: $sequence
      value: $value
    ) {
      choice {
        ...ChoiceType
      }
    }
  }
`;
