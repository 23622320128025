export const survey = 'survey'
export const report = 'report'
export const survey_list = 'organization-survey'
export const survey_360 = '360-survey'
export const team ="team"
const survey_module_route = {
    org_survey:`/${survey}/${survey_list}`,
    report: `/${survey}/${report}`,
    survey_360:`/${survey}/${survey_list}/${survey_360}/:surveyid/:responseid`,
    survey_360_add:`/${survey}/${survey_list}/${survey_360}/add/:surveyid/:responseid`,
    survey_360_report:`/${survey}/${report}/${survey_360}-${report}/:surveyid/:responseid`,
    survey_360_manager_report:`/${survey}/${report}/${survey_360}-${team}-${report}/:surveyid`,
    report_tool:`/${survey}/${report}/survey-report/:id`
}

export default survey_module_route