
import gql from 'graphql-tag';

export const COMPLETE_SURVEY =gql`
query surveyResponses($user:ID,$valuePart1:ID,$valuePart2:ID,$strengthsPart1:ID,$strengthsPart2:ID,$personalityPart1:ID,$personalityPart2:ID,$knowledgePart1:ID,$knowledgePart2:ID,$stengthQuestions:[ID],$personalityQuestions:[ID]){
    ValuesPart1: surveyResponses(survey: $valuePart1, user: $user) {
      totalCount
    }
    ValuesPart2: surveyResponses(survey: $valuePart2, user: $user) {
      totalCount
    }
    StrengthsPart1: answers(question_In: $stengthQuestions, response_User: $user, response_Survey: $strengthsPart1) {
      totalCount
      edges {
        node {
          id
  
        }
      }
    }
    StrengthsPart2: surveyResponses(survey: $strengthsPart2, user: $user) {
      totalCount
    }
    PersonalityPart1: answers(question_In: $personalityQuestions, response_User: $user, response_Survey: $personalityPart1) {
      totalCount
      edges {
        node {
          id
  
        }
      }
    }
    PersonalityPart2: surveyResponses(survey: $personalityPart2, user: $user) {
      totalCount
    }
    knowledgePart1: surveyResponses(survey: $knowledgePart1, user: $user) {
      totalCount
    }
    knowledgePart2: surveyResponses(survey: $knowledgePart2, user: $user) {
      totalCount
    }
}
`



export const COMPLETE_SOUL_SURVEY =gql`
query surveyResponses($user:ID,$value:ID,$strength:ID,$personality:ID,$knowledge:ID,$gender:ID,$HighestDegree:ID,$YearsOfExperience:ID,$CurrentNationality:ID,$diversity:ID){
    Values: surveyResponses(survey: $value, user: $user) {
      totalCount
    }
    Strength: surveyResponses(survey: $strength, user: $user) {
      totalCount
    }
    Personality: surveyResponses(survey: $personality, user: $user) {
      totalCount
    }
    knowledge: surveyResponses(survey: $knowledge, user: $user) {
      totalCount
    }
    diversity:surveyResponses(first: 1, user: $user, survey: $diversity, orderBy: ["-responseDate"]) {
      edges{
        node{
          id
          gender: answerSet(question: $gender){
            totalCount
          }
   
          HighestDegree: answerSet(question: $HighestDegree){
            totalCount
          }

          YearsOfExperience: answerSet(question: $YearsOfExperience){
            totalCount
          }
   
          CurrentNationality: answerSet(question: $CurrentNationality){
            totalCount
          }
        }
      }
    }
}
`