import { compose } from 'modules/core';
import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { THREECS_RESPONSE_QUERY } from '../graphql/ThreeCSresponse.gql';
import { getIntFromString, getQuestionBase64StringFromInt, getSurveyBase64StringFromInt, globalPermissionValidator } from 'modules/look';
import { report_tool_3cs, SURVEY_ID_3CS } from '../../../config';
import { question_id_for_3cs } from '../../../config';
import ThreeCSReportView from '../components/ThreeCSReportView';
import { message, Spin } from 'antd';
import { TOOL_BY_ID_OPTIMIZED } from 'modules/tool/graphql/ToolByidOptimized.gql';
import moment from 'moment';
import { survey_3cs_permission } from 'Permissions/threeCS.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';

const ThreeCSReportContainer = (props) => {
    const { client, me, userPermission } = props
    const [response_list, SetResponseList] = React.useState([])
    const [api_loading, SetApiLoading] = React.useState(false)
    const [getToolById, setToolData] = React.useState()
    const [permission, SetPermission] = React.useState();
    React.useEffect(() => {

        if (userPermission?.length && me) {
            let permission = globalPermissionValidator(survey_3cs_permission, userPermission)
            if (permission?.required_permission) {
                ResponseList3cs({})
            }
            SetPermission(permission)
        }

    }, [userPermission, me])

    const ResponseList3cs = async (filter) => {
        if (!filter?.search) {
            SetApiLoading(true)
        }
        try {
            const { data } = await client.query({
                query: THREECS_RESPONSE_QUERY,
                variables: {
                    survey: getSurveyBase64StringFromInt(SURVEY_ID_3CS),
                    question: getQuestionBase64StringFromInt(question_id_for_3cs),
                    user: me?.id,
                    ...filter
                },
                fetchPolicy: 'network-only'
            });

            if (data?.answers) {
                SetApiLoading(false)
                let list = data?.answers?.edges?.map(({ node }) => {
                    let user_name = node?.answer?.replace('<email style="display:none">', '(')?.replace('</email>', ')')
                    if (!user_name) {
                        return null
                    }
                    return {
                        id: node?.response?.id,
                        response_date: node?.response?.responseDate,
                        user_name
                    }
                })?.filter(i => i)?.sort((a, b) => moment(b.response_date)?.unix() - moment(a.response_date)?.unix())
                SetResponseList(list)
            }
        } catch (error) {
            console.log('errror', error);

        }
    }

    const viewReport = async (id) => {
        SetApiLoading(true)
        setToolData(null)
        try {
            const { data } = await client.query({
                query: TOOL_BY_ID_OPTIMIZED,
                variables: { responseId: getIntFromString(id), toolId: report_tool_3cs },
                fetchPolicy: 'network-only'
            });
            if (data?.getToolById) {
                SetApiLoading(false)
                setToolData(data?.getToolById)
            } else {
                message.error("failed to fetch report")
                SetApiLoading(false)
            }
        } catch (error) {
            message.error("failed to fetch report")
            SetApiLoading(false)
        }
    }

    return (
        <>
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }

            {
                (permission && permission?.required_permission) && (
                    <ThreeCSReportView {...props} response_list={response_list} api_loading={api_loading} viewReport={viewReport} getToolById={getToolById} ResponseList3cs={ResponseList3cs} />
                )
            }

            {
                (permission && !permission?.required_permission) && (
                    <NoPermissionView />
                )
            }

        </>
    )

}

export default compose(withApollo)(ThreeCSReportContainer)