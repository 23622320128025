import React, { useState } from 'react';
import { compose } from '../../core';
import FeedbackView from '../components/feedbackView'
import NotFound from '../../page/containers/NotFound'
import { withToolById } from 'modules/tool/containers/ToolOperations';
import { useHistory } from 'react-router-dom';
import { withCreateFeedback } from './feedbackOperations';
import { withCreateBadgeAward } from 'modules/kudos-user/container/badgeAwardOperationFeedback';
import { getEmployeeBase64StringFromInt } from 'modules/look';
import { EMPLOYEE_BY_ID_FEEDBACK } from '../graphql/employeeById.gql';
import { withApollo } from '@apollo/client/react/hoc'
import { FEEDBACK_BY_ID } from '../graphql/FeedbackById.gql';





const NewViewFeedbackContainer = props => {

  const {client} = props
  const history = useHistory();
  const typeList=['received','submitted']
  const [Recipient, setRecipient] = React.useState(undefined);
  const [recipientName, setrecipientName] = React.useState(undefined);
  const [feedbackDetails, setFeedbackDetails] = React.useState();
  const [feedbackDetailsLoading, setFeedbackDetailsLoading] = React.useState(false)
  let type = props.match.params.type;



  const getEmployeeById = async (id) => {
    
    let employee = getEmployeeBase64StringFromInt(id)
      try{
        const { data } = await client.query({
          query: EMPLOYEE_BY_ID_FEEDBACK,
          variables: {id:employee},
        });
        if (data?.employeeById) {
           setRecipient(data?.employeeById)
           setrecipientName(`${data?.employeeById?.user?.firstName} ${data?.employeeById?.user?.lastName}`)
        }
      }catch(e){
        console.error(e)
      }
     
    
  }


  const getFeedbackDetails = async (id) => {

  setFeedbackDetailsLoading(true)
    try {
      const { data } = await client.query({
        query: FEEDBACK_BY_ID,
        variables: { id: id },
        fetchPolicy: 'network-only'
      });

      if (data?.feedbackById) {
        setFeedbackDetails(data?.feedbackById)
      }
    } catch (e) {
      console.error(e)
    }
    
    setFeedbackDetailsLoading(false)
  }
 


  if(!typeList.includes(type)){
    return (
      <>
      <NotFound></NotFound>
      </>
    )
  }
  else{
    return (
      <>
        <FeedbackView {...props} 
        Recipient = {Recipient} setRecipient = {setRecipient}
        recipientName = {recipientName} setrecipientName = {setrecipientName}
          type={type} history={history}
          getEmployeeById = {getEmployeeById}
          feedbackDetails={feedbackDetails} setFeedbackDetails={setFeedbackDetails}
          getFeedbackDetails = {getFeedbackDetails}
          feedbackDetailsLoading = {feedbackDetailsLoading} setFeedbackDetailsLoading = {setFeedbackDetailsLoading}
          ></FeedbackView>
      </>
    );
  }


};

export default compose(withCreateFeedback, withCreateBadgeAward, withApollo)(NewViewFeedbackContainer);
