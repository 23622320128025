// @ts-nocheck
import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Menu, Row, Spin, Tooltip } from "antd";
import GivenKudosEditFeedback from "modules/feedback/components/GivenKudosEditFeedback";
import CKEditorFieldOneOnOne from "modules/look/components/form-components/CKEditor-one-on-one";
import React from "react";
import green_badge from '../../assets/green_badge.svg'
import styled from "styled-components";
import info_button from '../../assets/info-button.svg';
import { getIntFromString, getSelectedFeedbackType, MetaTags, placeholderTextForCkeditor, scrolltoview, ToolTipTitle, withMarkdownFieldValue } from "../../look";
import FeedbackQuestion from "modules/look-v2/Components/FeedbackQuestion";


const OneOnOneFeedbackUpdateView = props => {
  const { history, SurveyById, feedbackById, okrByrecipiant, onCancel, handleFeedbackPreview,
    questionList, setquestionList, answersList, setAnswers, tabArray, feedBackTypeEnum, setRecipient, setFeedBackType, feedBackType,
    givenKudos, setGivenKudos, setGiveKudosPopUpVisible, permission, setEditFeedback } = props


  const [loading, setloading] = React.useState(true);
  const [keyResultsActive, setKeyResultActive] = React.useState(false);
  const [recipientName, setrecipientName] = React.useState();
  const [form] = Form.useForm();
  const [fillInOption, setfillInOption] = React.useState(false);

  const [initialdataSet, setinitialdataSet] = React.useState(false);
  const [okrList, setokrList] = React.useState([])
  const [okrid, setokrid] = React.useState();
  const [selectedTabValue, setSelectedTabValue] = React.useState(0)


  React.useEffect(() => {
    if (okrByrecipiant) {
      setokrList(okrByrecipiant?.edges?.map(({ node }) => { return { label: node?.okr?.title, id: node?.okr?.id } }))
    }
  }, [okrByrecipiant])

  React.useEffect(() => {
    if (feedbackById && !initialdataSet) {
      setRecipient(feedbackById?.feedbackTo)
      setrecipientName(`${feedbackById?.feedbackTo?.user?.firstName} ${feedbackById?.feedbackTo?.user?.lastName}`)
      if (feedbackById?.okr) {
        setKeyResultActive(true)
        setokrid(feedbackById?.okr?.id)
      }
      if (feedbackById?.feedbackType === 'MOTIVATIONAL_RECOGNITION') {
        setFeedBackType("1")
      }
      else if (feedbackById?.feedbackType === 'DEVELOPMENTAL_CONSTRUCTIVE') {
        setFeedBackType("2")
      }

      // not resetting the answers when answers in edited feedback are available 
      if (!answersList?.length) {
        let answers = []
        feedbackById.response.answerSet.edges.forEach(item => {
          let data = {
            questionId: getIntFromString(item?.node?.question?.id),
            answer: item?.node?.answer
          }
          answers.push(data)
        })
        setAnswers(answers)
      }


      setinitialdataSet(true)
    }
  }, [feedbackById, initialdataSet])


  const back = () => {
    history.goBack();
  };


  React.useEffect(() => {
    if (SurveyById) {
      let list = SurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges?.map(({ node }) => node)
      setquestionList(list)
      setloading(false)
    }
  }, [SurveyById])


  const FeedBackTypemenu = () => (
    <Menu >
      <Menu.Item key="1" onClick={() => setFeedBackType("1")}>
        Motivational/Recognition
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setFeedBackType("2")}>
        Developmental/Constructive
      </Menu.Item>
    </Menu>
  );


  const submitAnswer = (data, value) => {
    let feed = withMarkdownFieldValue("answer", { answer: value })
    let answerSet = {
      questionId: getIntFromString(data?.id),
      ...feed
    }
    let tempAnswer = [].concat(answersList)
    let previous = tempAnswer.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
    if (previous) {
      let index = tempAnswer.indexOf(previous)
      tempAnswer.splice(index, 1)
      tempAnswer.push(answerSet)
    }
    else {
      tempAnswer.push(answerSet)
    }
    setAnswers(tempAnswer)

  }


  const showQuestion = (data) => {
    let createCustomID = 'fb_' + getIntFromString(data?.id)
    let previous = answersList?.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
    if (!data?.questionAddress) {
      return (
        <Col span={24}>
          <Row style={{ marginTop: '15px' }}>
            <FeedbackQuestion data={data} />
          </Row>

          <div id={createCustomID} className="one-on-one-ck-editor" style={{ paddingRight: '20px', border: " 1px solid #D9D9D9", borderRadius: "8px", height: 'auto', margin: 'auto' }} onClick={() => { scrolltoview(createCustomID) }}>
          <p></p>
            <CKEditorFieldOneOnOne
              key={data?.id}
              editorPlaceholder={placeholderTextForCkeditor(data)}
              initialValue={previous?.answer}
              changeHandler={(e) => { submitAnswer(data, e) }}
            />
          </div>

        </Col>
      )
    }
    else {
      let validations = data?.questionAddress?.split(':')
      if (validations?.includes(feedBackType)) {
        return (
          <Col span={24}>
            <Row style={{ marginTop: '15px' }}>

             <FeedbackQuestion data={data} />


            </Row>
            <div id={createCustomID} className="one-on-one-ck-editor" style={{ paddingRight: '20px', border: " 1px solid #D9D9D9", borderRadius: "8px" }} onClick={() => { scrolltoview(createCustomID) }}>
            <p></p>
              <CKEditorFieldOneOnOne
                key={data?.id}
                editorPlaceholder={placeholderTextForCkeditor(data)}
                initialValue={previous?.answer}
                changeHandler={(e) => { submitAnswer(data, e) }}
              />
            </div>

          </Col>

          
        )
      }
    }

  }

  const WithNoOption = (
    <Col span={24}>
      {
        questionList?.length && (
          showQuestion(questionList[selectedTabValue])
        )
      }

    </Col>
  )
  const disabledButon = (value, array) => {
    let filterList = array?.find((item) => item?.id === value)
    console.log("filterList", filterList)
    return filterList?.active
  }
  return (
    <>
      <>
        <MetaTags
          title="Update Feedback"
          description="This is Update Feedback page."
        />
        <Row justify="center" align="middle" style={{ width: '100%', minHeight: '500px' }} >
          <Spin style={{ width: '100%' }} spinning={loading}>
            {
              SurveyById && (
                <div style={{ padding: '0px 20px' }}>
                  <Form
                    style={{ width: '100%' }}
                    form={form}
                    name={"value"}
                  >
                    <Row gutter={5} justify="center" align="center" style={{ minHeight: "400px", maxHeight: "400px" }}>
                      {/* <Col  style={{overflow:'auto',marginBoottom:'10px'}}> */}
                      <Row justify="center" align="center" style={{ gap: "20px" }}>
                        <div>
                          <p className="one-on-one-form-title">Type of Feedback</p>
                          <Dropdown disabled={true} overlay={FeedBackTypemenu}  >
                            <DropdownButton
                              style={{
                                border: '1px solid #D9D9D9',
                                borderRadius: "8px",
                                height: "40px",
                                background: "#FFF",
                                paddingTop: "20px"
                              }}
                            >
                              <p className="one-on-one-input-field-style" style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>{feedBackType ? getSelectedFeedbackType(feedBackTypeEnum, feedBackType) : <span style={{ color: "#D9D9D9" }}>Select Feedback type</span>}</p>
                              <DownOutlined style={{ color: "#BFBFBF", marginTop: "-10px" }} />
                            </DropdownButton>
                          </Dropdown>

                          {/* give kudos button panel */}

                          
                           
                          <div justify="start" className="give-kudos-wrapper" style={{ marginTop: '25px',marginBottom:'25px',position:'relative',right: givenKudos ?  '15px' : 'auto' }}>

                            {
                              givenKudos && permission?.kudos_permission ? <GivenKudosEditFeedback buttonStyle={{top:"-19px",left:'58px'}} setGivenKudos={setGivenKudos} fromGiveFeedback={true} givenKudos={givenKudos} {...props} />
                                :
                                feedBackType === "1" && permission?.kudos_permission ?
                                  <Button style={{ height: "39px", width: '137px' }} onClick={(e) => { setEditFeedback(false); setGiveKudosPopUpVisible(true) }} className='give-kudos-button'><Row style={{ marginTop: '3px', gap: '5px' }} wrap={false} justify="center" align="middle"><div className='poppins'> Give kudos</div> <img src={green_badge} /></Row> </Button>
                                  : null
                            }

                          </div>

                        </div>



                      </Row>


                      {/* survey component */}
                      {
                        feedBackType && (
                          <div style={{ width: "100%" }}>
                            {
                              !fillInOption && (
                                <div>
                                  <Row justify="center" align="center" style={{ width: "100%", gap: "20px", flexWrap: "nowrap" }}>
                                    {tabArray?.length && tabArray?.map((item) => (
                                      <Button className={item?.id == selectedTabValue ? "one-on-one-feedback-tab-btn-focus" : !item?.active ? "one-on-one-feedback-tab-btn-active" : "one-on-one-feedback-tab-btn"} disabled={feedBackType == '1' && item?.value == 'P'} onClick={() => setSelectedTabValue(item?.id)}>{item?.value}</Button>
                                    ))}
                                    <Tooltip className="feedback-tooltip" overlayInnerStyle={{ width: '500px' }} title={ToolTipTitle}>
                                      <img style={{ position: 'relative', top: '-22px', right: '18px', width: '15px', zIndex: '1500',cursor:'pointer' }} src={info_button} />
                                    </Tooltip>
                                  </Row>
                                  {WithNoOption}
                                </div>
                              )
                            }

                          </div>
                        )
                      }

                      {/* </Col> */}
                    </Row>


                    <div align="center">

                      {selectedTabValue === questionList?.length - 1 && <Button className="one-on-one-save-btn-modal" htmlType="submit" disabled={!fillInOption ? disabledButon(selectedTabValue, tabArray) : false}
                        onClick={(e) => handleFeedbackPreview()} style={{ marginTop: '30px', padding: '8px 20px' }}>Preview</Button>}
                      {selectedTabValue !== questionList?.length - 1 &&
                        <Row justify="center" align="center" style={{ gap: "10px" }}>
                          <Button className="one-on-one-cancel-btn-modal" type="primary" style={{ marginTop: '30px' }} onClick={() => { onCancel(); setSelectedTabValue(); setAnswers(); }}>Cancel</Button>
                          <Button className="one-on-one-save-btn-modal" disabled={disabledButon(selectedTabValue, tabArray)} style={{ marginTop: '30px' }} onClick={() => setSelectedTabValue((feedBackType == '1' && selectedTabValue == 2) ? selectedTabValue + 2 : selectedTabValue + 1)}>Next</Button>
                        </Row>}


                    </div>
                  </Form>
                </div>
              )
            }

          </Spin>
        </Row>
      </>
    </>
  );
};

export default OneOnOneFeedbackUpdateView;



const DropdownButton = styled(Button)`
width:230px;
display:flex;
justify-content:space-between;
align-items:center;
gap:5px
`

