import gql from 'graphql-tag';

export const MANAGER_EMPLOYEE_COUNT = gql`
  query me {
    me {
     id
     employee{
        id
        teamManagerEmployee{
            totalCount
        }
        orgCeoEmployee{
            totalCount
        }
        orgPocEmployee{
            totalCount
        }
        verticalHeadEmployee{
            totalCount
        }
     }
    }
  }
`;