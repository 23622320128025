// @ts-nocheck
import React from "react";
import { Row, Col, Button, Slider, Spin, Form, Space, Radio, message, Modal, Badge, Tooltip, Select } from "antd";
import { MetaTags, getIntFromString, scrolltoview } from "../../look";
import ReactMarkdown from '../../look/components/ReactMarkdown';
import { FetchValidRuleStates } from '../../../readinessRuleConfig'
import { READINESS_ADD_OR_EDIT_TOOL_ID, SURVEY_READINESS_LEVEL } from '../../../config'
import new_back_arrow from '../../assets/new_back_arrow.svg';
import { readiness_zone_route } from '../routes/index'
import SideBulbToolContainer from "modules/look-v2/Container/SideBulbToolContainer";
import "../css/readiness.css"
import SkillField from "modules/look/components/form-components/SkillField";
import remove_readiness from '../../assets/readiness-remove.svg'
import readiness_radio_inactive from '../../assets/readiness-radio-inactive.svg'
import readiness_radio_active from '../../assets/readiness-radio-active.svg'
import WarningModal from "./warningModal";
const AddReadinessView = props => {
    const { navigateRoute, allOrganizationEmployees, SurveyById, okrReadinessDetails, handleSearch, permission, me, readinessMutationData, page_loading, match } = props
    const [loading, setloading] = React.useState(false);
    const [Recipient, setRecipient] = React.useState();
    const [recipientName, setrecipientName] = React.useState();
    const [form] = Form.useForm();
    const [questionList, setquestionList] = React.useState([]);
    const [selectedSkillbutton, setselectedSkillbutton] = React.useState([]);
    const [marginHandler, setMarginHandler] = React.useState(false)
    const [sliderError, setSliderError] = React.useState({})
    const [sliderVals, setSliderVals] = React.useState({})
    const trackStyle = { backgroundColor: '#B0CB1F' }
    const handleStyle = { backgroundColor: '#B0CB1F' }
    const [toolReturn, setToolReturn] = React.useState()
    const [withSkillList, setSkillSet] = React.useState();
    const [readiness_for_others, setReadinessForOthers] = React.useState("no")
    const [show_warning, setShowWarning] = React.useState(false)

    const back = () => {
        let params = new URLSearchParams(window.location.search)
        let tab = params.get("tab")
        if (tab) {
            navigateRoute(readiness_zone_route?.readinessHome?.replace(":type", tab))
        } else {
            navigateRoute("-1");
        }
    };

    React.useEffect(() => {
        if (okrReadinessDetails?.owner && okrReadinessDetails?.owner?.user?.employee?.id) {
            setRecipient(getIntFromString(okrReadinessDetails?.owner?.user?.employee?.id))
            setrecipientName(okrReadinessDetails?.owner?.user?.firstName)
        }
    }, [okrReadinessDetails])

    React.useEffect(() => {
        if (!selectedSkillbutton?.length) {
            addToSelectedSkill(true)
        }
    }, [selectedSkillbutton])

    React.useEffect(() => {
        if (SurveyById) {
            let list = SurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges?.map(({ node }) => node)
            setquestionList(list)

        }
    }, [SurveyById])

    function handleMenuClick(value) {
        if (getIntFromString(value?.key) != Recipient) {
            setRecipient(getIntFromString(value?.key))
            let user = allOrganizationEmployees?.find(item => item?.id === value?.key)
            if (user) {
                setrecipientName(`${user?.firstName} ${user?.lastName || ''}`)
            } else {
                setrecipientName(`employee`)
            }


        }
    }

    const checkIfSliderIsDisabled = (qId, skillid) => {
        if (qId === undefined) {
            return false
        }
        if (skillid !== undefined) {
            if (qId === 688) {
                return false
            } else {

                if (qId === 689) {
                    if (sliderError[skillid][688] !== undefined) {
                        return true
                    }
                    if (sliderVals[skillid][688] === undefined) {
                        return true
                    }
                    return false
                }
                else if (qId === 690) {
                    let b = undefined
                    if (sliderError[skillid][688] !== undefined) {
                        return true
                    }
                    if (sliderVals[skillid][688] === undefined) {
                        return true
                    }
                    if (sliderError[skillid][689] !== undefined) {
                        return true
                    }
                    if (sliderVals[skillid][689] === undefined) {
                        return true
                    }
                    return false
                }
                else if (qId === 691) {
                    if (sliderError[skillid][688] !== undefined) {
                        return true
                    }
                    if (sliderVals[skillid][688] === undefined) {
                        return true
                    }
                    if (sliderError[skillid][689] !== undefined) {
                        return true
                    }
                    if (sliderError[skillid][690] !== undefined) {
                        return true
                    }
                    if (sliderVals[skillid][689] === undefined) {
                        return true
                    }
                    if (sliderVals[skillid][690] === undefined) {
                        return true
                    }
                    return false
                }
            }
        }
        else {
            return false
        }
    }

    // React.useState(() => {

    // }, [sliderVals])


    const rangeValidation = (value, qId, skillid, prevVal) => {
        if (skillid === undefined) {
            return
        }
        if (qId === 688) {
            let a = FetchValidRuleStates(undefined, undefined, undefined, undefined)
            if (!checkIfValInRng(value, a)) {
                let errString = "Range should be between "
                let errRange = ""
                a.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = sliderError[skillid]
                updatedValue[688] = errString
                let parent = { [skillid]: updatedValue }
                setSliderError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
            } else {
                let updatedValue = sliderError[skillid]
                updatedValue[688] = undefined
                let parent = { [skillid]: updatedValue }
                setSliderError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
                let updatedVals = sliderVals[skillid]
                updatedVals[688] = value
                setSliderVals((prevState) => ({
                    ...prevState,
                    ...updatedVals,
                }));

                // recursively call children
                rangeValidation(sliderVals[skillid][689], 689, skillid, value)
            }
        }
        if (qId === 689) {
            let l = prevVal
            if (l === undefined) {
                l = sliderVals[skillid][688]
            }
            let b = FetchValidRuleStates(l, undefined, undefined, undefined)
            if (!checkIfValInRng(value, b)) {
                let errString = "Range should be between "
                let errRange = ""
                b.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = sliderError[skillid]
                updatedValue[689] = errString
                let parent = { [skillid]: updatedValue }
                // let updatedValue = { 689: errString };
                setSliderError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
            } else {
                let updatedValue = sliderError[skillid]
                updatedValue[689] = undefined
                let parent = { [skillid]: updatedValue }
                setSliderError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
                let updatedVals = sliderVals[skillid]
                updatedVals[689] = value
                setSliderVals((prevState) => ({
                    ...prevState,
                    ...updatedVals,
                }));

                rangeValidation(sliderVals[skillid][690], 690, skillid, value)
            }
        }
        if (qId === 690) {
            let l = prevVal
            if (l === undefined) {
                l = sliderVals[skillid][689]
            }
            let c = FetchValidRuleStates(sliderVals[688], l, undefined, undefined)
            if (!checkIfValInRng(value, c)) {
                let errString = "Range should be between "
                let errRange = ""
                c.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = sliderError[skillid]
                updatedValue[690] = errString
                let parent = { [skillid]: updatedValue }
                // let updatedValue = { 690: errString };
                setSliderError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
            } else {
                let updatedValue = sliderError[skillid]
                updatedValue[690] = undefined
                let parent = { [skillid]: updatedValue }
                setSliderError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
                let updatedVals = sliderVals[skillid]
                updatedVals[690] = value
                setSliderVals((prevState) => ({
                    ...prevState,
                    ...updatedVals,
                }));
                // recursively call children
                rangeValidation(sliderVals[skillid][691], 691, skillid, value)
            }
        }
        if (qId === 691) {
            let l = prevVal
            if (l === undefined) {
                l = sliderVals[skillid][690]
            }
            let d = FetchValidRuleStates(sliderVals[688], sliderVals[689], l, undefined)
            if (!checkIfValInRng(value, d)) {
                let errString = "Range should be between "
                let errRange = ""
                d.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = sliderError[skillid]
                updatedValue[691] = errString
                let parent = { [skillid]: updatedValue }
                // let updatedValue = { 691: errString };
                setSliderError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
            } else {
                let updatedValue = sliderError[skillid]
                updatedValue[691] = undefined
                let parent = { [skillid]: updatedValue }
                setSliderError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
                let updatedVals = sliderVals[skillid]
                updatedVals[691] = value
                setSliderVals((prevState) => ({
                    ...prevState,
                    ...updatedVals,
                }));
            }
        }

    }

    const checkIfValInRng = (val, rng) => {
        let ret = false
        rng.forEach(r => {
            if (Array.isArray(r)) {
                if (val >= Number(r[0]) && val <= Number(r[1])) {
                    ret = true
                }
            } else {
                if (val >= Number(r) && val <= Number(r)) {
                    ret = true
                }
            }
        })
        return ret
    }
    const handleValidation = () => {
        if (selectedSkillbutton.length) {
            let unAnsweredOne = selectedSkillbutton.filter(item => !item.id)
            if (unAnsweredOne && unAnsweredOne?.length) {
                message.error('please select the skills')
            }
            else {
                handleSubmit()
            }

        }
        else {
            message.error('please fill in the required field')
        }
    }
    async function handleSubmit(value) {
        let valid = true

        for (let [key, value] of Object.entries(sliderError)) {
            for (let [k, v] of Object.entries(value)) {
                if (v !== undefined) {
                    valid = false
                }
            }
        }
        if (!valid) {
            message.error('please select the correct ranges')
        }
        else {
            setloading(true)
            let readinessData = {
                readinessToId: Recipient,
            }
            let selectedAnswes = []
            selectedSkillbutton.map(skills => {
                let readinessLevelDataBuild = {}
                for (let [k, v] of Object.entries(sliderVals[getIntFromString(skills.id)])) {
                    if (v !== undefined) {
                        readinessLevelDataBuild[`q_${k}`] = v;
                    }
                }

                let data = {
                    answers: readinessLevelDataBuild,
                    surveyId: SURVEY_READINESS_LEVEL,
                    skillId: getIntFromString(skills.id),
                }
                selectedAnswes.push({ readinessData: readinessData, data: data })
            })

            readinessMutationData(selectedAnswes)

        }
    }

    const addToSelectedSkill = (initial = false) => {
        let x = [].concat(selectedSkillbutton)
        x.push({ id: undefined, label: undefined })
        setselectedSkillbutton(x)
    }

    const featchSliderValue = (value, qId, skillid) => {
        if (skillid === undefined) {
            return
        }
        if (qId === 688) {
            let updatedVals = sliderVals[skillid]
            updatedVals[688] = value
            setSliderVals((prevState) => ({
                ...prevState,
                ...updatedVals,
            }));
        }
        if (qId === 689) {
            let updatedVals = sliderVals[skillid]
            updatedVals[689] = value
            setSliderVals((prevState) => ({
                ...prevState,
                ...updatedVals,
            }));
        }
        if (qId === 690) {
            let updatedVals = sliderVals[skillid]
            updatedVals[690] = value
            setSliderVals((prevState) => ({
                ...prevState,
                ...updatedVals,
            }));
        }
        if (qId === 691) {
            let updatedVals = sliderVals[skillid]
            updatedVals[691] = value
            setSliderVals((prevState) => ({
                ...prevState,
                ...updatedVals,
            }));
        }
    }

    const changeselectedSkill = (skill, index) => {
        setMarginHandler(true)
        let skillset = {}
        let skillErrorSet = {}
        skillset[getIntFromString(skill?.value)] = { 688: 0, 689: 0, 690: 0, 691: 0 }
        skillErrorSet[getIntFromString(skill?.value)] = { 688: undefined, 689: undefined, 690: undefined, 691: undefined }
        setSliderVals((prevState) => ({
            ...prevState,
            ...skillset,
        }));
        setSliderError((prevState) => ({
            ...prevState,
            ...skillErrorSet,
        }));
        let x = [].concat(selectedSkillbutton)
        x[index].id = skill?.value
        x[index].label = skill?.label
        setselectedSkillbutton(x)
    }

    const handleDeleteNewCard = (skillId, index) => {
        let updatedSkillVal = { [getIntFromString(skillId)]: { 688: 0, 689: 0, 690: 0, 691: 0 } }
        setSliderVals((prevState) => ({
            ...prevState,
            ...updatedSkillVal,
        }));
        let updatedSkillError = { [getIntFromString(skillId)]: { 688: undefined, 689: undefined, 690: undefined, 691: undefined } }
        setSliderError((prevState) => ({
            ...prevState,
            ...updatedSkillError,
        }));

        // setselectedSkillbutton(selectedSkillbutton.filter(item => item?.id !== skillId));
        let x = [].concat(selectedSkillbutton)?.filter(i => i?.id != skillId)
        setselectedSkillbutton(x)
    }

    return (
        <>
            <>
                <MetaTags
                    title="Readiness Zone"
                    description="This is Create Readiness page."
                />
                <Spin spinning={loading || page_loading}>
                    {/* Page Title and Back Arrow */}
                    <div className="readiness-root">
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "1em" }}>
                            <img src={new_back_arrow} style={{ width: "20px", height: "20px", cursor: "pointer", marginLeft: '7px' }} onClick={() => { selectedSkillbutton.length ? setShowWarning(true) : back() }} alt="" />
                            <h4 className="readiness-page-title">Assess Readiness Level</h4>
                            <div style={{ position: 'relative' }}>
                                <SideBulbToolContainer
                                    {...props}
                                    widthOfContainer={24}
                                    containerBorder={""}
                                    spacing={'0px'}
                                    hideTopDivider={true}
                                    keepDesign={true}
                                    top={"104px"}
                                    toolReturn={e => setToolReturn(e)}
                                    tool_id={READINESS_ADD_OR_EDIT_TOOL_ID}
                                />
                            </div>
                        </div>
                        {/* {
                        SurveyById && ( */}
                        <div className="readiness-main-container" style={{ background: "#fff", boxShadow: " 0px 10px 15px 0px rgba(170, 186, 198, 0.2)", width: '100%', padding: '1em 2em', borderRadius: ".5em", overflow: "auto", marginTop: "1em", paddingBottom: "6em" }}>
                            <Form
                                form={form}
                                name={"value"}
                                onFinish={() => handleValidation()}>
                                <div style={{ marginTop: '1em', display: "flex", flexDirection: "column", gap: "1em", width: "100%" }} >
                                    {okrReadinessDetails?.owner?.id ?
                                        <Col lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ paddingLeft: '10px' }}>
                                        </Col> :
                                        permission?.employee_permission && (me?.employee?.orgCeoEmployee?.totalCount > 0 || me?.employee?.orgPocEmployee?.totalCount > 0 || me?.employee?.teamManagerEmployee?.totalCount > 0 || me?.employee?.orgPocEmployee?.totalCount > 0 || me?.employee?.verticalHeadEmployee?.totalCount > 0) ?
                                            <div style={{ display: "flex", flexDirection: "column", gap: "1em" }} >
                                                <p className="readiness-form-lable"><span style={{ color: "#F95F53" }}>*</span>Is this Readiness Level for a team member?</p>
                                                <div style={{ display: "flex", flexDirection: "column", gap: ".75em", marginLeft: ".5em" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: ".5em", cursor: "pointer", width: "fit-content" }} onClick={() => setReadinessForOthers("yes")}>
                                                        {readiness_for_others == "yes" ? <img style={{ width: "20px", height: "20px" }} src={readiness_radio_active} alt="" /> :
                                                            <img style={{ width: "20px", height: "20px" }} src={readiness_radio_inactive} alt="" />}
                                                        <span className="readiness-form-lable">Yes</span>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: ".5em", cursor: "pointer", width: "fit-content" }} onClick={() => setReadinessForOthers("no")}>
                                                        {readiness_for_others == "no" ? <img style={{ width: "20px", height: "20px" }} src={readiness_radio_active} alt="" /> :
                                                            <img style={{ width: "20px", height: "20px" }} src={readiness_radio_inactive} alt="" />}
                                                        <span className="readiness-form-lable"> No</span>
                                                    </div>
                                                </div>
                                                {readiness_for_others == "yes" && (
                                                    <div style={{ padding: '1.5em', height: "100%", background: "#F6FBFF", borderRadius: "1em", maxWidth: "70%" }} >
                                                        <Select
                                                            className="generic-survey-select-filed"
                                                            showSearch={true}
                                                            onSearch={(e) => { handleSearch(e) }}
                                                            style={{ width: "100%" }}
                                                            placeholder="Select Recipient"
                                                            onChange={(value, options) => handleMenuClick(options)}
                                                            id="readiness_recipient" onClick={() => { scrolltoview('readiness_recipient') }}
                                                        >

                                                            {
                                                                allOrganizationEmployees?.map(item => {
                                                                    return <Select.Option key={item?.employee?.id} value={`${item?.firstName} ${item?.lastName}`} >{item?.firstName} {item?.lastName}</Select.Option>
                                                                }
                                                                )
                                                            }

                                                        </Select>
                                                    </div>
                                                )}
                                            </div> : ''
                                    }
                                    <div style={{ marginTop: "2em", width: "100%" }}>
                                        <p className="readiness-form-lable" style={{ paddingLeft: ".5em" }}>Readiness assessment for {!Recipient ? 'you' : recipientName}</p>
                                        {selectedSkillbutton?.map((item, index) =>
                                        (
                                            <div style={{ padding: '2em', height: "100%", background: "#F6FBFF", borderRadius: "1em", maxWidth: "70%", marginTop: "1.25em" }} >
                                                <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "1em" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <SkillField
                                                            id="readiness_skill"
                                                            name={"skill"}
                                                            owner={"readiness"}
                                                            form={form}
                                                            label={""}
                                                            style={{ width: "100%" }}
                                                            no_label={true}
                                                            onChange={(e) => changeselectedSkill(e, index)}
                                                            closeAfterSelection={true}
                                                            hide_skill_list={selectedSkillbutton?.map(i => i?.id)}
                                                            setSkillSet={setSkillSet}
                                                            skill_detail={item}
                                                        />
                                                    </div>
                                                 
                                                    <div>
                                                        <img style={{ width: "40px", cursor: "pointer" }} src={remove_readiness} alt="" onClick={() => handleDeleteNewCard(item?.id, index)} />
                                                    </div>
                                                </div>
                                                {item?.id ?
                                                    questionList?.map(question =>
                                                        <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: "1em" }}>
                                                            {question?.required && (
                                                                <h3 className="readiness-form-lable"><ReactMarkdown >{question?.questionText}</ReactMarkdown></h3>
                                                            )}
                                                            {!question?.required && (
                                                                <h3 className="readiness-form-lable"><ReactMarkdown >{question?.questionText}</ReactMarkdown></h3>
                                                            )}
                                                            <div className="readiness-slider">
                                                                <Slider
                                                                    min={0}
                                                                    max={100}
                                                                    disabled={checkIfSliderIsDisabled(getIntFromString(question?.id))}
                                                                    step={1}
                                                                    onChange={e => featchSliderValue(e, getIntFromString(question?.id), getIntFromString(item?.id))}
                                                                    onAfterChange={e => rangeValidation(e, getIntFromString(question?.id), getIntFromString(item?.id))}
                                                                    trackStyle={trackStyle}
                                                                    handleStyle={handleStyle}
                                                                    value={sliderVals[getIntFromString(item?.id)][getIntFromString(question?.id)]}
                                                                    disabled={checkIfSliderIsDisabled(getIntFromString(question?.id), getIntFromString(item?.id))}
                                                                />
                                                            </div>
                                                            {question?.id && sliderError[getIntFromString(item?.id)] && sliderError[getIntFromString(item?.id)][getIntFromString(question?.id)] && (
                                                                < div style={{ color: "red" }}>
                                                                    {sliderError[getIntFromString(item?.id)][getIntFromString(question?.id)]}
                                                                </div>
                                                            )
                                                            }
                                                        </div>
                                                    )
                                                    : ''}

                                            </div>
                                        )
                                        )}


                                        <div align="Start" style={{ marginTop: "2em" }}>
                                            <Button className="readiness-add-btn" onClick={(e) => { addToSelectedSkill() }}>
                                                + Add more
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: "center", width: "100%", background: "#fff", paddingBottom: "1em" }} >
                                    <Button className="readiness-cancel-btn" onClick={() => { selectedSkillbutton.length ? setShowWarning(true) : back() }} disabled={!selectedSkillbutton?.length} style={{ margin: '1em 1em 0em 1em' }}>Cancel</Button>
                                    <Button className="readiness-save-btn" type="primary" htmlType="submit" disabled={!selectedSkillbutton?.length} style={{ margin: '1em 1em 0em 1em' }}>Submit</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Spin>
                <Modal
                    visible={show_warning}
                    footer={false}
                    closable={false}
                    centered={true}
                    destroyOnClose={true}>
                    <WarningModal {...props} title={"Are you sure you want to go back?"} onClose={() => { setShowWarning(false) }} onGoBack={() => back()} />
                </Modal>
            </>
        </>
    );
};
export default AddReadinessView;