import React from 'react';
import '../css/idpHome.css'
import { Button, Tooltip, Modal, Spin } from 'antd'
import { idp_home_icons } from '../idpIcons';
import idp_route from '../route';
import IDPSearchView from './IDPSearchView';
import { displayUtcToUserTimezone, getIntFromString } from 'modules/look';
import Idppagination from './idpPagination';
import { idp_status } from '../idp.config';
import moment from 'moment';
import RoleDefinitionConfirmation from 'modules/look-v2/Components/ConfirmationDialog';

const IdpHomeView = (props) => {
    const { idp_lists, idp_list_loading, getUserIdpList, idp_page_info, manager_permission, deleteSelectedIdp, setDeleteIdp, delete_idp,navigateRoute } = props
    const filter_data = [
        {
            key: "goal_Icontains",
            ui_flow: "input_box",
            label: "Goal",
            default: true
        },
        {
            key: "status",
            ui_flow: "dropdown",
            label: "Status",
            choice: [
                {
                    key: idp_status?.ToDo?.for_mutation,
                    label: "To Do"
                },
                {
                    key: idp_status?.Draft?.for_mutation,
                    label: "Draft"
                },
                {
                    key: idp_status?.InProgress?.for_mutation,
                    label: "In Progress"
                },
                {
                    key: idp_status?.Approved?.for_mutation,
                    label: "Approved"
                },
                {
                    key: idp_status?.Pedning_Approval?.for_mutation,
                    label: "Pending"
                },
                {
                    key: idp_status?.Completed?.for_mutation,
                    label: "Completed"
                },
                {
                    key: idp_status?.Revise?.for_mutation,
                    label: "Revise"
                }

            ]
        },
    ]

    const getStatus = (status) => {
        if (status === idp_status?.Draft?.for_query) {
            return <h4 className='idp-draft-btn idp-text-transform'>{status}</h4>
        } else if (status === idp_status?.Pedning_Approval?.for_query) {
            return <h4 className='idp-pending-btn  idp-text-transform'>Pending</h4>
        } else if (status === idp_status?.Completed?.for_query) {
            return <h4 className='idp-complete-btn  idp-text-transform'>{status}</h4>
        } else if (status === idp_status?.InProgress?.for_query) {
            return <h4 className='idp-inprogress-btn  idp-text-transform'>{idp_status?.InProgress?.label}</h4>
        }
        else if (status === idp_status?.Approved?.for_query) {
            return <h4 className='idp-approved-btn  idp-text-transform'>{status}</h4>
        } else if (status === idp_status?.Revise?.for_query) {
            return <h4 className='idp-revise-btn  idp-text-transform'>{status}</h4>
        }
        else if (status === idp_status?.ToDo?.for_query) {
            return <h4 className='idp-inprogress-btn  idp-text-transform'>{idp_status?.ToDo?.label}</h4>
        }

    }

    return (
        <div className="idp-home-root">
            <div className="idp-home-container">
                <div className="idp-space-between" style={{flexWrap:"wrap"}}>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", gap: '1em' }}>
                        <Button className="idp-green-btn" onClick={() => navigateRoute(idp_route?.create)}>New IDP</Button>
                        {manager_permission && <div className="idp-team-button" style={{whiteSpace:"nowrap"}} onClick={() => navigateRoute(idp_route?.team_idp)}>Team IDP</div>}
                    </div>
                    <IDPSearchView filterData={filter_data} onfilterChange={e => getUserIdpList(e)}{...props} />
                </div>
                <div className="idp-table-container">
                    <Spin spinning={idp_list_loading}>
                       <div style={{overflow:"auto"}}>
                       <table className='rd-table idp-table-overflow'>
                            <thead>
                                <tr className='rd-row-header'>
                                    <th className='rd-table-row-padding-left rd-left-radious' >
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>My Goals</h4>
                                    </th>
                                    <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Manager</h4>
                                    </th>
                                    <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Completion Status</h4>
                                    </th>
                                    <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Milestone</h4>
                                    </th>
                                    <th className='rd-table-row-padding-rigth rd-content-center rd-table-header-label rd-right-radious' >
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Action{idp_lists?.filter(i=>i?.status==idp_status?.Pedning_Approval.for_query||i?.status==idp_status?.Draft.for_query)?.length?'s':""}</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {idp_lists?.length > 0 && idp_lists?.map(item => (
                                    <tr className='idp-row-body'>
                                        <td className='rd-left-radious' style={{ width: "30%", borderRight: "none" }}>
                                            <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", gap: "1em" }}>
                                                {item?.status === idp_status?.Draft?.for_query ? <img src={idp_home_icons?.pending_identification} alt="pending_identification" style={{ height: "3.25em" }} /> :
                                                    item?.status === idp_status?.Revise?.for_query ? <img src={idp_home_icons?.revise_identification} alt="pending_identification" style={{ height: "3.25em" }} /> :
                                                        <img src={idp_home_icons?.apporved_identification} alt="pending_identification" style={{ height: "3.25em" }} />}
                                                {item?.goalType === "LONG" ? <Tooltip title="Long-term goal"><img src={idp_home_icons?.long_term_goal} alt="long_term_goal" style={{ width: "2em", height: "2em", cursor: "pointer" }} /></Tooltip> :
                                                    <Tooltip title="Short-term goal"><img src={idp_home_icons?.short_term_goal} alt="short_term_goal" style={{ width: "2em", height: "2em", cursor: "pointer" }} /></Tooltip>}
                                                <h4 className='rd-table-body-label' style={{ cursor: "pointer",wordBreak:"break-all" }} onClick={() => navigateRoute(idp_route?.detail.replace(":idp_id", getIntFromString(item?.id)))}>{item?.goal}</h4>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                            <h4 className='rd-table-body-label' >{item?.approver?.firstName || item?.sendTo}</h4>
                                        </td>
                                        <td style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                                {getStatus(item?.status)}
                                            </div>
                                        </td>
                                        <td style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                            <h4 className='rd-table-body-label' style={{ color: moment(item?.duedate).isBefore(moment()) && item?.status !== idp_status?.Completed?.for_query ? "#FF3535" : "#000" }}>{item?.duedate ? displayUtcToUserTimezone(item?.duedate, 'DD MMM YYYY') : '-'}</h4>
                                        </td>
                                        <td className='rd-table-row-padding-rigth rd-content-center rd-right-radious' style={{  borderLeft: "none" }}>
                                            <div className='rd-table-actions'>
                                                {
                                                    (item?.status === idp_status?.Draft?.for_query || item?.status === idp_status?.Pedning_Approval?.for_query || item?.status === idp_status?.Revise?.for_query) ? (
                                                        <img className='rd-delete-icon' onClick={() => navigateRoute(idp_route?.edit.replace(":idp_id", getIntFromString(item?.id)))} src={idp_home_icons?.edit_icon} alt="edit_icon" />
                                                    ) : <img className='rd-delete-icon' src={idp_home_icons?.edit_icon} alt="edit_icon" style={{ visibility: 'hidden' }} />
                                                }
                                                <img className='rd-delete-icon' src={idp_home_icons?.delete_icon} alt="delete_icon" onClick={() => setDeleteIdp(item?.id)} />
                                            </div>
                                        </td>
                                    </tr>))}
                            </tbody>
                        </table>
                       </div>
                    </Spin>
                    {
                        (idp_lists?.length === 0 && !idp_list_loading) &&
                        <div className='rd-nodata'>
                            <h4 className='rd-nodata-label'>No data found</h4>
                        </div>
                    }
                    {
                        (idp_page_info?.hasNextPage && idp_page_info?.cursor) && (
                            <Idppagination on_pagination={() => { getUserIdpList({ cursor: idp_page_info?.cursor }) }} />
                        )
                    }
                </div>

            </div>
            <Modal
                visible={delete_idp}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                style={{ maxWidth: "35em" }}
                className='idp-modal'
                width={"90%"}
            >
                <RoleDefinitionConfirmation
                    message={`Do you want to delete this IDP?`}
                    onCancel={() => { setDeleteIdp() }}
                    onConfirm={() => { deleteSelectedIdp(delete_idp) }}
                />
            </Modal>
        </div>
    )
}

export default IdpHomeView