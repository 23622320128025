// @ts-nocheck
import React from 'react';

import { compose } from '../../../core';

import { withApollo } from '@apollo/client/react/hoc'
import { withTokenDelete,withCreateTokenBySurvey,with360SurveyByid } from '../../../survey/containers/SurveyOperations'
import Survey360ReportView from '../component/Survey360reportView'
import { SURVEY_360_REPORTS } from '../../../survey/graphql/Survey360Report.gql'
import { getIntFromString, getResponsesBase64StringFromInt } from 'modules/look/components/functions';
import { MANAGER_EMPLOYEE_COUNT } from '../../../survey/graphql/EmployeeCount.qgl';

const Survey360ReportContainer = props => {
    const { client,me,history } = props
    const [SurveyReport,setSurveyReport]=React.useState()
    const [permission,setpermission]= React.useState(false)
    
    const getSurveyReport = async (filterData) => {
        const { data } = await client.query({
            query: SURVEY_360_REPORTS,
            variables: filterData,
            fetchPolicy: 'network-only'
        });

        if(data?.responseByResponseId){
            if(typeof(data.responseByResponseId?.report360) === 'string'){
              data.responseByResponseId.report360 = JSON.parse(data?.responseByResponseId?.report360)
            }
            setSurveyReport(data?.responseByResponseId)
    
        }
        
    }

    const getEmployeeData = async()=>{
        const { data } = await client.query({
              query: MANAGER_EMPLOYEE_COUNT,
          });
     
          if(data?.me){
            let Detail =data?.me?.employee
            if(Detail?.teamManagerEmployee?.totalCount || Detail?.orgCeoEmployee?.totalCount || Detail?.orgPocEmployee?.totalCount || Detail?.verticalHeadEmployee?.totalCount){
              setpermission(true)
            }
            else{
              setpermission(false)
            }
          }
      }

  
    React.useEffect(()=>{
        if(me){
            if(props?.match?.params?.responseid){
                let id = getResponsesBase64StringFromInt( props?.match?.params?.responseid )
                getSurveyReport({ id: id})
                getEmployeeData()
            }
            
          }
          else if (me===null){
            history.push('/user/login')
          }
    },[me])

    return (
        <Survey360ReportView {...props} SurveyReport={SurveyReport} permission={permission}></Survey360ReportView>
    )
 
};

export default compose(withApollo)(Survey360ReportContainer);
