// @ts-nocheck
import React from 'react';
import { Row, Col, Button, Dropdown, Menu, Table, Spin, Card, Switch, Tooltip,Input,message } from 'antd';
import { LeftOutlined,DeleteOutlined,CopyOutlined ,PlusOutlined,DownOutlined,CloseOutlined} from '@ant-design/icons';
import { compose } from '../../../core';
import styled from 'styled-components';
import ReactMarkdown from '../../../look/components/ReactMarkdown';
import { displayBoolean } from '../../../look';
import { getIntFromString } from 'modules/look/components/functions';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
} from 'chart.js';
import survey_module_route from 'modules/survey-module/routes';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend
);
const Survey360ReportView = props => {
    const { me,navigateRoute,SurveyReport,permission } = props
    const [loading, setloading] = React.useState(true);
    const [ReportList, setReportList] = React.useState([]);

    React.useEffect(()=>{
        if(SurveyReport){
            if(SurveyReport?.report360?.self?.length){
                let report = SurveyReport?.report360
                let selfList = SurveyReport?.report360?.self
                selfList.forEach(element => {
                    element['overAll']=report?.overall.filter(item=>element?.answer__question__group__id===item?.answer__question__group__id)
                    element['respondents']=report?.respondents.filter(item=>element?.answer__question__group__id===item?.answer__question__group__id)
                });
                setReportList(selfList)
            }
            setloading(false)
        }
    },[SurveyReport])

    const back = () => {
        navigateRoute("-1");
    };


    const ToDecimal =(value)=>{
        if(value%1){
            return value.toFixed(1)
        }
        else{
            return value
        }
    }

    const navigateToTeamReport=()=>{
        if(permission){
            navigateRoute( survey_module_route.survey_360_manager_report?.replace(":surveyid",props?.match?.params?.surveyid))
        }
    }

    const chartData = (chart)=>{
       
        let Values =[]
        let labels=[]
        let dataSource = []
        const columns = [
            {
                title: <Tooltip title="Type of Respondent">TYPE</Tooltip>,
                key: 'TYPE',
                render: (text, record) => record && 
                <Tooltip title={record?.type}>
                    <p style={{maxWidth:'100px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',marginTop:'5px'}}>{record?.type}</p>
                </Tooltip>
            },
            {
              title: <Tooltip title="Average">AVG</Tooltip>,
              key: 'AVG',
              render: (text, record) => record && 
              <p style={{marginTop:'5px'}}>{record?.AVG}</p>
            },
            {
              title: <Tooltip title="Standard Deviation">SD</Tooltip>,
              key: 'SD',
              render: (text, record) => record &&
               <p style={{marginTop:'5px'}}>{record?.SD}</p>
            },
            
            {
                title:<Tooltip title="High">HI</Tooltip>,
                key: 'HI',
                render: (text, record) => record && 
                <p style={{marginTop:'5px'}}>{record?.HI}</p>
              },
              {
                title: <Tooltip title="Low">LO</Tooltip>,
                key: 'LO',
                render: (text, record) => record && 
                <p style={{marginTop:'5px'}}>{record?.LO}</p>
              }
        ]
        if(chart?.overAll?.length){
            labels.push('OverAll')
            const Avg = chart?.overAll.reduce((a, b) => a + b?.Avg_response, 0) 
            Values.push(Avg)
            let tableOverall={
                type:'OverAll',
                AVG:ToDecimal(Avg),
                SD:ToDecimal(chart?.overAll.reduce((a, b) => a + b?.StdDev_response, 0)),
                HI:ToDecimal(chart?.overAll.reduce((a, b) => a + b?.Max_response, 0)) ,
                LO:ToDecimal(chart?.overAll.reduce((a, b) => a + b?.Min_response, 0)) ,
            }
            dataSource.push(tableOverall)
        }
      
        if(chart?.respondents?.length){
    
            chart.respondents.forEach(element => {
                const Avg = element?.Avg_response
                labels.push(element?.respondentType)
                Values.push(Avg)
                let tablerespondents={
                    type:element?.respondentType,
                    AVG:ToDecimal(Avg),
                    SD:ToDecimal(element.StdDev_response),
                    HI:ToDecimal(element?.Max_response), 
                    LO:ToDecimal(element.Min_response),
                }
                dataSource.push(tablerespondents)
            });
        }
     
        
        
        labels.push('Self')
        Values.push(chart?.Avg_response)
        let tableSelf={
            type:'Self',
            AVG:ToDecimal(chart?.Avg_response),
            SD:ToDecimal(chart?.StdDev_response),
            HI:ToDecimal(chart?.Max_response) ,
            LO:ToDecimal(chart?.Min_response) ,
        }
        dataSource.push(tableSelf)
        const chartData = {
            labels:labels,
            datasets: [{
                data: Values,
                backgroundColor: '#B0CB1F',
            }]
        };
        let maxValue = ( (parseInt(Math.max(...Values)/10)+1 )* 10 )

        const options = {
            responsive: true,
            scales: {
                x: {
                    min:0,
                    max:maxValue>100?maxValue:100
                }
            },
            indexAxis: 'y',
            elements: {
                bar: {
                  borderWidth: 1,
                  borderHeight:1
                },
              },
            plugins: {
                legend: {
                    position: 'bottom',
                    display:false
                },
                title: {
                    display: false,
                }
            },
        };
        return (
            <>
             <Row >
                <Col  lg={{span:14}} sm={{span:24}} xs={{span:24}} style={{paddingTop:'34px'}}>
                    <Bar data={chartData} options={options} height={dataSource?.length*30} />
                </Col>
                <Col  lg={{span:10}} sm={{span:24}} xs={{span:24}} >
                    <MyTable
                        size={'middle'}
                        loading={loading }
                        showHeader={true}
                        pagination={false}
                        dataSource={
                        dataSource
                            ? dataSource
                            : []
                        }
                        columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}

                    />
                </Col>
             </Row>
               
            </>
        )
    }

    return (
        <>
         <Spin spinning={loading}>
          <div style={{padding:'0px 20px'}}>
             {
                SurveyReport && (
                   <>
                    <RowDiv >
                    <Col span={18} >
                        <h4 style={{fontSize:'20px'}}>360 Report for {SurveyReport?.survey?.name}</h4>
                    </Col>
                   <Row>
                        {
                            permission && (
                                <Button type='primary' onClick={()=>{navigateToTeamReport()}} >
                                    Team's 360 reports
                                </Button>
                            )
                        }
                        
                        <Button shape="circle" style={{marginLeft:'20px'}} onClick={back}>
                            <LeftOutlined />
                        </Button>
                   </Row>
                    </RowDiv>
                    <div>
                         {ReportList?.length?
                            <div style={{marginTop:'50px'}}>

                                {
                                    ReportList.map(chart=>
                                        <ExpansionCard style={{marginTop:'20px'}}>
                                            <Row gutter={20}>
                                                <Col lg={{span:9}} sm={{span:24}} xs={{span:24}}>
                                                    <ReactMarkdown>{chart?.answer__question__group__label}</ReactMarkdown>
                                                    <ReactMarkdown>{chart?.answer__question__group__help_text}</ReactMarkdown>
                                                </Col>
                                                <Col lg={{span:15}} sm={{span:24}} xs={{span:24}}>
                                                    {chartData(chart)}
                                                </Col>
                                            </Row>
                                        </ExpansionCard>
                                    )
                                }

                            </div>
                            :
                            <h3 style={{textAlign:'center',marginTop:'200px'}}>NO REPORTS FOUND</h3> 
                        }
                    </div>
                   </>
                ) 
             }
          </div>
         </Spin>
        </>
    )
 
};

export default Survey360ReportView

const RowDiv =styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`
const ExpansionCard = styled(Card)`
border:2px solid #CACACA
`

export const MyTable = styled(Table)`
  table {
    th{
        text-align:center !important;
    }
    tbody {
      th,td{
          padding:3px 5px 0px 5px !important;  
          font-size:13px;
          text-align:center;
      }  
      tr {
        td { 
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
