// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { compose } from '../../core';
import ConversationDetailView from '../components/ConversationDetailView';
import { withApollo } from '@apollo/client/react/hoc';
import { Spin } from "antd";
import { ORG_SURVEY_BY_ID_OPTIMIZED } from 'modules/survey/graphql/OrgSurveyOptimized.gql';
import { getConversationTypeBase64StringFromInt, getIntFromString, globalPermissionValidator } from 'modules/look';
import { ACTION_ITEM_SUBSCRIPTION, CONVERSATION_BY_ID } from '../graphql/ConversationByid.gql';
import { withCreateActionItem, withCreateConversation, withUpdateActionItem } from './conversationOperation';
import { coaching_engagement_permission } from 'Permissions/coachingEngagement.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { withAddCustomUserCommitment, withEditCustomUserCommitment, withGetAllCustomUserCommitments,withGetAllCustomUserCommitmentsForCoaching } from 'modules/dashboard/containers/DashboardOperations';
import { GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_COACHING } from 'modules/dashboard/graphql/GetAllCustomUserCommitments.gql';
const ConversationDetail = props => {
  const { client, me, userPermission, permissionLoader, getAllCustomUserCommitments,updateCustomUserCommitment,createCustomUserCommitment } = props
  
  const [loading, setloading] = React.useState(false)
  const [coachingPermitted, setCoachingPermitted] = React.useState()
  const [orgSurveyById, setorgSurveyById] = React.useState()
  const dataRef = React.useRef()
  const [coachingConversationById, setcoachingConversationById] = React.useState()
  const [customUserCommitmentsForCoaching, setCustomUserCommitmentsForCoaching] = useState()

  const cleanup = () => {
    setorgSurveyById()
    // setcoachingConversationById()
  }

  useEffect(()=>{
    if(coachingConversationById){
      getAllCustomUserCommitmentsForCoaching(coachingConversationById?.id)
    }
  },[coachingConversationById])

  const getSubscribedToActionItems = async (conversation) => {
    const { data } = await client.subscribe({
      query: ACTION_ITEM_SUBSCRIPTION,
      fetchPolicy: 'network-only',
    }).subscribe({
      next(result) {


        switch (result?.data?.newActionItemSubscription?.mutation) {
          case 'CREATE':

            break
          case 'UPDATE':

            if (conversation && result?.data?.newActionItemSubscription?.actionItem) {
              let indexOfActionItem = conversation?.actionItems?.edges?.map((node) =>
                node?.node?.id).indexOf(result?.data?.newActionItemSubscription?.actionItem?.id)
              let updatedActionItem = Object.assign(conversation?.actionItems?.edges[indexOfActionItem]?.node, {
                details: result?.data?.newActionItemSubscription?.actionItem?.details,
                status: result?.data?.newActionItemSubscription?.actionItem?.status
              });
              setcoachingConversationById(conversation)


            }
            break;
          case 'DELETE':

            break
          default:
            break
        }
      }
    })
  }


  const getConversation = async (id) => {
    setloading(true)
    const { data } = await client.query({
      query: CONVERSATION_BY_ID,
      variables: { id: id },
      fetchPolicy: 'network-only'
    });
    if (data.coachingConversationById) {
      setcoachingConversationById(data.coachingConversationById)
      
      getSubscribedToActionItems(data?.coachingConversationById)

      
    }
  }

  const getAllCustomUserCommitmentsForCoaching = async (id) => {
    // console.log
    const { data } = await client.query({
      query: GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_COACHING,
      variables: { coachingId: id },
      fetchPolicy: 'network-only'
    });
    if (data.customUserCommitmentForCoaching) {
      setCustomUserCommitmentsForCoaching(data.customUserCommitmentForCoaching)
      setloading(false)
    }
  }



  const getSurveyDetail = async (id) => {

    setloading(true)
    const { data } = await client.query({
      query: ORG_SURVEY_BY_ID_OPTIMIZED,
      variables: { id: id },
    });
    if (data?.orgSurveyById) {
      setorgSurveyById(data?.orgSurveyById)
      setloading(false)
    }
  }

  const getUserCommitments = async () => {
    let response = await getAllCustomUserCommitments({getAllCustomUserCommitments:{}})
    console.log('here is the response data = ::', response)
  }
// useEffect(()=>{
//   getUserCommitments()
//   console.log('here is the response data = ::')

// },[])


  React.useEffect(() => {
    if (props?.match?.params?.convoId && coachingPermitted?.required_permission) {
      let id = getConversationTypeBase64StringFromInt(props?.match?.params?.convoId)

      getConversation(id)
    }
  }, [props?.match?.params?.convoId, coachingPermitted])




  React.useEffect(() => {
    if (me && userPermission) {
      let permission = globalPermissionValidator(coaching_engagement_permission, userPermission)
      setCoachingPermitted(permission)
    }
  }, [me, userPermission])


  async function handleCustomUserCommitment(values) {
    // setSubmitLoading(true);
    try {
      const response = await updateCustomUserCommitment(values);
      if(response){
        // setSubmitLoading(false);
      }
    } catch (err) {
      // setSubmitLoading(false);
      throw new Error(err.message);
    }
  }

  const addCoacheeComm = async (title,toolId,description,userId,coachingId,dueDate) =>{
    // console.log('answers given by the coach root =::', title,toolId,description,userId,coachingId,dueDate)

    // let userIdInt = getIntFromString(userId)
    let coacheeCommAdded = await createCustomUserCommitment({customUserCommitmentData :{title,toolId:34,description,userId,coachingId,dueDate}})
    if (coacheeCommAdded){
      // console.log('the commitment is posted testing coacheeCommAdded', coacheeCommAdded,title,toolId,description,userIdInt)
    }
  }

  return (
    <>

      {
        !permissionLoader &&
        <div>
          {
            coachingPermitted?.required_permission ?  
              <>
                {
                  coachingConversationById && (
                    <ConversationDetailView {...props}
                    customUserCommitmentsForCoaching={customUserCommitmentsForCoaching}
                    onCustomUserCommitment={handleCustomUserCommitment}
                    getAllCustomUserCommitments={getAllCustomUserCommitments}
                    getUserCommitments={getUserCommitments}
                    addCoacheeComm={addCoacheeComm}
                      setloading={(e) => { setloading(e) }}
                      cleanup={cleanup}
                      coachingConversationById={coachingConversationById}
                      detail_loading={loading}
                      orgSurveyById={orgSurveyById} changeConversation={(e) => { setorgSurveyById(null); setcoachingConversationById(null); getConversation(e) }} SelectSurvey={(e) => { getSurveyDetail(e) }} ></ConversationDetailView>
                  )
                }
              </>
              :
              <NoPermissionView />
          }
        </div>
      }


    </>
  );

};

export default compose(withApollo,withGetAllCustomUserCommitments, withCreateConversation, withCreateActionItem, withUpdateActionItem,withGetAllCustomUserCommitments,withEditCustomUserCommitment,withAddCustomUserCommitment)(ConversationDetail);
