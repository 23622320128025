import gql from 'graphql-tag';
// import { DETAILED_TOKEN_LIST_TYPE } from './DetailedTokenListType.gql';

//    ${DETAILED_TOKEN_LIST_TYPE}
export const GET_TOKENS_LIST_BY_SURVEY = gql`
  query getAllTokensList(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $user: ID
    $survey: ID
    $createdBy: ID
    $invited: Boolean
    $valid: Boolean
    $responseSourceFor:ID
    $message_Icontains:String
    $user_FirstName_Icontains:String
  ) {
    tokenListBySurvey:getAllTokensList(
      first: $first
      offset: $offset
      orderBy: $orderBy
      user: $user
      survey: $survey
      createdBy: $createdBy
      invited: $invited
      valid: $valid
      responseSourceFor:$responseSourceFor
      message_Icontains:$message_Icontains
      user_FirstName_Icontains:$user_FirstName_Icontains
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          user {
            id
            username
            firstName
            lastName
            email
            profile {
              id
              phoneNumber
            }
          }
          invited
          createdBy {
            id
            firstName
            lastName
          }
          valid
          dateCreated
          survey {
            id
            name
          }
          link
        }
      }
    }
  }
`;
