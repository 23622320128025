// @ts-nocheck

import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { ME_QUERY } from '../user/graphql/MeQuery.gql';
import question_inactive from '../assets/question-inactive.svg'
import question_hover from '../assets/question-hover.svg'
import question_active from '../assets/question-active.svg'
import { withApollo } from '@apollo/client/react/hoc';
import { Col, Menu, Dropdown } from 'antd';

import PAGE_ROUTE from '../page/route';
import USER_ROUTE from '../user/route';
import ORGANIZATION_USER_VIEW from '../organization-user-view/route'
import NotificationContainer from '../notification/container/notificationDropdownContainer'
import User_vector from '../assets/user-vector.svg'
import Organization_vector from '../assets/organization-vector.svg'
import Logout_vector from '../assets/logout-vector.svg'
import { getItem, compose, removeItem } from '../core';
import { ACCESS_TOKEN, BACKEND_URI, JWT_AUTHENTICATION, NODE_ENV, } from '../../config';
import bell_inactive from '../assets/bell-inactive.svg'
import bell_hover from '../assets/bell-hover.svg'
import bell_active from '../assets/bell-active.svg'
import { NOTIFICATION_LIST, NOTIFICATION_LIST_NAV_BAR } from 'modules/notification/graphql/NotificationList.gql';
import NotificationCountContainer from 'modules/look-v2/Components/NotificationCount';
import { NOTIFICATION_SUBSCRIPTION_NAV_BAR } from 'modules/notification/graphql/NotificationSubscription.gql';
import { globalPermissionValidator } from 'modules/look';
import { notification_permissions } from 'Permissions/notification.permission';
import {
  DeploymentUnitOutlined,
} from '@ant-design/icons';
import ORGANIZATION_ROUTE from '../organization/route';
const NavBar = props => {

  const { me, client, userPermission, navigateRoute } = props;
  const [jwt, setJwt] = React.useState(null);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const handleLoggedIn = React.useRef(() => { });
  const [OpenMenu, setOpenMenu] = React.useState(false)
  const [notificationCount, setNotificationCount] = React.useState(0);

  const [OpenMenuNotification, setOpenMenuNotification] = React.useState(false)
  const notificationRef = React.useRef();
  const [notification_permission, setNotificationpermission] = React.useState()
  const [notifications, setnotifications] = React.useState()
  const [Readednotifications, setReadednotifications] = React.useState()
  const [notification_loading, setNotificationLoading] = React.useState(true)
  handleLoggedIn.current = async () => {
    const storedJwt = await getItem(ACCESS_TOKEN);
    setTimeout(3000, setLoggedIn(storedJwt));
  };

  const dataRef = React.useRef(0);
  React.useEffect(() => {
    dataRef.current = notificationCount
  }, [notificationCount])

  let notificationSubscription = undefined

  const subscribeToMore = async () => {
    if (!notificationSubscription) {
      notificationSubscription = client.subscribe({
        query: NOTIFICATION_SUBSCRIPTION_NAV_BAR,
      }).subscribe({
        next(result) {
          if (result?.data?.notificationSubscription) {
            getNotificationList()
            getReadedNotifications()
          }
        }
      })
    }

  }

  const getNotificationList = async () => {
    try {
      const { data } = await client.query({
        query: NOTIFICATION_LIST,
        variables: {
          orderBy: ["-actionTime"],
          readTime_Isnull: true,
          offset: 0,
          first: 5,
          recipient: me?.id
        },
        fetchPolicy: 'network-only'
      });
      if (data?.notifications) {
        setNotificationCount(data?.notifications?.totalCount)
        setnotifications(data?.notifications)
        setNotificationLoading(false)
      }
    } catch (error) {
      setNotificationLoading(false)
    }
  }
  const getReadedNotifications = async () => {
    try {
      const { data } = await client.query({
        query: NOTIFICATION_LIST,
        variables: {
          orderBy: ["-actionTime"],
          readTime_Isnull: false,
          offset: 0,
          first: 5,
          recipient: me?.id
        },
        fetchPolicy: 'network-only'
      });
      if (data?.notifications) {
        setReadednotifications(data?.notifications)
      }
    } catch (error) {

    }
  }

  React.useEffect(() => {
    if (userPermission?.length && me) {
      let permission = globalPermissionValidator(notification_permissions, userPermission)
      setNotificationpermission(permission)
      if (permission?.required_permission) {
        getNotificationList()
        getReadedNotifications()
        subscribeToMore()
      } else {
        notificationRef.current = undefined;
        setnotifications(undefined);
        setReadednotifications(undefined)
        setNotificationCount(0)
      }
    }
    else {
      setNotificationpermission(null)
    }
  }, [userPermission, me])

  const handleLogout = () => {
    removeItem(ACCESS_TOKEN);
    removeItem(JWT_AUTHENTICATION);
    localStorage.clear()
    setJwt(null);
    window.location.pathname = USER_ROUTE.login;
  };
  React.useEffect(() => {
    handleLoggedIn.current();
  }, []);

  const nologinBtn = [USER_ROUTE.login].includes(window.location.pathname.replace(/[0-9]/g, ''));
  const faqPage = [
    PAGE_ROUTE.faqPage
  ].includes(window.location.pathname.replace(/[0-9]/g, ''));

  React.useEffect(_ => {
    const handleScroll = _ => {
      if (window.pageYOffset > 0) {
        setOpenMenu(false)
        setOpenMenuNotification(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return _ => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const Profile = () => (
    <Menu className='nav-profile-dropdown'>
      <Col>
        {loggedIn ? (
          <Col >
            <div className='polygon'></div>
            <div className='nav-drop-content-div' style={{ paddingTop: "-10px" }}>
              <img style={{ width: "20px", height: '20px' }} src={User_vector} alt="" />
              {/* <a style={{color:"#000000"}} href={USER_ROUTE.profile}>{me ? me.username : 'My Account'}</a> */}
              <a style={{ color: "#000000" }} href={USER_ROUTE.profile}>My Account</a>
            </div>
            {me &&
              me?.employee?.organizationSet?.edges.map(({ node }) =>
              (
                <div className='nav-drop-content-div' style={{ marginTop: "20px" }}>
                  <img style={{ width: "20px", height: '20px' }} src={Organization_vector} alt="" />
                  <a style={{ color: "#000000" }} href={ORGANIZATION_USER_VIEW.organizationDetail.replace(':id', getIntFromString(node.id))}>{node.name}
                  </a>
                </div>

              ))}
            <div className='nav-drop-content-div' style={{ marginTop: "20px" }}>
              <img style={{ width: "20px", height: '20px' }} src={Logout_vector} alt="" onClick={handleLogout} />
              <p style={{ margin: 0 }} onClick={handleLogout} >Log out</p>
            </div>
          </Col>
        ) : (
          nologinBtn ?
            null
            :
            <div >
              <img className='nav-drop-content-div' src={Logout_vector} alt="" />
              <a href={USER_ROUTE.login}>Login</a>
            </div>
          //   <Menu.Item key="login" icon={<LoginOutlined />}>
          //   {<a href={USER_ROUTE.login}>Login</a>}
          // </Menu.Item>


        )}
      </Col>
    </Menu>
  )

  const Notification = () => (
    <Menu className='notification-dropdown' >
      <div className='polygon-for-note'></div>
      <NotificationContainer notifications={notifications} Readednotifications={Readednotifications} notification_loading={notification_loading} notification_permission={notification_permission} {...props} />
    </Menu>

  )

  const UserAuthDetail=()=>{
    let user_auth_detail = JSON?.parse(localStorage.getItem("manager_user"))
    return (user_auth_detail?.manager_user?.org_user || me?.employee?.organizationSet?.edges?.length)?true:false
  }
  return (
    <>
      <div className="top-nav-bar-icons-align">
        {
          me && me.isSuperuser && (
            <DeploymentUnitOutlined style={{ fontSize: "20px", marginTop: "5px" }} onClick={() => navigateRoute(ORGANIZATION_ROUTE.organization)} />
          )
        }


        <div>
          {
            (notificationCount > 0) ?
              <NotificationCountContainer notificationCount={notificationCount} position="absolute" notificationCountStyle={{
                width: notificationCount <= 9 ? "19px" : "24px", height: notificationCount <= 9 ? "19px" : "24px", borderRadius: "50%", background: "#E86161", color: "#fff"
                , textAlign: 'center', position: "absolute", top: "18px", right: "105px", display: "flex", flexDirection: "row"
                , justifyContent: "center", alignItems: "center", fontSize: "11px", fontWeight: "400", fontFamily: 'Poppins'
              }} />
              : null
          }
          <Dropdown overlay={Notification} overlayClassName="header-dropdown-index" style={{ cursor: 'pointer' }} visible={OpenMenuNotification} trigger="click" onClick={() => { OpenMenuNotification ? setOpenMenuNotification(false) : setOpenMenuNotification(true); setOpenMenu(false) }}>
            {OpenMenuNotification ?
              <img src={bell_active} className="icon-size" style={{ cursor: 'pointer', marginTop: "3px" }} /> : <div style={{ cursor: 'pointer', marginTop: '3px' }} className="active-notification"><img src={bell_inactive} className="bell-inactive icon-size" /> <img src={bell_hover} className="bell-hover icon-size" /></div>}
          </Dropdown>
          {OpenMenuNotification ? (<div className='overlay-dynamic-island-nav' onClick={() => setOpenMenuNotification(false)} />) : null}
        </div>

        <div>
          <a href={PAGE_ROUTE.faqPage} style={{ cursor: 'pointer' }} target="_blank" className="navTitle question-sction">{faqPage ? <img src={question_active} className="icon-size-question" style={{ cursor: 'pointer' }} /> : <div ><img src={question_inactive} className="question-inactive icon-size-question" /><img src={question_hover} className="question-hover icon-size-question" /></div>}</a>
        </div>
        <div style={{ marginTop: ".25em" }}>
          <Dropdown overlay={Profile} style={{ cursor: 'pointer' }} overlayClassName="header-dropdown-index" visible={OpenMenu} trigger="click" onClick={() => { OpenMenu ? setOpenMenu(false) : setOpenMenu(true); setOpenMenuNotification(false) }}>
            {OpenMenu ? <div className="icon-size-profile" style={{ cursor: 'pointer' }}>
              <svg width="17" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 14.0522C15.99 14.0522 19 11.031 19 7.0261C19 3.02122 15.99 0 12 0C8.01 0 5 3.02122 5 7.0261C5 11.031 8.01 14.0522 12 14.0522ZM14 15.9553H10C4.486 15.9553 0 20.458 0 25.9925V28H24V25.9925C24 20.458 19.514 15.9553 14 15.9553Z"
                  fill={UserAuthDetail() ? "#4CCB1F" : "rgb(235, 137, 14)"} />
              </svg>
            </div> :
              <div style={{ cursor: 'pointer' }} className="active-profile">
                <div className="inactive-user icon-size-profile" >
                  <svg width="17" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.65 7.0261C17.65 10.2901 15.2397 12.7022 12 12.7022C8.76032 12.7022 6.35 10.2901 6.35 7.0261C6.35 3.76207 8.76032 1.35 12 1.35C15.2397 1.35 17.65 3.76207 17.65 7.0261ZM1.35 25.9925C1.35 21.1988 5.23632 17.3053 10 17.3053H14C18.7637 17.3053 22.65 21.1988 22.65 25.9925V26.65H1.35V25.9925Z" stroke={UserAuthDetail() ? "#434343" : "rgb(235, 137, 14)"} stroke-width="2.7" />
                  </svg>
                </div>
                <div className="active-user icon-size-profile">
                  <svg width="17" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.65 7.0261C17.65 10.2901 15.2397 12.7022 12 12.7022C8.76032 12.7022 6.35 10.2901 6.35 7.0261C6.35 3.76207 8.76032 1.35 12 1.35C15.2397 1.35 17.65 3.76207 17.65 7.0261ZM1.35 25.9925C1.35 21.1988 5.23632 17.3053 10 17.3053H14C18.7637 17.3053 22.65 21.1988 22.65 25.9925V26.65H1.35V25.9925Z"
                      stroke={UserAuthDetail() ? "#4CCB1F" : "rgb(235, 137, 14)"} stroke-width={UserAuthDetail() ? "2.7" : "3.7"} />
                  </svg>
                </div>
              </div>}
          </Dropdown>
          {OpenMenu ? (<div className='overlay-dynamic-island-nav' onClick={() => setOpenMenu(false)} />) : null}
        </div>
      </div>
    </>
  );
};

export default compose(
  graphql(ME_QUERY, {
    options: () => {
      return {
        fetchPolicy: 'network-only'
      };
    },
    props({ data: { loading, error, me, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, me, subscribeToMore, updateQuery };
    }
  }),
  withApollo
)(NavBar);
export function getIntFromString(base64String) {
  return typeof base64String === "string"
    ? Number(window.atob(base64String).split(":")[1])
    : base64String;
}