
import home_icon from '../assets/new-home-icon.svg'
import soul_icon from '../assets/new-soal-icon.svg'
import goal_icon from '../assets/new-goal-icon.svg'
import whole_icon from '../assets/new-whole-icon.svg'
import poll_icon from '../assets/new-poll-icon.svg'
import survey_icon from '../assets/new-survy-icon.svg'
import shoal_icon from '../assets/new-shoal-icon.svg'
import side_nav_selector from '../assets/side-nav-selector.svg'
import side_bar_logo from '../assets/map-new-side-bar-logo.svg'
import search_icon from '../assets/new-nav-search-icon.svg'
import expand_icon from '../assets/map-nav-bar-expand-icon.svg'
import expand_close from '../assets/map-mav-bar-expand-close.svg'
import dasboard_non_active from '../assets/side-bar-dashboard-icon-non-active.svg'
import dasboard_active from '../assets/side-bar-dasboard-icon-active.svg'
import teams_active from '../assets/side-bar-teams-icon-active.svg'
import teams_non_active from '../assets/side-bar-teams-icon-non-active.svg'
import kudos_icon from '../assets/kudos-parent-icon.svg'
import value_active from '../assets/nav-icon-value-active.svg'
import value_inactive from '../assets/nav-icon-value-inactive.svg'
import strength_active from '../assets/nav-icon-strength-acive.svg'
import strength_inactive from '../assets/nav-icon-strength-inactive.svg'
import personality_active from '../assets/nav-personality-active.svg'
import personality_inactive from '../assets/nav-personality-inactive.svg'
import knowledge_and_skill_active from '../assets/nav-knowledge-and-skills-active.svg'
import knowledge_and_skill_inactive from '../assets/nav-knowledge-and-skills-inactive.svg'
import impact_narrative_active from '../assets/nav-icon-impact-narrative-active.svg'
import impact_narrative_inactive from '../assets/nav-icon-impact-narrative-inactive.svg'
import bars_active from '../assets/nav-icon-bars-active.svg'
import bars_inactive from '../assets/nav-icon-bars-inactive.svg'
import role_definition_active from '../assets/nav-icon-role-definition-active.svg'
import role_definition_inactive from '../assets/nav-icon-role-definition-inactive.svg'
import role_relation_active from '../assets/nav-icon-role-relation-active.svg'
import role_relation_inactive from '../assets/nav-icon-role-relation-inactive.svg'
import hiring_active from '../assets/nav-icon-hiring-active.svg'
import hiring_inactive from '../assets/nav-icon-hiring-inactive.svg'
import survey_active_icon from '../assets/nav-icon-survey-list-active.svg'
import survey_inactive_icon from '../assets/nav-icon-survey-list-inactive.svg'
import report_active from '../assets/nav-icon-reports-active.svg'
import readiness_active from '../assets/nav-icon-readiness-zone-active.svg'
import readiness_inactive from '../assets/nav-icon-readiness-zone-inactive.svg'
import okr_active from '../assets/nav-icon-okr-active.svg'
import okr_inactive from '../assets/nav-icon-okr-inactive.svg'
import one_on_one_active from '../assets/nav-icon-1-on-1-active.svg'
import one_on_one_inactive from '../assets/nav-icon-1-on-1-invactive.svg'
import career_conversation_active from '../assets/nav-icon-career-conversation-active.svg'
import career_conversation_inactive from '../assets/nav-icon-career-conversation-inactive.svg'
import coaching_engagement_inactive from '../assets/nav-icon-coaching-egagement-inactive.svg'
import coaching_engagement_active from '../assets/nav-icon-coaching-engagement-active.svg'
import managing_performance_inactive from "../assets/nav-icon-managing-performance-inactive.svg"
import managing_performance_active from "../assets/nav-icon-managing-performers-active.svg"
import feedback_active from '../assets/nav-icon-feedback-active.svg'
import feedback_inactive from '../assets/nav-icon-feedback-inactive.svg'
import nav_drop from '../assets/nav-side-bar-drop-down-arrow.svg'
import bread_crum_arrow from '../assets/bread_crum_arrow.svg'
import idp_inactive from '../assets/icons/IDP_inactive.svg'
import idp_active from '../assets/icons/IDP_active.svg'
export const navigationIcons ={
    home_icon,
    soul_icon,
    goal_icon,
    whole_icon,
    poll_icon,
    survey_icon,
    shoal_icon,
    side_nav_selector,
    side_bar_logo,
    search_icon,
    expand_icon,
    expand_close,
    dasboard_non_active,
    dasboard_active,
    teams_active,
    teams_non_active,
    kudos_icon,
    value_active,
    value_inactive,
    strength_active,
    strength_inactive,
    personality_active,
    personality_inactive,
    knowledge_and_skill_active,
    knowledge_and_skill_inactive,
    impact_narrative_active,
    impact_narrative_inactive,
    bars_active,
    bars_inactive,
    role_definition_active,
    role_definition_inactive,
    role_relation_active,
    role_relation_inactive,
    hiring_active,
    hiring_inactive,
    survey_active_icon,
    survey_inactive_icon,
    report_active,
    readiness_active,
    readiness_inactive,
    okr_active,
    okr_inactive,
    one_on_one_active,
    one_on_one_inactive,
    career_conversation_active,
    career_conversation_inactive,
    coaching_engagement_inactive,
    coaching_engagement_active,
    managing_performance_inactive,
    managing_performance_active,
    feedback_active,
    feedback_inactive,
    nav_drop,
    bread_crum_arrow,
    idp_inactive,
    idp_active,
}