// @ts-nocheck
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Carousel, Card, Progress, Skeleton, Row, Button, Col, Form, Empty, Modal, Tooltip, Space, Select, message } from 'antd';
import { LogoutOutlined, UpCircleTwoTone, DownCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import { ShortcutProvider, ShortcutConsumer } from 'react-keybind';

import SinglePageSectionComponent, { getTypeFromQuestion, TIME_LIMIT } from './SinglePageSectionComponent';
import { MetaTags, getIntFromString, InputValuesObject, getUniqueListBy, SelectField, TextField, EmployeeField, TextWithDateField, DatePickerField, KeyResultField, MilestoneField, scrolltoview, ReactMarkdown } from '../../look';
import HOME_ROUTE from '../../home/route'
import ROUTE from '../route';
import QuizLoading from './QuizLoading';
import SaveInCompleteQuizResponse from './SaveInCompleteQuizResponse';
import SurveyQuizShortcuts, { getShortCutObj } from './SurveyQuizShortcuts';
import SkillField from 'modules/look/components/form-components/SkillField';
import moment from 'moment-timezone';
import OkrFiled from 'modules/look/components/form-components/OkrFiled';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'modules/core';
import check_box from '../../assets/dashboard-check-box-icon.svg'
import SurveySelectField from 'modules/survey-look/containers/survey-select-filed';
import GrowSelectField from 'modules/survey-look/containers/grow-select-field';
import SurveyDatePickerField from 'modules/survey-look/containers/survey-datepicker-field';
const Option = Select.Option;
const { confirm } = Modal;

const ALL_EMPLOYEES_QUERY = gql`
  query allEmployees($nameIcontains: String) {
    allEmployees(nameIcontains: $nameIcontains) {
      edges {
        node {
          id
          user {
            id
            username
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const SinglePageQuizView = props => {
    const { loading, orgSurveyById, onSubmit, getUserCommitments, SurveyById, setPrevTotalDuration, quizStartTime, history, submitted, showTermsAndCondition, showAdditonalCoahee, showActionItem, setUserId, coachingConversation, allEmployees,me } =
        props;
    const QuesDiv = {
        paddingRight: '20px'
        // margin: 'auto',
        // minHeight: '50vh',
        // maxWidth: '800px',
        // width: ' 100%',
        // padding: `${props => !props.currentIsMatrix && '24px'}`
    };
    const BottomButton = {
        position: "absolute",
        top: "1rem",
        right: "2rem",
    }

    const ref = React.useRef();
    // const mobileRef = React.useRef();
    const currentSectionRef = React.useRef();
    const showOkRef = React.useRef();

    const [ showOk, setShowOk ] = React.useState( [ false ] );
    const [ timeoutId, setTimeoutId ] = React.useState( null );
    const [ currentSection, setCurrentSection ] = React.useState( 1 );
    const [ formValues, setFormValues ] = React.useState( [] );
    const [ startTime, setStartTime ] = React.useState( new Date().getTime() );
    const [ isForw, setIsForw ] = React.useState( true );
    const [ currentMatrixQuestion, setCurrentMatrixQuestion ] = useState( 0 );
    const [ enableShortCuts, setEnableShortCuts ] = useState( false );
    const [ visible, setVisible ] = useState( false );
    //specific to GROW survey
    const [ cocheeVal, setCocheeVal ] = useState();
    const [ conversationType, setConversationType ] = useState();
    const [ selected_skill, setSelectedSkill ] = useState();
    const [choices,setChoices]=React.useState([])
    const [ form ] = Form.useForm();

    React.useEffect(()=>{
        if(allEmployees?.edges?.length){
        let filter_list =allEmployees?.edges?.filter(({node})=>node?.user?.id!==me?.employee?.user?.id)?.map( ( { node } ) => ( {
            label: node.user.firstName && node.user.lastName ? `${node.user.firstName} ${node.user.lastName}` : node.user.username,
            value: [ `${node.user.firstName} ${node.user.lastName}`, getIntFromString( node.id ), getIntFromString( node.user.id ) ]

        } ) )
        setChoices(filter_list)
    }
    },[allEmployees])

    useEffect( () => {
        if ( !enableShortCuts ) {
            setEnableShortCuts( true );
        }
    }, [ enableShortCuts ] );

    useEffect(()=>{
        form.resetFields(["okr"])
    },[cocheeVal])
    const question = [].concat.apply(
        [],
        ( orgSurveyById ? orgSurveyById : SurveyById )?.groupSet?.edges
            .sort( function ( a, b ) {
                return a.node.sequence - b.node.sequence;
            } )
            .map( ( { node: grpSet }, idx ) =>
                grpSet.matrix
                    ? { ...grpSet }
                    : grpSet?.questionSet?.edges?.map( ( { node: questionSet } ) => ( {
                        ...grpSet,
                        questionSet: [ questionSet ]
                    } ) )
            )
    );

    const currentIsMatrix = question[ currentSection - 1 ]?.matrix;
    let totalLength = question.length;

    const shortcutObj = question?.map( q =>
        Array.isArray( q?.questionSet )
            ? q?.questionSet?.map( getShortCutObj )
            : q?.questionSet?.edges?.map( ( { node } ) => getShortCutObj( node ) )
    );

    function showDeleteConfirm() {
        confirm( {
            title: 'Are you sure you want to exit this survey?',
            icon: <LogoutOutlined />,
            content:
                'We will save all your current responses. You can choose to continue from here the next time you start this survey.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                history.push( HOME_ROUTE.home );
            },
            onCancel() {
            }
        } );
    }

    const renderFormSections = survey => {

        // function timeoutFunc(srNo) {
        //     const id = setTimeout(() => nextStep(form.getFieldsValue(true), srNo), TIME_LIMIT * 1000);
        //     setTimeoutId(id);
        // }
        function closetimer() {
            clearTimeout( timeoutId );
        }

        function getValue( value, name ) {
            if ( !currentIsMatrix ) {
                const questionFiltered = question.filter( q => {
                    return Array.isArray( q?.questionSet ) && q?.questionSet?.filter( qs => qs.id === name ).length > 0;
                } )[ 0 ]?.questionSet[ 0 ];
                switch ( questionFiltered?.choiceSet?.edges[ 0 ]?.node?.inputType ) {
                    case InputValuesObject.date:
                        return value?.format( 'YYYY/MM/DD' );
                    case InputValuesObject.datetime:
                        return value?.format( 'YYYY/MM/DD HH:mm:ss' );
                    case InputValuesObject.month:
                        return value?.format( 'M' );
                    case InputValuesObject.time:
                        return value?.format( 'HH:mm:ss' );
                    case InputValuesObject.week:
                        return value?.week();
                    case InputValuesObject.range:
                        return value === undefined || value === 0 ? 0 : value;
                    default:
                        return value || '';
                }
            } else {
                return value || '';
            }
        }

        function getResponseDuration( endTime, value, k ) {
            let time = 0;
            const existingValue = formValues.filter( f => f.questionId === getIntFromString( k ) )[ 0 ];

            if ( !currentIsMatrix ) {
                time =
                    question[ currentSection - 1 ]?.questionSet[ 0 ].id === k
                        ? existingValue
                            ? existingValue?.responseDuration + ( endTime - startTime ) / 1000 // returns time in seconds
                            : ( endTime - startTime ) / 1000 // returns time in seconds
                        : existingValue?.responseDuration || 0;
            } else {
                time = existingValue
                    ? existingValue?.answer === '' || existingValue?.answer === undefined
                        ? value !== '' || value !== undefined
                            ? 0
                            : ( endTime - startTime ) / 1000
                        : existingValue?.responseDuration
                    : value === '' || value === undefined
                        ? 0
                        : ( endTime - startTime ) / 1000;
            }
            return time;
        }

        const prevStep = () => {
            if ( currentSection === 0 ) {
                return false;
            }
            closetimer();
            setIsForw( false );
            ref.current.prev();
            // mobileRef.current.prev();
            if ( document ) {
                if ( document.body ) {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }
            }
        };

        function beforeChange( current ) {
            if ( !isForw && current === 0 ) {
                return false;
            }
            setCurrentSection( isForw ? current + 2 : current );
            setCurrentSectionFromRef( isForw ? current + 2 : current );
            setCurrentMatrixQuestion( 0 );
        }
        function getFieldName( index, current ) {
            return shortcutObj[ current - 1 ][ index ].name;
        }

        function getFieldType( index, current ) {
            return shortcutObj[ current - 1 ][ index ].type;
        }

        function getFieldValue( name, optionKey, current ) {
            const fieldValue = shortcutObj[ current - 1 ].filter( q => q.name === name )[ 0 ][ optionKey ];
            const currentFieldType = getFieldType( 0, current );
            return currentFieldType === InputValuesObject.radio ? fieldValue : [ ...( form.getFieldValue( name ) || [] ), fieldValue ];
        }
        function setCurrentSectionFromRef( value ) {
            currentSectionRef.current.innerHTML = Number( value );
        }
        function setShowOkFromRef( value ) {
            showOkRef.current.innerHTML = JSON.stringify( value );
        }

        const requiredRule = [ { required: true, message: 'An answer is required.' } ];

        const SubmitForm = ( value ) => {
            value[ "skill" ] = selected_skill?.value
            value[ "cocheerel" ] = conversationType
            const allContainMoment = value?.when_need?.every(subArray => subArray.some(item => moment.isMoment(item)));
            const allContainMomentNextStep = value?.when_next_step?.every(subArray => subArray.some(item => moment.isMoment(item)));
            if (!allContainMoment&&value?.when_need?.length>0) {
                message.error("Date is missing in What will be your next step and when?")
            }
            else if (!allContainMomentNextStep&&value?.when_next_step?.length>0) {
                message.error("Date is missing in What do you need from me?")
            }
            else{
                onSubmit( value )
            }

        }
        return (
            <>
                <span ref={currentSectionRef} style={{ display: 'none' }}>
                    1
                </span>

                <span ref={showOkRef} style={{ display: 'none' }}>
                    {JSON.stringify( [ false ] )}
                </span>

                {!submitted && survey?.id && showTermsAndCondition === false && (
                    <SaveInCompleteQuizResponse
                        form={form}
                        formValues={formValues}
                        itemName={survey?.id}
                        getValue={getValue}
                        setFormValues={setFormValues}
                        setPrevTotalDuration={setPrevTotalDuration}
                        startTime={quizStartTime}
                    />
                )}

                {survey?.groupSet.edges.length > 0 ? (
                    <>
                        <MetaTags title={survey[ currentSection ]?.label || survey?.name} description="This is survey page." />
                        <Form
                            form={form}
                            name={survey?.name}
                            // layout={'vertical'}
                            onFinish={values => ( SubmitForm( values ) )}
                            style={{ width: '100%' }}
                        >

                            <div style={{ width: '100%' }}>
                                <div style={QuesDiv}>
                                    {showAdditonalCoahee && (
                                        <div style={{ margin: 'auto 0' }}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: ".5em"}}>
                                                <h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}><span className="rd-input-star">*</span> Select Coachee</h4>
                                                <GrowSelectField
                                                    id="grow_coachee"
                                                    onChange={( value ) => { setCocheeVal( value[ 1 ] ); setUserId( value[ 2 ] ) }}
                                                    placeholder="Select a coachee"
                                                    name={"cochee"}
                                                    rules={requiredRule}
                                                    showSearch
                                                    choices={choices}
                                                    required={question?.required} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: "row", gap: "1em", justifyContent: 'start', width: "100%" }}>
                                                <div style={{ display: "flex", flexDirection: "column", gap: ".5em",width:'40%'}}>
                                                    <h4 className='coaching-engagement-form-lable'style={{fontSize:"1.3em"}}><span className="rd-input-star">*</span> This coaching conversation is related to</h4>
                                                    <div style={{ display: "flex", flexDirection: "row", gap: "1em", justifyContent: 'start', alignItems: 'center', paddingLeft: '.5em',marginTop:"1em" }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: 'cemter', gap: "1em" }}>
                                                            {conversationType === "okr" ? <img style={{ width: "2em", height: '2em', cursor: "pointer" }} src={check_box} alt="check-box"
                                                                onClick={() => setConversationType( 'okr' )} /> :
                                                                <div style={{ width: "2em", height: '2em', border: "1px solid rgba(140, 140, 161, 1)", borderRadius: ".25em", cursor: "pointer" }}
                                                                    onClick={() => setConversationType( 'okr' )} ></div>
                                                            }
                                                            <h4 className='coaching-engagement-form-lable' style={{ fontSize: "1.3em" }}>OKR</h4>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: 'cemter', gap: "1em" }}>
                                                            {conversationType === "skill" ? <img style={{ width: "2em", height: '2em', cursor: "pointer" }} src={check_box} alt="check-box"
                                                                onClick={() => setConversationType( 'skill' )} /> :
                                                                <div style={{ width: "2em", height: '2em', border: "1px solid rgba(140, 140, 161, 1)", borderRadius: ".25em", cursor: "pointer" }}
                                                                    onClick={() => setConversationType( 'skill' )}></div>
                                                            }
                                                            <h4 className='coaching-engagement-form-lable' style={{ fontSize: "1.3em" }}>Skill</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{width:"60%"}}>
                                                    {conversationType === "skill" && cocheeVal !== undefined && (
                                                        <SkillField
                                                            id="grow_skill"
                                                            onClick={( e ) => { scrolltoview( 'grow_skill' ) }}
                                                            name={"skill"}
                                                            owner={cocheeVal}
                                                            rules={requiredRule}
                                                            form={form}
                                                            label={<h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}>Select Skill</h4>}
                                                            style={{width:"100%" }}
                                                            onChange={( e ) => setSelectedSkill( e )}
                                                            closeAfterSelection={true}
                                                        />
                                                    )}
                                                    {conversationType === "okr" && cocheeVal !== undefined && (
                                                        <OkrFiled
                                                            id="grow_okr"
                                                            onClick={( e ) => { scrolltoview( 'grow_okr' ) }}
                                                            name={"okr"}
                                                            employee={cocheeVal}
                                                            rules={requiredRule}
                                                            style={{width:"100%" }}
                                                            label={<h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}>Select OKR</h4>}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            {/* {conversationType === "key_result" && cocheeVal !== undefined && (
                                                <KeyResultField
                                                    id="grow_coachee"
                                                    onClick={( e ) => { scrolltoview( 'grow_coachee' ) }}
                                                    name={"kr"}
                                                    owner={cocheeVal}
                                                    rules={requiredRule}


                                                />
                                            )} */}
                                            {/* {conversationType === "milestone" && cocheeVal !== undefined && (
                                                <MilestoneField
                                                    id="grow_milestone"
                                                    onClick={( e ) => { scrolltoview( 'grow_milestone' ) }}
                                                    name={"msf"}
                                                    owner={cocheeVal}
                                                    rules={requiredRule}

                                                />
                                            )} */}
                                            {/* <SelectField
                                                name={"goal"}
                                                label={"Select your Goal :"}
                                                rules={requiredRule}
                                            /> */}
                                        </div>
                                    )}
                                    {question?.map( ( grpSet, idx ) => (
                                        <Col className='grow_survey'>
                                            <SinglePageSectionComponent
                                                survey={orgSurveyById}
                                                key={idx}
                                                srNo={idx + 1}
                                                form={form}
                                                currentIsMatrix={currentIsMatrix}
                                                currentMatrixQuestion={currentMatrixQuestion}
                                                currentSection={currentSection}
                                                matrix={grpSet?.matrix}
                                                // timeoutFunc={timeoutFunc}
                                                closetimer={closetimer}
                                                sectionLabel={grpSet?.label}
                                                autoscroll={( e ) => { scrolltoview( e ) }}
                                                sectionSequence={grpSet?.sequence}
                                                questionList={
                                                    Array.isArray( grpSet?.questionSet )
                                                        ? grpSet?.questionSet?.map( node => node )
                                                        : grpSet?.questionSet?.edges?.map( ( { node } ) => node )
                                                }
                                                showOk={showOk[ currentSection - 1 ]}
                                                setShowOk={e =>
                                                    setShowOk( question.map( ( q, qi ) => ( e ? qi === currentSection - 1 : false ) ) )
                                                }
                                            />
                                        </Col>
                                    ) )}
                                    {showActionItem && (
                                        <div style={{ width: "100%", display: "flex", flexDirection: 'column', gap: '1em' }}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
                                                <h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}>What will be your next step and when?</h4>
                                                <TextWithDateField
                                                    name={"when_next_step"}
                                                    id="when_next_step"
                                                    onBlur={e => e?.target?.value}
                                                    form={form}
                                                    scrolltoview={e => scrolltoview( e )}
                                                    selectedid="when_next_step"
                                                    margin={"0px"}
                                                    required={question?.required}
                                                />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
                                                <h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}><span className="rd-input-star">*</span> What do you need from me?</h4>
                                                <TextWithDateField
                                                    name={"when_need"}
                                                    id="when_need"
                                                    onBlur={e => e?.target?.value}
                                                    form={form}
                                                    scrolltoview={e => scrolltoview( e )}
                                                    selectedid="when_need"
                                                    margin={"0px"}
                                                    required={question?.required}
                                                    rules={requiredRule}
                                                />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
                                                <h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}><span className="rd-input-star">*</span> When should we follow up again?</h4>
                                                <SurveyDatePickerField
                                                    style={{ width: "100%", minWidth: "17em", height: "2.5em" }}
                                                    name={"follow_up"}
                                                    id="follow_up"
                                                    disabledDate={( current ) => current.isBefore( moment().subtract( 1, "day" ) )}
                                                    form={form}
                                                    onClick={( e ) => { scrolltoview( 'follow_up' ) }}
                                                    selectedid="follow_up"
                                                    required={question?.required}
                                                    rules={requiredRule}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <br />
                                </div>
                            </div>
                            <Button className="enggement-main-button" style={{ marginTop: "1em" }} htmlType="submit">Submit</Button>
                        </Form>
                    </>

                ) : survey ? (
                    <div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Empty />
                    </div>
                ) : null
                }
            </>
        );
    };

    return (
        <MobilePadding>
            <div>
                {loading && <QuizLoading />}
                {!loading && orgSurveyById ? renderFormSections( orgSurveyById ) : renderFormSections( SurveyById )}
            </div>
        </MobilePadding>
    );
};

export default compose(
    graphql( ALL_EMPLOYEES_QUERY, {
        options: ( { filter, orderBy } ) => {
            return { variables: { ...filter, orderBy } };
        },
        props( { data: { loading, error, allEmployees, subscribeToMore, updateQuery } } ) {
            return { loading, error, allEmployees, subscribeToMore, updateQuery };
        }
    } ),

)( SinglePageQuizView );

const MobilePadding = styled.div`
 
`;
