import React from 'react'
import { compose } from '../../core';
import { READINESS_ASSESSMENT, READINESS_SUBSCRIPTION } from '../graphql/ReadinessAssessment.gql'
import { withApollo } from '@apollo/client/react/hoc'
import ReadinessAssessmentView from '../components/ReadinessAssessmentView';
import { readiness_color_zone } from '../config/readiness-color.config';
import { conditionCheck } from 'modules/look';

const ReadinessAssessmentContainer = (props) => {
    const { client, me, history } = props
    const [readiness_assessment, setReadinessAssessment] = React.useState([])
    const [page_info_assessment, SetPageInfoAssessment] = React.useState()
    const [assessment_loader, SetAssessmentLoader] = React.useState(false)
    const mountedRef = React.useRef(true)
    const readiness_ref = React.useRef([])
    let filter_ref = React.useRef({})
    
    let readinessSub = undefined
    React.useEffect(() => {
        readiness_ref.current = readiness_assessment
    }, [readiness_assessment])

    React.useEffect(() => {
        return () => {
            if (readinessSub) {
                readinessSub.unsubscribe();
            }
        }
    })

    React.useEffect(() => {
        return () => {
            mountedRef.current = false
        }
    }, [])

    React.useEffect(() => {
        mountedRef.current = true
    }, [])


    const ReadinessAssessment = async (filterData) => {
        filter_ref.current = { ...filter_ref.current, ...filterData }
  
        SetAssessmentLoader(true)
        const { data } = await client.query({
            query: READINESS_ASSESSMENT,
            variables: { 
                ...filter_ref.current, 
                first: 25,
                user: me?.employee?.id,
                received_after:filterData?.after?.received_after,
                submitted_after:filterData?.after?.submitted_after,
                orderBy: ["-createdAt", "-updatedAt"]  
            },
            fetchPolicy: 'network-only'
        });
        if (data) {
            let detail = {
                hasNextPage: data?.allReceivedReadiness?.pageInfo?.hasNextPage || data?.allSubmittedReadiness?.pageInfo?.hasNextPage,
                after: {
                    received_after: data?.allReceivedReadiness?.pageInfo?.endCursor,
                    submitted_after: data?.allSubmittedReadiness?.pageInfo?.endCursor,
                }
            }
            SetPageInfoAssessment(detail)
            let received_readiness = data?.allReceivedReadiness?.edges?.map(({ node }) => node)
            let submitted_readiness = data?.allSubmittedReadiness?.edges?.map(({ node }) => node)
            let readiness = [...received_readiness, ...submitted_readiness]?.filter(i => i?.readinessFrom?.user?.id != i?.readinessTo?.user?.id)
            var setObj = new Set();
            readiness = readiness?.reduce((acc, item) => {
                if (!setObj.has(item?.id)) {
                    setObj.add(item?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            readiness = handleReadinessStatus(readiness)
            let list = filterData?.after ? readiness_ref?.current?.concat(readiness) : readiness
            setReadinessAssessment(list)
            SetAssessmentLoader(false)
        }
        readinessSub = client.subscribe({
            query: READINESS_SUBSCRIPTION,
            variables: filterData
        }).subscribe({
            next(result) {
                let readinessCacheData = readiness_ref.current
                switch (result.data.readinessSubscription.mutation) {
                    case 'CREATE':
                        const deletedIfExists = readinessCacheData?.filter(item =>
                            item.id === result.data.readinessSubscription.readiness.id
                                ? false
                                : true).concat([result.data.readinessSubscription.readiness])
                        if (mountedRef.current) {
                            setReadinessAssessment(deletedIfExists)
                        }
                        break
                    case 'UPDATE':
                        const updated = readinessCacheData?.map(item =>
                            item.id === result.data.readinessSubscription.readiness.id
                                ? result.data.readinessSubscription.readiness
                                : item
                        )
                        if (mountedRef.current) {
                            setReadinessAssessment(updated)
                        }
                        break
                    case 'DELETE':
                        const deleted = readinessCacheData?.filter(item =>
                            item.id === result.data.readinessSubscription.readiness.id
                                ? false
                                : true)
                        if (mountedRef.current) {
                            setReadinessAssessment(deleted)
                        }
                        break
                    default:
                        break
                }
            }
        });

    }

    const handleReadinessStatus = (list) => {

        let data = list?.map(i => {
            let answer_set = i?.readinesslevelSet?.edges?.map(({ node }) => {
                let response_set = node?.response?.answerSet?.edges?.map(an => an?.node)?.map(s => {
                    return {
                        question: s?.question?.id,
                        answer: parseInt(s?.answer)
                    }
                })
                let rule_validation = {}
                readiness_color_zone.forEach(z => {
                    rule_validation[z.zone_id] = {}
                    response_set.forEach(rs => {
                        rule_validation[z.zone_id][rs.question] = true
                        let question_rule = z.rule?.find(rl => rl?.question_id == rs.question)
                        if (question_rule) {
                            question_rule.conditions.forEach(c => {
                                if (!conditionCheck(rs.answer, c.value, c.operation)) {
                                    rule_validation[z.zone_id][rs.question] = false
                                }
                            })
                        } else {
                            rule_validation[z.zone_id][rs.question] = false
                        }
                    })
                })
                let zone_enteries = Object.entries(rule_validation).find(rv => Object.values(rv[1])?.every(item => item === true))
                let zone
                if (zone_enteries) {
                    zone = readiness_color_zone?.find(z => z?.zone_id == zone_enteries[0])
                }

                return {
                    ...node,
                    response_set,
                    rule_validation,
                    zone
                }
            })

            return {
                ...i,
                zone_detail: answer_set
            }
        })
        return data
    }

    React.useEffect(() => {
        if (me) {
            ReadinessAssessment({})
        }
        else if (me === null) {
            history.push('/user/login')
        }
    }, [me])
    return (
        <ReadinessAssessmentView {...props} dataSource={readiness_assessment} page_info_assessment={page_info_assessment} onfilter={(e) => ReadinessAssessment(e)} assessment_loader={assessment_loader} />
    )

}
export default compose(withApollo)(ReadinessAssessmentContainer);