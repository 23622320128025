// @ts-nocheck
import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, message, Row, Select, Spin, Table, Tooltip } from "antd";
import React from 'react';
import { useHistory } from "react-router-dom";
import { surveyAnswerGroup } from 'ScapeReportConfig';
import styled from "styled-components";
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg';
import { getIntFromString, MetaTags } from '../../look';

  import Back_icon from '../../assets/soul-back.svg';
import '../css/scape-report.css';
const VisibleBorderTable = styled(Table)`
 border-left: 1px solid #e2e0e0;
 thead tr:first-child th {
     border-top: 1px solid #e2e0e0;
 }
`;

const ScapeReportView = (props) => {
    const history=useHistory()
    const { surveyList, onSelectedId, responseSurveyAnswer, responseTableData, loading,responseTableDataNew,employeeList } = props
    const [userSurveyList, setUserSurveyList] = React.useState([])
    const [surveyName, setSurveyName] = React.useState()
    const [surveyId, setSurveyId] = React.useState()
    const [surveyStringId, setSurveyStringId] = React.useState()
    const [tableAnswerList, setTableAnswerList] = React.useState([])
    const [dataSource, setDataSource] = React.useState([]);

    const [answer, setAnswer] = React.useState({});
    const [userAnsSequence, setUserAnsSequence] = React.useState({});

    const [empIdName, setEmpIdName] = React.useState({});
    const [tableHeading, setTableHeading] = React.useState({})
    const [cols, setCols] = React.useState([]);
    const [colData, setColData] = React.useState([]);
    const [tableLoading, setTableLoading] = React.useState(false)
    const [employeeDataList,setEmployeeDataList]=React.useState()
    const [selectedEmployes,setSelectedEmployes]=React.useState()
    const [showTable,setShowTable]=React.useState(false)
    React.useEffect(()=>{
        if(employeeList?.length){
            setEmployeeDataList(employeeList)
        }
    },[employeeList])
    React.useEffect(() => {
        let empoyeeSurvey = []
      
        const employeeSurveyList = empoyeeSurvey.filter((thing, index, self) =>
            index === self.findIndex((t) => (
                t.node.id === thing.node.id && t.node.name === thing.node.name
            ))
        )
        setUserSurveyList(employeeSurveyList)

    }, [surveyList])


    React.useEffect(() => {
        let surveyList = {}
        let edges = []
        let empIdName = {}
        let tableHead = {}
        let employeeAnswer = {}
        setTableLoading(true)
        if (surveyId && responseSurveyAnswer?.length) {
            let surveyGroupSet = surveyAnswerGroup.filter(item => item[surveyId])
            // setGroupAnswerSurvey(surveyGroupSet)
            let filterEmpty = responseSurveyAnswer.filter(item => item?.value)
            surveyGroupSet.map(item => {
                for (const [key, value] of Object.entries(item[surveyId])) {
                    for (const [k, v] of Object.entries(value)) {
                        let enterFilterData = filterEmpty.filter(item => v.includes(item?.value))
                        surveyList[k] = enterFilterData
                    }
                }
            })

            for (const [key, ans] of Object.entries(surveyList)) {
                tableHead[key] = ans
                edges = edges.concat(ans)
            }
          
            if(surveyList){
                tableHead['Others'] = []
                responseTableDataNew.map((employee) => {
                    employee.user.responseSet.edges.map((data) => {
                        employeeAnswer[employee?.user?.id] =  FilterDuplicates(data?.node?.survey?.groupSet?.edges[0]?.node?.questionSet?.edges?.map(q => q?.node))
                        data.node.survey.groupSet.edges[0].node.questionSet.edges.map(q => {
                            let x = edges?.find(item => q?.node?.answerSet?.edges[0]?.node?.answer?.trim() === item?.value?.trim())
                            if (x === undefined) {
                                let include = tableHead['Others'].find(item => { return item.value.trim() === q?.node?.answerSet?.edges[0]?.node?.answer?.trim() })
                                if (!include) {
                                    tableHead['Others'].push({
                                        id: q?.node?.answerSet?.edges[0]?.node?.id,
                                        value: q?.node?.answerSet?.edges[0]?.node?.answer,
                                        __typename: q?.node?.answerSet?.edges[0]?.node?.__typename
                                    })
                                }
                            }
                        })
                    })
                    empIdName[employee?.user?.id] = employee?.user?.firstName
        
                })
            }
            setTableHeading(tableHead)
            setEmpIdName(empIdName);
            setUserAnsSequence(employeeAnswer);
            setTableLoading(false)
            // fatchTableData(surveyList)
        }
        else{
            setTableHeading({})
            setEmpIdName({});
            setUserAnsSequence({});
            setTableLoading(false)
        }
    }, [responseSurveyAnswer, surveyId,responseTableDataNew,selectedEmployes])

    const FilterDuplicates =(answerLIst)=>{
        var setObj = new Set();
        let sortList =answerLIst?.sort((a,b)=>{
            return new Date(b.pubDate) - new Date(a.pubDate);
        })
        var result =sortList?.reduce((acc,item)=>{
          if(!setObj.has(item?.answerSet?.edges[0]?.node?.answer)){
            setObj.add(item?.answerSet?.edges[0]?.node?.answer)
            acc.push(item)
          }
          return acc;
        },[])
        return result
    }
    //titles
    React.useEffect(() => {
        const columns = []
        columns.push({
            title: <h4 className='scape-table-title'>{Object.keys(tableHeading).length !== 0?'Name':''}</h4>,
            width: 150,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            className: "visible-table-border-color"
        })
        let headingGroup = {}
        Object.keys(tableHeading)
            // .sort()
            .forEach(function (v, i) {
                if(tableHeading[v]?.length>0){
                    headingGroup[v] = tableHeading[v]
                }
               
            });
        for (const [key, values] of Object.entries(headingGroup)) {
            columns.push({
                title: <h4 className='scape-table-title' style={{ whiteSpace: 'nowrap' }}>{key} <Tooltip title='Total'><span style={{color:"#009AF1"}}>({TotalCount(key)})</span></Tooltip> </h4>,
                children: values.map(ans => ({
                    title: <h4 className='scape-table-answer' style={{ whiteSpace: 'nowrap' }}>{ans?.value}</h4>,
                    dataIndex: ans?.value,
                    key: ans?.id,
                    className: "visible-table-border-color"
                })),
                className: "visible-table-border-color"
            })
        }
        setCols(columns)
    }, [tableHeading])

    const TotalCount = (selectedKey) => {
        let total = 0
        let tableHead = tableHeading[selectedKey]
        let map = tableHead.map(item => item.value)
        for (const [key, data] of Object.entries(userAnsSequence)) {
            let filterdData = data.filter(item => map.includes(item?.answerSet?.edges[0]?.node?.answer))
            total = total + filterdData.reduce((a, b) => a + b?.sequence, 0)
        }
        return total
    }


    // table Data
    React.useEffect(() => {
        const data = []
        for (const [emp, ansList] of Object.entries(userAnsSequence)) {
            let d = {}
            d['key'] = emp
            d['name'] = <h2 className='scape-table-content'>{empIdName[emp]}</h2>
            ansList.forEach(item => {
                d[item?.answerSet?.edges[0]?.node?.answer] = <h2 className='scape-table-content'>{item?.sequence}</h2>
            })
            data.push(d)
        }
        if (data.length && !data?.find(val => val.key === 'Count')) {
            let dataCount = { key: 'Count', name: <h3 className='scape-table-title'>Count</h3> }
            let x = [].concat.apply([], Object.values(userAnsSequence))
            let result = removeDuplicateObjectFromArray(x, "answer")
            result.forEach(item => {
                let filterCount = x.filter(value => value?.answerSet?.edges[0]?.node?.answer === item?.answerSet?.edges[0]?.node?.answer)
                dataCount[item?.answerSet?.edges[0]?.node?.answer] = <h3 className='scape-table-title'>{filterCount?.length ? filterCount?.length : 0}</h3>
            })
            data.push(dataCount)
        }
        setColData(data)
    }, [userAnsSequence])


    //Table column count
    function removeDuplicateObjectFromArray(array, key) {
        var check = new Set();
        return array.filter(obj => !check.has(obj?.answerSet?.edges[0]?.node[key]) && check.add(obj?.answerSet?.edges[0]?.node[key]));
    }

    const handleSurveyResultMenu = (data) => {
        setSurveyName(data?.name)
        setSurveyId(getIntFromString(data?.id))
        setSurveyStringId(data?.id)
    }


    const userSurveys = () => (
        <Menu style={{ maxHeight: '200px', overflow: 'auto',zIndex:'-1' }}>
            {
                userSurveyList?.length ?
                    userSurveyList.map(({ node }) =>
                        <Menu.Item key={node?.id} onClick={() => handleSurveyResultMenu(node)} className='scape-menu-item'>
                            {node?.name}
                        </Menu.Item>
                    ) :
                    <Menu.Item >
                        No Survey found
                    </Menu.Item>
            }
        </Menu>
    );

    const goToShoal=()=>{
        history.push(`/shoal`)
    }
    const handleChange=(options)=>{
        if(options?.length){
            let data =options.map(item=>getIntFromString(item?.key))
            setSelectedEmployes(data)
        }
        else{
            setSelectedEmployes([])
        }
    }
   const onSerachScapeReport=()=>{
    setTableLoading(true)
    if(selectedEmployes?.length>0 && surveyStringId){
        setShowTable(true)
        onSelectedId({employeeIds:selectedEmployes,surveyId:surveyStringId})
    }
    else{
        message.error("Please Select Your Values")
        setTableLoading(false)
    }
   }
    return (
        <>
            <Row justify="center">
                <MetaTags title="Report" description={`This is scape report page`} />
                   <Col span={24}>
                  
                    <Row justify='center' align='center' style={{width:"100%",flexDirection:"column",justifyContent:'center',alignItems:"center",position:"relative"}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 1em' }}>
                        <img className="back-icon-scape back-arrow-global" src={Back_icon} alt="" onClick={()=>goToShoal()}/>
                            <h4 className='scape-head'>Scape Report</h4>
                        <img className="back-icon-scape back-arrow-global" src={Back_icon} alt="" onClick={()=>goToShoal()} style={{ visibility: 'hidden' }}/>

                        </div>

                            <Row align='center' justify='center' style={{width:"100%",gap:"20px"}}>
                                <Row align='center' justify='center' style={{width:"100%",gap:"20px"}}>
                                <Col style={{ textAlign: 'center', paddingTop: '20px' }}>
                                    <Dropdown overlay={userSurveys} >
                                        <DropdownButton className='scape-dropdown' trigger="click" placement="bottom">
                                            <p style={{ marginTop: '10px',whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden",color:surveyName?"#303030":"#BFBFBF"}}>{surveyName ? surveyName : 'Select survey'}</p>
                                            <img src={dropdown_Arrow} alt="" style={{width:"13px",height:"10px",marginTop:"-5px"}}/>
                                            <DownOutlined />
                                        </DropdownButton>
                                    </Dropdown>
                                    
                                </Col>
                                <Col style={{ textAlign: 'center', paddingTop: '20px'}}>
                                    <Select
                                        suffixIcon={<img src={dropdown_Arrow} alt="" style={{width:"13px",height:"10px",marginTop:"-5px"}}/>}
                                        mode="multiple"
                                        // allowClear
                                        className='scape-dropdown-employee'
                                        placeholder="Select Employees"
                                        style={{textAlign:"left",cursor:"pointer"}}
                                        maxTagCount={1}
                                        // value={defaultValue}
                                        onChange={(value,options)=>handleChange(options)}
                                        id="select-employee"
                                        showSearch={true}
                                        showArrow={true}
                                        // onSearch={e=>handleSearch(e)}
                                        
                                        >
                                        
                                            {
                                                employeeDataList?.map(item => 
                                                    {
                                                        return <Select.Option key={item?.id} value={item?.user?.firstName} >{item?.user?.firstName} {item?.user?.lastName}</Select.Option>
                                                    }
                                                )
                                            }

                                    </Select>
                                </Col>
                                </Row>
                               
                                <Col style={{ textAlign: 'center', paddingTop: '20px' }}>
                                    <Button className='search-button' disabled={!selectedEmployes?.length>0||!surveyStringId} onClick={()=>onSerachScapeReport()}>View Report</Button>
                                </Col>
                            </Row>
                           
                       
                    </Row>
                    {showTable ?
                        <Row justify="center" style={{ paddingTop: '50px' }}>
                            <Col span={24} style={{ overflow: 'auto',paddingBottom:"20px" }} className='scape-table-container'>
                                <Spin spinning={loading}>
                                    <VisibleBorderTable bordered={true}
                                        style={{ width: '100%' }}
                                        columns={cols}
                                        className='scape-table'
                                        dataSource={colData}
                                        pagination={false}
                                        loading={loading}
                                    />
                                </Spin>
                            </Col>
                        </Row> : null}
                </Col>
            </Row>
        </>
    )
}
export default ScapeReportView

const DropdownButton = styled(Button)`
// width:100%;
// display:flex;
// justify-content:space-between;
// align-items:center;
// gap:5px
// z-index:0;
`