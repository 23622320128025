// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import MeetingStartView from '../components/MeetingStartedView';
import { MEETING_BY_ID, MEETING_BY_ID_OPTIMIZED } from '../graphql/MeetingByid.gql';
import { withCreateActionItemsEditPage, withDeleteActionItem, withUpdateActionItems, withUpdateMeeting, withCreateMeetingNotes, withUpdateMeetingNotes, withEndMeeting, withRatingFeedbackMeeting } from './One_on_oneOperations';
import { getIntFromString, getToolTypeBase64StringFromInt, getMeetingTypeBase64StringFromInt, globalPermissionValidator } from 'modules/look';
import { REPORTED_BY_TOOL_BY_ID } from '../graphql/ReportedByToolId.gql'
import { ACTIONITEMS_SUBSCRIPTION } from '../graphql/ActionItemSubscription.gql'
import { FEEDBACK_SUBSCRIPTION } from '../graphql/FeedbackSubscription.gql'
import { ACTIONITEM_BY_MEETING_ID } from '../graphql/ActionItemSub.gql'
import { FEEDBACK_MEETING_ID } from '../graphql/FeedbackSub.gql'
import { MEETING_SUBSCRIPTION } from '../graphql/MeetingSubscription.gql';
import { CHECK_REPORTEE } from '../graphql/LoggedInUserRole.gql';
import { SOUL_AI_TIP_FOR_OTHER, SOULTIPS, SOULTIPS_FOR_REPORTEE, SOULTIPS_FOR_REPORTEE_CALL } from '../graphql/SouiTip.gql';
import { notification, message, Button, Col } from "antd";
import { getItem } from "../../../modules/core/clientStorage";
import { transcript_initiator, transcript_terminate, TRANSCRIPTION_SERVER_URI, TRANSCRIPTION_SOCKET_URI, BACKEND_URI, transcript_sync_confirmed, transcript_sync_started, ACCESS_TOKEN } from '../../../config';
import { oneonone_permission } from 'Permissions/oneonone.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';


const MeetingStartContainer = props => {
  const { client, me, history, userPermission } = props
  const [meetingById, setMeeting] = React.useState()
  const [getToolById, setgetToolById] = React.useState()
  const [agendaList, setagendaList] = React.useState([]);
  const [actionList, setactionList] = React.useState([]);
  const [feedbackList, setfeedbackList] = React.useState([]);
  const [is_reportee, setis_reportee] = React.useState();
  const [notes, setnote] = React.useState()
  const [soultips, setSoultips] = React.useState();
  const [socketError, SetocketError] = React.useState(false);
  const meetingEndedBy = React.useRef();
  const [meetingEnded, setMeetingEnded] = React.useState(false);
  const [meetingData, setMeetingData] = React.useState();
  const [recording_status, setRecordingStatus] = React.useState(false);
  const [transcription_waring_closed, setTranscription_waring_closed] = React.useState(false);
  const [transcription_visible, SetTranscription_visible] = React.useState(false);
  const mediaRecorderRef = React.useRef(null);
  const recordchunks = React.useRef([]);
  const recording_intervel = React.useRef();
  const data_retrive_intervel = React.useRef();
  let cache_message = React.useRef([])
  let socket_connection_ref = React.useRef()
  let recording_status_ref = React.useRef()
  const transcription_stream_ref = React.useRef()
  const [transcript_message, setTranscript_message] = React.useState();
  const [permission, setpermission] = React.useState()
  const mountedRef = React.useRef(true)
  const meeting = React.useRef(null);
  const m = React.useRef(true)
  const [meetingParticipiants, setParticipiants] = React.useState()
  const [transcription_media_availability, settranscription_media_availability] = React.useState();
  const transcription_unmute = React.useRef(true);
  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  React.useEffect(() => {
    mountedRef.current = true
  }, [])

  let feedbackSub = undefined
  let actionSub = undefined
  let meetingSub = undefined
  const actionRef = React.useRef()
  const feedRef = React.useRef()

  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(oneonone_permission, userPermission)
      setpermission(permission)
    }
    else {
      setpermission(null)
    }
  }, [userPermission])

  React.useEffect(() => {
    return () => {
      if (meetingSub) {
        meetingSub.unsubscribe();
      }
    }
  })
  React.useEffect(() => {
    return () => {
      if (feedbackSub) {
        feedbackSub.unsubscribe();
      }
    }
  })

  React.useEffect(() => {
    return () => {
      if (actionSub) {
        actionSub.unsubscribe();
      }
    }
  })

  React.useEffect(() => {
    return () => {
      if (actionItemSubscription) {
        actionItemSubscription.unsubscribe();
      }
      if (feedbackSubscription) {
        feedbackSubscription.unsubscribe();
      }

      if (meetingSubscription) {
        meetingSubscription.unsubscribe();
      }
    }
  })

  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])
  React.useEffect(() => {
    m.current = true
  }, [])

  React.useEffect(() => {

    if (meetingEndedBy.current === false && me && meetingData) {
      setMeetingEnded(true)
    } else if (meetingEndedBy.current === true && me && meetingData) {
      setMeetingEnded(false)
    }

  }, [meetingData])

  // Query for getting soultips of reportee

  const getSoulTips = async (user) => {
  
    if(user && (!soultips || !soultips?.length)){
      try {
     
        const { data } = await client.query({
          query: SOULTIPS_FOR_REPORTEE,
          variables: { reportUserId: getIntFromString(user), other: true },
          fetchPolicy: 'network-only'
        });
  
        if (data?.getSoulAiTip) {
          let tip = [data?.getSoulAiTip]?.map(item => item?.forOther)?.filter(item => item !== null)?.slice(0, 1)
          setSoultips([].concat(tip))
        }
        subscribeToSoulTip()
      } catch (e) {
        console.error(e)
      }
    }

  }

  const soul_tip_subscribe = React.useRef()
  const soul_added_from_sub = React.useRef()

  const subscribeToSoulTip = () => {
    soul_tip_subscribe.current = client.subscribe({
      query: SOUL_AI_TIP_FOR_OTHER
    }).subscribe({
      next(result) {
      
        switch (result.data.soulTipSubscription.mutation) {
          case 'CREATE': {
            if(result?.data?.soulTipSubscription?.soulTip?.forOther){
                        
              if(!soul_added_from_sub?.current){
                setSoultips([].concat([result?.data?.soulTipSubscription?.soulTip?.forOther]))
                  soul_added_from_sub.current = result.data.soulTipSubscription.soulTip
              }
          }
          }

          default:
            break
        }
      }
    })
  }

  // call function for getting soultips for reportee based on members of meeting

  React.useEffect(() => {
    if (meetingById && permission) {
      if (permission?.soul_tip_permission) {
        let member = meetingById?.member?.edges?.find((element) =>
          getIntFromString(element?.node?.user?.id) !== getIntFromString(me?.id)
        )
        if(member?.node?.user?.id){
          getSoulTips(member?.node?.user?.id)
        }
       
      }
      meeting.current = meetingById;
    }
  }, [meetingById, permission])


  let actionItemSubscription = undefined
  let feedbackSubscription = undefined
  let meetingSubscription = undefined
  const getMeetingDetail = async (value) => {
    try {
      let id = getMeetingTypeBase64StringFromInt(value);
      const { data } = await client.query({
        query: MEETING_BY_ID_OPTIMIZED,
        variables: { id: id },
        fetchPolicy: 'network-only'
      });
      if (data?.meetingById) {

        setMeeting(data?.meetingById)
        subscribeToMore()
        let members = data?.meetingById?.member?.edges?.map(({ node }) => node?.user?.employee?.id)?.filter(item => item != me?.employee?.id)
        checkUserRole(members)
      }
    } catch (e) {
      console.error(e)
    }

  }

  const subscribeToMore = async () => {
    try {
      if (!meetingSubscription) {
        meetingSubscription = client.subscribe({
          query: MEETING_SUBSCRIPTION,

        }).subscribe({
          next(result) {
            if (result?.data?.meetingSubscription) {
              switch (result.data.meetingSubscription.mutation) {
                case "UPDATE":
                  let meeting = result?.data?.meetingSubscription
                  if (meeting && getIntFromString(meeting?.meeting?.id) == props?.match?.params?.meetingid) {

                    if (meeting?.meeting?.endedAt) {
                      if (!meetingEndedBy.current) {
                        meetingEndedBy.current = false
                      }
                      setMeetingData(meeting?.meeting?.endedAt)
                    }
                  }
              }
            }
          }
        })
      }
    } catch (e) {
      console.error(e)
    }

  }

  const checkUserRole = async (members) => {
    try {
      const { data } = await client.query({
        query: CHECK_REPORTEE,
        variables: { membersbase64: members, membersint: members?.map(item => getIntFromString(item)) },
        fetchPolicy: 'network-only'
      });
      if (data?.reportee) {
        let employee_data = data?.reportee?.employee
        let count = employee_data?.teamMembersEmployee?.totalCount + employee_data?.verticalMembersEmployee?.totalCount + employee_data?.organizationSet?.totalCount
        setis_reportee(count > 0 ? true : false)
      }
    } catch (e) {
      console.error(e)
    }

  }





  React.useEffect(() => {
    if (permission?.requried_permission) {
      let id = props?.match?.params?.meetingid
      getMeetingDetail(id)
      ActionItemList(id)
      if (permission?.feedback_permission) {
        FeedBackList(id)
      }

    }
  }, [permission])


  React.useEffect(() => {
    if (meetingById && me) {
      getCallparticipants()
      SetCookie()
      let list = meetingById?.actionitemSet?.edges?.map(({ node }) => node)
      let noteslist = meetingById?.meetingnotesSet?.edges?.map(({ node }) => node)
      let note = noteslist?.find(item => item?.employee.id === me?.employee?.id)
      setnote(note)
    }
  }, [meetingById, me])

  /* Action iem and agenda query and subscription */

  const ActionItemList = async (id) => {
    const { data } = await client.query({
      query: ACTIONITEM_BY_MEETING_ID,
      variables: { id: getMeetingTypeBase64StringFromInt(id) },
      fetchPolicy: 'network-only'
    });
    if (data?.meetingById) {
      let list = data?.meetingById?.actionitemSet?.edges?.map(({ node }) => node)
      let agendaList = list?.filter(item => item?.isAgenda)
      let actionList = list?.filter(item => !item?.isAgenda)
      let actionFiltered = actionList?.map((item) => {
        return {
          title: item?.title,
          okrId: item?.okr,
          commitmentId: item?.commitment?.commitment,
          coachingCommitmentId: item?.coachingCommitment?.coachingcommitment,
          commitment: item?.commitment,
          coachingCommitment: item?.coachingCommitment,
          isAgenda: false,
          id: item?.id,
          member: null,
          notes: item?.notes,
          edited: false,
          updatedAt: item?.updatedAt,
          completed: item?.completed
        }
      }
      )

      let setAgenda = []
      agendaList.forEach(element => {

        let data =
        {
          id: element?.id,
          title: element?.title,
          // content:null,
          navigateTo: null,
          notes: element?.notes,
          okrId: element?.okr,
          commitmentId: element?.commitment?.commitment,
          coachingCommitmentId: element?.coachingCommitment?.coachingcommitment
        }


        setAgenda.push(data)
      });

      setagendaList(setAgenda)
      setactionList(actionFiltered)
      actionRef.current = data?.meetingById?.actionitemSet?.edges?.map(({ node }) => node)
    }
    actionSub = client.subscribe({
      query: ACTIONITEMS_SUBSCRIPTION,
      variables: { id: getMeetingTypeBase64StringFromInt(id) },
      fetchPolicy: 'network-only'
    }).subscribe({
      next(result) {
        let actionCacheData = actionRef.current
        switch (result?.data?.actionItemSubscription.mutation) {
          case 'CREATE':
            const created = actionCacheData?.filter(item =>
              item?.id === result?.data?.actionItemSubscription.actionItem.id
                ? false
                : true).concat([result?.data?.actionItemSubscription.actionItem])
            if (mountedRef.current) {
              let agendaList = created?.filter(item => item?.isAgenda)
              let actionList = created?.filter(item => !item?.isAgenda)
              let actionFiltered = actionList?.map((item) => {
                return {
                  title: item?.title,
                  okrId: item?.okr,
                  commitmentId: item?.commitment?.commitment,
                  coachingCommitmentId: item?.coachingCommitment?.coachingcommitment,
                  commitment: item?.commitment,
                  coachingCommitment: item?.coachingCommitment,
                  isAgenda: false,
                  id: item?.id,
                  member: null,
                  notes: item?.notes,
                  edited: false,
                  updatedAt: item?.updatedAt,
                  completed: item?.completed
                }
              }
              )
              let setAgenda = []
              agendaList.forEach(element => {

                let data =
                {
                  id: element?.id,
                  title: element?.title,
                  // content:null,
                  navigateTo: null,
                  notes: element?.notes,
                  okrId: element?.okr,
                  commitmentId: element?.commitment?.commitment,
                  coachingCommitmentId: element?.coachingCommitment?.coachingcommitment
                }


                setAgenda.push(data)
              });
              setagendaList(setAgenda)
              setactionList(actionFiltered)
              actionRef.current = created
            }
            break
          case 'UPDATE':
            const index = actionCacheData.findIndex(obj => obj.id === result?.data?.actionItemSubscription.actionItem.id);
            if (index !== -1) {
              actionCacheData.splice(index, 1, result?.data?.actionItemSubscription.actionItem);
            }
            // const filterList =  actionCacheData?.filter(item =>
            //   item?.id !== result?.data?.actionItemSubscription.actionItem.id
            // )
            // filterList.push(result?.data?.actionItemSubscription.actionItem)
            if (mountedRef.current) {
              let agendaList = actionCacheData?.filter(item => item?.isAgenda)
              let actionList = actionCacheData?.filter(item => !item?.isAgenda)
              let actionFiltered = actionList?.map((item) => {
                return {
                  title: item?.title,
                  okrId: item?.okr,
                  commitmentId: item?.commitment?.commitment,
                  coachingCommitmentId: item?.coachingCommitment?.coachingcommitment,
                  commitment: item?.commitment,
                  coachingCommitment: item?.coachingCommitment,
                  isAgenda: false,
                  id: item?.id,
                  member: null,
                  notes: item?.notes,
                  edited: false,
                  updatedAt: item?.updatedAt,
                  completed: item?.completed
                }
              }
              )
              let setAgenda = []
              agendaList.forEach(element => {

                let data =
                {
                  id: element?.id,
                  title: element?.title,
                  // content:null,
                  navigateTo: null,
                  notes: element?.notes,
                  okrId: element?.okr,
                  commitmentId: element?.commitment?.commitment,
                  coachingCommitmentId: element?.coachingCommitment?.coachingcommitment
                }


                setAgenda.push(data)
              });
              setagendaList(setAgenda)
              setactionList(actionFiltered)
              actionRef.current = actionCacheData
            }
            break
          case 'DELETE':
            const deleted = actionCacheData?.filter(item =>
              item?.id === result?.data?.actionItemSubscription.actionItem.id
                ? false
                : true)
            if (mountedRef.current) {
              let agendaList = deleted?.filter(item => item?.isAgenda)
              let actionList = deleted?.filter(item => !item?.isAgenda)
              let actionFiltered = actionList?.map((item) => {
                return {
                  title: item?.title,
                  okrId: item?.okr,
                  commitmentId: item?.commitment?.commitment,
                  coachingCommitmentId: item?.coachingCommitment?.coachingcommitment,
                  commitment: item?.commitment,
                  coachingCommitment: item?.coachingCommitment,
                  isAgenda: false,
                  id: item?.id,
                  member: null,
                  notes: item?.notes,
                  edited: false,
                  updatedAt: item?.updatedAt,
                  completed: item?.completed
                }
              }
              )
              let setAgenda = []
              agendaList.forEach(element => {

                let data =
                {
                  id: element?.id,
                  title: element?.title,
                  // content:null,
                  navigateTo: null,
                  notes: element?.notes,
                  okrId: element?.okr,
                  commitmentId: element?.commitment?.commitment,
                  coachingCommitmentId: element?.coachingCommitment?.coachingcommitment
                }


                setAgenda.push(data)
              });
              setagendaList(setAgenda)
              setactionList(actionFiltered)
              actionRef.current = deleted
            }
            break
          default:
            break
        }
      }
    })

  }


  const getFeedbackList = (feedbackList) => {
    let setFeedback = []
    feedbackList.forEach(element => {
      let data =
      {
        id: element?.id,
        title: element?.feedbackTo?.user?.firstName,
        content: element?.feedbackType ? element?.feedbackType : element?.content,
        navigateTo: null,
        response: element?.response,
        updatedAt: element?.updatedAt,
        detailFillIn: element?.detailFillIn,
        feedbackTo: element?.feedbackTo
      }
      setFeedback.push(data)
    })

    return setFeedback;

  }

  /*  Feedback list query and subscription */

  const FeedBackList = async (id) => {
    const { data } = await client.query({
      query: FEEDBACK_MEETING_ID,
      variables: { id: getMeetingTypeBase64StringFromInt(id) },
      fetchPolicy: 'network-only'
    });
    if (data?.meetingById) {
      let feedbackList = data?.meetingById?.feedback?.edges?.map(({ node }) => node)
      let setFeedback = getFeedbackList(feedbackList)
      setfeedbackList(setFeedback)
      feedRef.current = setFeedback
    }
    feedbackSub = client.subscribe({
      query: FEEDBACK_SUBSCRIPTION,
      variables: { id: getMeetingTypeBase64StringFromInt(id) },
      fetchPolicy: 'network-only'
    }).subscribe({
      next(result) {
        let feedbackCacheData = feedRef.current

        let isStakeHolderOfFeedback = meeting?.current.member?.edges?.filter((member) =>
          member?.node?.user?.employee?.id === me?.employee?.id)


        if (isStakeHolderOfFeedback?.length) {
          switch (result?.data?.feedbackSubscription.mutation) {
            case 'CREATE':
              const created = feedbackCacheData?.filter(item =>
                item.id === result?.data?.feedbackSubscription.feedback.id
                  ? false
                  : true).concat([result?.data?.feedbackSubscription.feedback])
              let feedbackList = getFeedbackList(created);

              // if (mountedRef.current) {

              setfeedbackList(feedbackList)
              feedRef.current = feedbackList
              // }
              break
            case 'UPDATE':

              const updated = feedbackCacheData?.map(item =>
                item.id === result?.data?.feedbackSubscription.feedback.id
                  ? result?.data?.feedbackSubscription.feedback
                  : item
              )
              if (mountedRef.current) {
                setfeedbackList(updated)
                feedRef.current = updated
              }
              break
            case 'DELETE':
              const deleted = feedbackCacheData?.filter(item =>
                item.id === result?.data?.feedbackSubscription.feedback.id
                  ? false
                  : true)
              if (mountedRef.current) {
                setfeedbackList(deleted)
                feedRef.current = deleted
              }
              break
            default:
              break
          }
        }


      }
    })
  }

  React.useEffect(() => {
    recording_status_ref.current = recording_status
    notification.destroy()
    clearInterval(recording_intervel?.current)
    clearInterval(data_retrive_intervel?.current)

    if (transcription_media_availability && transcription_media_availability?.audio) {
      if (recording_status) {
        StartRecordingSession()
      } else if (mediaRecorderRef.current && !recording_status) {
        if (mediaRecorderRef.current?.state !== "inactive") {
          mediaRecorderRef.current.stop()
          mediaRecorderRef.current = null
        }
        if (transcription_stream_ref.current) {
          let audiotracks = transcription_stream_ref.current.getAudioTracks()
          try {
            if (audiotracks) {
              audiotracks.forEach(item => {
                item.stop()
              })
            }
          } catch (error) {

          }
        }
      }
    }


  }, [recording_status])

  const socketInfoNotify = (action) => {
    if (socket_connection_ref?.current && transcription_visible) {
      if (action) {
        socket_connection_ref.current.send(JSON.stringify({ results: { text: transcript_initiator } }))
        let start_msg = { "results": { "text": `${me?.firstName} has started transcription`, "user_id": "start", "meeting_id": getIntFromString(meetingById?.id), "requested_at": new Date(), "chunk_id": `info_${Math.random()}`, info: true, intiated_user: getIntFromString(me?.id) } }
        socket_connection_ref.current.send(JSON.stringify(start_msg))
      } else {
        let stop_msg = { "results": { "text": `${me?.firstName} has stopped transcription`, "user_id": "stop", "meeting_id": getIntFromString(meetingById?.id), "requested_at": new Date(), "chunk_id": `info_${Math.random()}`, info: true, intiated_user: getIntFromString(me?.id) } }
        socket_connection_ref.current.send(JSON.stringify(stop_msg))
        socket_connection_ref.current.send(JSON.stringify({ results: { text: transcript_terminate } }))
      }
    }
  }


  const StartRecordingSession = () => {
    try {
      if (navigator?.mediaDevices?.getUserMedia) {
        notification.destroy()
        notification.open({
          message: 'Transcription Started',
          className: 'transcription-notification',
          duration: 30,
          onClose: () => { setTranscription_waring_closed(true) }
        });
        navigator.mediaDevices.getUserMedia({ audio: { sampleRate: 16000, noiseSuppression: true, sampleSize: 16000 } })
          .then(stream => {
            if (stream) {
              transcription_stream_ref.current = stream
              const mediaRecorder = new MediaRecorder(stream)
              mediaRecorderRef.current = mediaRecorder
              const audioContext = new AudioContext();
              const source = audioContext.createMediaStreamSource(stream);
              const analyser = audioContext.createAnalyser();
              const processor = audioContext.createScriptProcessor(4096, 1, 1);
              source.connect(analyser);
              const bufferLength = analyser.frequencyBinCount;
              const dataArray = new Uint8Array(bufferLength);
              mediaRecorder.addEventListener('dataavailable', event => {
                if (transcription_unmute.current) {
                  recordchunks.current.push(event.data.slice());
                  convertToBase64(recordchunks.current)
                }
                recordchunks.current = []
              });
              let isRecording = false;
              const threshold = 8;

              recording_intervel.current = setInterval(() => {
                if (mediaRecorderRef.current) {
                  analyser.getByteFrequencyData(dataArray);
                  const average = dataArray.reduce((acc, val) => acc + val) / bufferLength;
                  if (!isRecording && average > threshold) {

                    recordchunks.current = [];
                    mediaRecorderRef.current.start();
                    isRecording = true;
                  } else if (isRecording && average < threshold) {
                    mediaRecorderRef.current.stop();
                    isRecording = false;
                  }
                }
              }, 100);



            }
          })
      } else {
        message.warning('your browser doesnot support transcription')
      }
    } catch (error) {
      message.warning('your browser doesnot support transcription')
    }
  }

  const convertToBase64 = (blob_list) => {


    if (blob_list?.length) {
      const durationInSeconds = 3;
      const emptyAudioBlob = createEmptyAudioBlob(durationInSeconds);
      blob_list.push(emptyAudioBlob)

      var blob = new Blob(blob_list, { type: 'audio/wav' });
      var file = new File([blob], `audio${Date.now()}.wav`, { type: 'audio/wav' });
      if (recording_status_ref.current) {
        handleTranscribe(file)
      }
    }
  }

  const createEmptyAudioBlob = (durationInSeconds) => {
    // Create an AudioContext
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    // Create an empty audio buffer
    const audioBuffer = audioContext.createBuffer(1, audioContext.sampleRate * durationInSeconds, audioContext.sampleRate);

    // Convert the audio buffer to a blob
    const audioData = audioBuffer.getChannelData(0);
    const audioBlob = new Blob([audioData], { type: 'audio/wav' }); // Adjust the MIME type as needed

    return audioBlob;
  }
  // Handle the API call to transcribe the audio
  const handleTranscribe = async (audio_data) => {

    let token = await getItem(ACCESS_TOKEN)
    try {
      let form_data = new FormData()
      form_data.append('language', 'en')
      form_data.append('input_data', audio_data)
      form_data.append('model_size', 'small')
      form_data.append('meeting_id', getIntFromString(meetingById?.id))

      fetch(`${TRANSCRIPTION_SERVER_URI}/transcribe`, {
        method: 'POST',
        body: form_data,
        credentials: 'include',
        headers: {
          Authorization: `JWT ${token}`
        }
      })
        .then((res) => res.json())
        .then((response) => {

        })
        .catch((err) => {

        })
    } catch (error) {

    }
  };

  const SetCookie = async (reconnection = false) => {
    let token = await getItem(ACCESS_TOKEN)
    try {
      var blob = new Blob([], { type: 'audio/wav' });
      var audio_data = new File([blob], `audio${Date.now()}.wav`, { type: 'audio/wav' });
      let form_data = new FormData()
      form_data.append('language', 'en')
      form_data.append('input_data', audio_data)
      form_data.append('model_size', 'small')
      form_data.append('meeting_id', getIntFromString(meetingById?.id))

      fetch(`${TRANSCRIPTION_SERVER_URI}/transcribe`, {
        method: 'POST',
        body: form_data,
        credentials: 'include',
        headers: {
          Authorization: `JWT ${token}`
        }
      })
        .then((res) => {
          if (!reconnection) {
            listenToNetwork()
          }
          integrateSocket()
        })
        .catch((err) => { })
    } catch (error) { }
  };

  const listenToNetwork = () => {
    window.addEventListener('online', () => {
      if (socket_connection_ref.current) {
        socket_connection_ref.current.close()
        socket_connection_ref.current = null
      }
      SetCookie(true)
    });
  }

  React.useEffect(() => {
    return () => {
      // Code to be executed when the component is unmounted
   
      if (socket_connection_ref.current) {
        socket_connection_ref.current.close()
        socket_connection_ref.current = null
      }
      window.removeEventListener('online', () => { });
      if (soul_tip_subscribe?.current) {
        soul_tip_subscribe.current.unsubscribe()
      }
    };
  }, []);
  const integrateSocket = async () => {

    try {

      let requestUrl = `${TRANSCRIPTION_SOCKET_URI}/${getIntFromString(meetingById?.id)}/`
      const socket_client = new WebSocket(requestUrl);
      socket_connection_ref.current = socket_client
      socket_client.onopen = () => { SetTranscription_visible(true); transcriptionSync() };
      socket_client.onerror = (err) => { message.error('Your browser failed to connect to socket'); SetocketError(true) };
      socket_client.onmessage = (message) => {
        let socket_data = JSON.parse(message?.data)
        if (recording_status_ref.current && socket_data?.results?.text === transcript_sync_started && socket_data?.results?.user_id != me?.employee?.id) {
          socket_connection_ref.current.send(JSON.stringify({ results: { text: transcript_sync_confirmed, user_id: me?.employee?.id } }))
        }
        else if (!recording_status_ref.current && socket_data?.results?.text === transcript_sync_confirmed && socket_data?.results?.user_id != me?.employee?.id) {
          setRecordingStatus(true)
        }
        else if (!recording_status_ref.current && socket_data?.results?.text === transcript_initiator) {
          setRecordingStatus(true)
        }
        else if (recording_status_ref.current && socket_data?.results?.text === transcript_terminate) {
          setRecordingStatus(false)
        }
        else if (socket_data?.results) {
          let cache = cache_message.current
          let check_for_update = cache?.find(item => item?.results?.chunk_id == socket_data?.results?.chunk_id && item?.results?.user_id == socket_data?.results?.user_id)
          if (check_for_update) {
            check_for_update.results.text = socket_data.results.text
          } else {
            cache.push(socket_data)
          }
          cache_message.current = cache
          setTranscript_message([].concat(cache))
        }
      }
      socket_client.onclose = (event) => { }
    } catch (error) {

    }

  }

  const transcriptionSync = () => {
    socket_connection_ref.current.send(JSON.stringify({ results: { text: transcript_sync_started, user_id: me?.employee?.id } }))
  }

  const disconnectSocket = () => {
    if (transcription_stream_ref.current) {
      try {
        let audiotracks = transcription_stream_ref.current.getAudioTracks()

        if (audiotracks) {
          audiotracks.forEach(item => {
            item.stop()
          })
        }
      } catch (error) {

      }
    }
    setRecordingStatus(false)
    if (socket_connection_ref.current) {
      socket_connection_ref.current.close()
      socket_connection_ref.current = null
    }
  }

  const getCallparticipants = () => {
    let participants = meetingById?.member?.edges?.filter((element) =>
      getIntFromString(element?.node?.user?.id) !== getIntFromString(me?.id)
    ).map(({ node }) => node?.user?.id)
    setParticipiants(participants)
  }



  return (
    <>
      {
        (permission && permission?.requried_permission) && (
          meetingById ?
            <>
              <MeetingStartView
                {...props}
                getToolById={getToolById}
                meetingById={meetingById}
                agendaList={agendaList}
                actionList={actionList}
                feedbackList={feedbackList}
                selfNotes={notes}
                meetingEnded={meetingEnded}
                setMeetingEnded={setMeetingEnded}
                is_reportee={is_reportee}
                meetingEndedBy={meetingEndedBy}
                meetingData={meetingData}
                soultips={soultips}
                setRecordingStatus={(e) => { socketInfoNotify(e); setRecordingStatus(e) }}
                recording_status={recording_status}
                transcript_message={transcript_message}
                transcription_visible={transcription_visible}
                disconnectSocket={() => { disconnectSocket() }}
                permission={permission}
                meetingParticipiants={meetingParticipiants}
                socketError={socketError}
                settranscription_media_availability={(e) => { settranscription_media_availability(e) }}
                settranscription_unmute={(e) => { transcription_unmute.current = e }}
                setnote={setnote}
              />
            </>
            :
            <div style={{ width: '100%' }} align="center">
            </div>

        )
      }

      {
        (permission && !permission?.requried_permission) && (
          <NoPermissionView />
        )
      }


    </>
  )

};
export default compose(withApollo, withCreateActionItemsEditPage, withDeleteActionItem, withUpdateActionItems, withUpdateMeeting, withCreateMeetingNotes, withUpdateMeetingNotes, withEndMeeting, withRatingFeedbackMeeting)(MeetingStartContainer);

