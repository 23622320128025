// @ts-nocheck
import React from "react";
import { Col, Modal, Row, Tooltip,Spin, Select,} from "antd";
import Tree from "react-d3-tree";
import "../../../treenode.css";
import { CustomProgress } from "modules/look-v2";
import Supervisor from '../../assets/supervisor.svg'
import close from '../../assets/close.svg'
import arrow_up from '../../assets/arrow_up.svg'
import arrow_down from '../../assets/arrow_down.svg'
import tree_center from '../../assets/tree_center.svg'
import InfoCircle from '../../assets/InfoCircle.svg'
import {CONTRIBUTOR_OKR_WEIGHTS_UNDER_OBJECTIVE_WITH_ID} from '../graphql/KrWeight.gql'
import {CONTRIBUTOR_MILESTONE_WEIGHTS_UNDER_OBJECTIVE_WITH_ID} from '../graphql/MilestoneWeight.gql'
import d3_arrow from '../../assets/d3_arrow.svg'
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
import moment from 'moment';
import { getIntFromString } from "modules/look";
const ObjectiveTreeView = (props) => {
  const { level, okrChart, closeModal, getNextLevelData, changeLevel, expandSelectedNode, client,reCenter } = props;
 console.log(" okrChart", okrChart)
  const[resetState, setResetState] = React.useState(false)
  const [treeId, setTreeId] = React.useState(Math.random())
  const [isDetailsModelVisible, setIsDetailsModelVisible] = React.useState(false)
  const [spinDetails, setSpinDetails] = React.useState(false)
  const [detailsType, setDetailsType] = React.useState()
  const [detailsData, setDetailsData] = React.useState()

  const expandNode =(nodeDatum)=>{
    if(!nodeDatum?.attributes?.loader){
      expandSelectedNode(nodeDatum?.attributes?.contribution_id)
    }
  }

  const onDetails = async(type, node) => {
    if (node?.attributes?.okr?.id !== undefined) {
      setSpinDetails(true)
      setIsDetailsModelVisible(true)
      if(type === "kr"){
        const { data } = await client.query({
          query: CONTRIBUTOR_OKR_WEIGHTS_UNDER_OBJECTIVE_WITH_ID,
          fetchPolicy: 'network-only',
          variables: { okr_Parent : node?.attributes?.okr?.id,employee:node?.attributes?.employee,tz:moment.tz.guess(),id:node?.attributes?.okr?.id},
        });
        setDetailsType({type:"KEY RESULTS",detail:node})
        setDetailsData(data)
        
      } else {
        const { data } = await client.query({
          query: CONTRIBUTOR_MILESTONE_WEIGHTS_UNDER_OBJECTIVE_WITH_ID,
          fetchPolicy: 'network-only',
          variables: { okr_Parent: node?.attributes?.okr?.id,employee:node?.attributes?.employee,tz:moment.tz.guess(),id:node?.attributes?.okr?.id},
        });
        setDetailsType({type:"MILESTONES",detail:node})
        setDetailsData(data)
      }
      setSpinDetails(false)
    }
  }

  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => {  
    return (
      <g>
        <foreignObject
          width="500"
          height="205"
          x="-250"
          y="-95"
          className="custom-tree-node"
          onClick={toggleNode}
          
        >
          <div className="foreign-parent" id={`fo_${nodeDatum?.attributes?.contribution_id}`}>
            {(nodeDatum?.attributes?.rect_node && !nodeDatum?.attributes?.loader) ? (
              <div className={`foreign-rect row ${nodeDatum?.attributes?.active_node?'active-node-border':''}`} style={{gap:"0px"}}>
                <div onClick={()=>expandNode(nodeDatum)} className="foreign-circle foreign-circle-left">
                  <h4 className="node-name">{nodeDatum?.attributes?.employee_detail?.firstName?.charAt(0)?.toUpperCase()}{nodeDatum?.attributes?.employee_detail?.lastName?.charAt(0)?.toUpperCase()}</h4>
                </div>
                <div onClick={()=>expandNode(nodeDatum)}  className="react-data-container" >
                <div align="center">
                <Tooltip title={nodeDatum?.attributes?.okr?.goalStatement}>
                <h4 className="node-goal-statement">{nodeDatum?.attributes?.okr?.goalStatement}</h4>
                </Tooltip>
                </div>
                <div className="row space-between" style={{padding:"20px",gap:"2px"}}>
                  <div style={{width:"10px",height:"5px"}}></div>
                  <div style={{width:"250px"}} className="progress-parent">
                  <CustomProgress showTooltip={true} progress={nodeDatum?.attributes?.progress}/>
                  <div className="row" style={{justifyContent:"center",marginTop:"30px"}} >
                    <img  src={Supervisor} alt="" />
                    <h4 className="node-user-name">{nodeDatum?.name}</h4>
                  </div>
                  </div>
                  {/* disabled={nodeDatum?.parentokr ? false : true} */}
              
                    <div>
                    {
                    nodeDatum?.attributes?.okrType==='OBJECTIVE'  &&(
                        <div  className="custom-node-btn hover-key" style={{ color: "#009AF1" }} onClick={(e) => {onDetails("kr", nodeDatum);e.stopPropagation()}}>
                        KR
                    </div>
                      )
                    }
                    {
                     nodeDatum?.attributes?.okrType==='KEY_RESULT' && (
                        <div  className="custom-node-btn hover-mile" style={{ color: "#FAC53C" }} onClick={(e) => {onDetails("m", nodeDatum);e.stopPropagation()}}>
                        M 
                      </div>
                      )
                    }
                    </div>
                    
                
                </div>
                </div>
              </div>
            ) : (
              <div className="foreign-circle" style={{cursor:"pointer"}} onClick={()=>expandNode(nodeDatum)}>
                {
                  !nodeDatum?.attributes?.loader?
                  <h4 className="node-name">{nodeDatum?.attributes?.employee_detail?.firstName?.charAt(0)?.toUpperCase()}{nodeDatum?.attributes?.employee_detail?.lastName?.charAt(0)?.toUpperCase()}</h4>
                  :<Spin spinning={nodeDatum?.attributes?.loader}></Spin>
                }
                
              </div>
            )}
          </div>
        </foreignObject>
      </g>
    );
  };


  const onDetailsClose = () => {
    setIsDetailsModelVisible(false)
  }

  const drawStepPath = (linkData, orientation) => {
    
    const { source, target } = linkData;
    let offSet = 0
    const deltaY = target.y - source.y;
    return orientation === 'horizontal'
      ? `M${source.y},${source.x} H${source.y + deltaY / 2} V${target.x} H${target.y}`
      : `M${source.x + offSet},${source.y} V${source.y + deltaY / 2} H${target.x} V${target.y}`;
  }

  const getDynamicPathClass = ({ source, target }, orientation) => {
    let className = ""
    if (target.data.attributes.okrType === "OBJECTIVE"){
      className = "objective-link"
    }
    else if (target.data.attributes.okrType === "KEY_RESULT"){
      className = "key-res-link"
    } else if (target.data.attributes.okrType === "MILESTONE") {
      className = "milestone-link"
    }else {
      className = "null-link"
    }
    // Style it as a link connecting two branch nodes by default.
    return className
  };

 const loadTitle =(data)=>{
  var setObj = new Set();
  let employeeData = data?.okr?.contributorSet?.edges.reduce((acc,item)=>{
    if(!setObj.has(item?.node?.employee?.id)){
      setObj.add(item?.node?.employee?.id)
      acc.push(item)
    }
    return acc;
  },[])
  let name_list = employeeData?.map(({node})=>node?.employee?.user?.firstName)?.join(',')
  return name_list || 'employee'
 }

 const getinitialZoom =()=>{
  let zoom = 0.6
  let width=window?.innerWidth
  if(width>=1800){
    zoom=.8
  }
  else if(width<1200 &&width>600){
    zoom=.5
  }
  else if(width<=600){
    zoom=.4
  }
  return zoom
 }

 const getDimension=()=>{
  let element =document.getElementById('treeWrapper')
  if(element){
    let bounding= element.getBoundingClientRect()
    let dimension= {
      width:bounding?.width,
      height:bounding?.height
    }
    return dimension
  }else{
    return {
      width:window.innerWidth-240 || 1140,
      height:window.innerHeight-150 || 750
    }
  }
 
 }

  return (
    <>
      <Modal
        footer={null}
        style={{ padding: 10, borderRadius: "20px" }}
        visible={true}
        closable={false}
        width="95%"
        centered={true}
        className="tree-modal"
      >
        <div className="row space-between aligin-center">
        <div style={{width:"10px",height:"5px"}}></div>
        <h4 className="modal-heading">OKR Cascade</h4>
        <img src={close} alt="" className="close-icon" onClick={()=>{closeModal()}} />
        </div>
        <Row
          wrap={false}
          style={{ width: "100%", height: "80vh", background: "transparent" }}
        >
          <div
            style={{
              
              height: "100%",
              background: "transparent",
              display:"flex",
              flexDirection:"column",gap:"5px",
              justifyContent:"space-between"
            }}
            className="process-div"
          >
            <div style={{width:"10px",height:"10px"}}></div>
            <div className="process-section">
              <div style={{marginBottom:"40px",paddingLeft:"5px"}}>
              <h4 className="level-label">Levels <span>
              <Tooltip title="change level">
              <img src={InfoCircle} className="level-info" alt="" />
              </Tooltip>
              </span></h4>
              {/* <img src={dropdown_Arrow} alt="" style={{width:"13px",height:"10px"}}/> */}
              <Select
                showSearch={false}
                defaultValue={level}
                className="level-selector"
                suffixIcon={<img src={dropdown_Arrow} alt="" style={{marginTop:"-4px",width:"13px",height:"10px"}}/>}
                placeholder="Select Level"
                onChange={(value)=>changeLevel(value)}
              
                >
                
                  <Select.Option key={1} value={1} >1</Select.Option>
                  <Select.Option key={2} value={2} >2</Select.Option>

              </Select>
              </div>
              <div style={{display:"flex",flexDirection:"column",gap:"30px",width:"22px",alignItems:"center"}}>
                <img src={arrow_up} className="arrows" alt="" style={{cursor:"pointer"}} onClick={(e)=>{getNextLevelData(true,false)}} />
                <img src={tree_center} alt="" style={{ cursor: "pointer" }} className="recenter-icon" onClick={reCenter}/>
                <img src={arrow_down} className="arrows" alt="" style={{cursor:"pointer"}} onClick={(e)=>{getNextLevelData(false,true)}} />
              </div>
            </div>
            <div style={{width:"10px",height:"10px"}}></div>
          </div>

          {
            okrChart && !resetState ?
            <div id="treeWrapper" style={{ width: "100%", height: "100%" }}>
            <Tree
              key={treeId}
              data={okrChart}
              // enableLegacyTransitions={true}
               orientation="vertical"
              zoom={getinitialZoom()}
              renderCustomNodeElement={renderRectSvgNode}
              translate={{
                x: window?.innerWidth / 2 - 150,
                y: 100,
              }}
              separation={{
                siblings: 1,
                nonSiblings: 1,
              }}
              nodeSize={{
                x: 500,
                y: 350,
              }}
              collapsible={false}
              dimensions={getDimension()}
              // pathFunc={drawStepPath}
              pathClassFunc={getDynamicPathClass}
            />
                <Modal
                  footer={null}
                  style={{ padding: 10, borderRadius: "20px" }}
                  visible={isDetailsModelVisible}
                  closable={false}
                  maskClosable={true}
                  className="okr-child-modal"
                  // width="40%"
                  centered={true}
                  onCancel={onDetailsClose}
                  bodyStyle={{'minHeight' : '50vh'}}
                >
                  {spinDetails && (
                    <Row>
                      <Col span={24} style={{height:"50vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <Spin spinning={spinDetails}></Spin>
                      </Col>
                    </Row>
                  )}
                  {!spinDetails && (
                    <>
                      <h2 className="modal-title">{detailsType?.type}</h2>
                      
                      <div style={{width:"100%"}}>
                      <h4 className="d3-modal-sub-title">{`Under ${detailsData?.okrById?.title}`}</h4>
                      </div>
                      <div style={{width:"100%"}} className="okr-list-container" align="center">
                      {detailsData?.contributors?.edges?.map(({ node }, index) => (
                    
                    <div  className="container-div">
                      <div className="progress-div" justifyContent="end">
                      <h4 className="progress" >{node?.progress}%</h4>
                      </div>
                    <Tooltip title={node?.okr?.title}>
                    
                      <a href={`/role/objective-keyresult/${detailsType?.type==="MILESTONES"?'milestone':'keyresult'}/view/${getIntFromString(node?.okr?.id)}`} target="_blank" className={`okr-title-div ${detailsType?.type==="MILESTONES"?'mile-title-hover':'key-title-hover'}`} style={{background:detailsType?.type === "MILESTONES" ? "#FAC53C" : "#009AF1" }}>
                        <h4 className="title">{node?.okr?.title}</h4>
                        
                      </a>
                
                    
                    </Tooltip>
                      <div className="progress-div" style={{width:"fit-content"}}>
                        <img src={d3_arrow} alt="" />
                      </div>
                      <div className="progress-div">
                       <Tooltip title={loadTitle(node)}>
                       <div className="contributor-circle" style={{ verticalAlign: 'middle' }}>
                        <h4 className="node-name">{node?.employee?.user?.firstName?.charAt(0)?.toUpperCase()}{node?.employee?.user?.lastName?.charAt(0)?.toUpperCase()}</h4>
                        </div>
                       </Tooltip>
                      </div>
                    </div>
                      ))}
                      {!detailsData?.contributors?.edges?.length ?
                        <h4 style={{marginTop:"100px"}}>NO {detailsType?.type} FOUND</h4>:''
                      }
                      </div>
                    </>
                  )}
                  
                </Modal>
          </div>
          :
          <div style={{width:"100%",padding:"50px"}} align="center">
            <Spin spinning={true}></Spin>
          </div>
          }
        </Row>
      </Modal>
    </>
  );
};

export default ObjectiveTreeView;