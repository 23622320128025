// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import CreateConversationView from '../components/CreateConversationView';
import { SURVEY_PERMISSIONS } from '../graphql/surveyPermission.gql';
import { withApollo } from '@apollo/client/react/hoc';
import { GROW_SURVEY_IDENTIFIER } from '../../../config';
import GrowSurveySelectorContainer from '../container/SelectSurveyContainer'
import { Spin } from "antd";
import { ORG_SURVEY_BY_ID_OPTIMIZED } from 'modules/survey/graphql/OrgSurveyOptimized.gql';
import { getIntFromString, globalPermissionValidator } from 'modules/look';
import { withCreateActionItem, withCreateConversation } from './conversationOperation';
import { coaching_engagement_permission } from 'Permissions/coachingEngagement.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { withAddCustomUserCommitment } from 'modules/dashboard/containers/DashboardOperations';
import { withMe } from 'modules/user/containers/UserOperations';

const CreateConversation = props => {
  const { client, me, userPermission, permissionLoader, createCustomUserCommitment } = props
  const [surveyList, setsurveyList] = React.useState()
  const [surveyid, setsurveyid] = React.useState()
  const [permission, setPermission] = React.useState(true)
  const [identifier, setIdentifier] = React.useState()
  const [coachingPermitted, setCoachingPermitted] = React.useState(false)
  const [loading, setloading] = React.useState(true)
  const [orgSurveyById, setorgSurveyById] = React.useState()

  const getSurveyDeatils = async (identifier) => {
    setloading(true)
    const { data } = await client.query({
      query: SURVEY_PERMISSIONS,
      variables: { identifier: identifier },
    });
    if (data?.surveyPermission?.employee) {
      let Detail = data?.surveyPermission?.employee

      if (Detail?.organizationSet?.totalCount || Detail?.verticalMembersEmployee?.totalCount || Detail?.verticalMembersEmployee?.totalCount) {
        let surveyData = {}
        let orgSurvey = Detail?.organizationSet?.edges?.map((item) => {
          if (item?.node?.orgSurvey?.totalCount) {
            return {
              id: item?.node?.id,
              name: item?.node?.name,
              survey: item?.node?.orgSurvey
            }
          }

        })

        let publicSurvey = Detail?.organizationSet?.edges?.map((item) => {
          if (item?.node?.publicSurvey?.totalCount) {
            return {
              id: item?.node?.id,
              name: item?.node?.name,
              survey: item?.node?.publicSurvey
            }
          }

        })
        let teamMembers = Detail?.teamMembersEmployee?.edges?.map((item) => {
          if (item?.node?.surveySet?.totalCount) {
            return {
              id: item?.node?.id,
              name: item?.node?.name,
              survey: item?.node?.surveySet
            }
          }

        })
        let verticalMembers = Detail?.verticalMembersEmployee?.edges?.map((item) => {
          if (item?.node?.surveySet?.totalCount) {
            return {
              id: item?.node?.id,
              name: item?.node?.name,
              survey: item?.node?.surveySet
            }
          }

        })
        surveyData['orgSurvey'] = orgSurvey?.filter((item) => item)
        surveyData['publicSurvey'] = publicSurvey?.filter((item) => item)
        surveyData['teamMembers'] = teamMembers?.filter((item) => item)
        surveyData['verticalMembers'] = verticalMembers?.filter((item) => item)
        setsurveyList(surveyData)
        // console.log("surveyData",surveyData)
      } else {
        setPermission(false)
      }

    }
    setloading(false)
  }

  React.useEffect(() => {
    if (surveyList && coachingPermitted?.required_permission) {
      selectSurvey()
    }
  }, [surveyList, coachingPermitted])

  React.useEffect(() => {
    if (surveyid!==undefined && coachingPermitted?.required_permission) {
      setloading(true)
      getSurveyDetail(surveyid)
    }
    else{
      setloading(false)
    }
  }, [surveyid, coachingPermitted])

  const getSurveyDetail = async (id) => {
    const { data } = await client.query({
      query: ORG_SURVEY_BY_ID_OPTIMIZED,
      variables: { id: id },
      fetchPolicy:'network-only'
    });
    if (data?.orgSurveyById) {
      let filter = data?.orgSurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges?.filter((item) => item?.node?.questionAddress !== "continue")
      data.orgSurveyById.groupSet.edges[0].node.questionSet.edges = filter
      setorgSurveyById(data?.orgSurveyById)
    }
    setloading(false)
  }
  React.useEffect(() => {
    if (identifier) {
      setloading(false)
    }
  }, [identifier])

  const selectSurvey = () => {
    console.log("surveyList",surveyList)
    if (surveyList?.teamMembers?.length) {
      if (surveyList?.teamMembers?.length <= 1 && surveyList?.teamMembers[0]?.survey?.totalCount <= 1) {
        let survey = surveyList?.teamMembers[0]?.survey?.edges[0]

        if (survey) {
          setsurveyid(survey?.node?.id)
        }

      }
      else {
        setIdentifier({ sourceData: surveyList?.teamMembers, title: 'Grow Model', })
      }
      return
    } else if (surveyList?.verticalMembers?.length) {
      if (surveyList?.verticalMembers?.length <= 1 && surveyList?.verticalMembers[0]?.survey?.totalCount <= 1) {
        let survey = surveyList?.verticalMembers[0]?.survey?.edges[0]
        if (survey) {
          setsurveyid(survey?.node?.id)
        }
      }
      else {
        setIdentifier({ sourceData: surveyList?.verticalMembers, title: 'Grow Model' })
      }
      return
    } else if (surveyList?.orgSurvey?.length) {
      if (surveyList?.orgSurvey?.length <= 1 && surveyList?.orgSurvey[0]?.survey?.totalCount <= 1) {
        let survey = surveyList?.orgSurvey[0]?.survey?.edges[0]
        if (survey) {
          setsurveyid(survey?.node?.id)
        }
      }
      else {
        setIdentifier({ sourceData: surveyList?.orgSurvey, title: 'Grow Model' })
      }
      return
    } else if (surveyList?.publicSurvey?.length) {
      if (surveyList?.publicSurvey?.length <= 1 && surveyList?.publicSurvey[0]?.survey?.totalCount <= 1) {
        let survey = surveyList?.publicSurvey[0]?.survey?.edges[0]
        if (survey) {
          setsurveyid(survey?.node?.id)
        }
      }
      else {
        setIdentifier({ sourceData: surveyList?.publicSurvey, title: 'Grow Model' })
      }
      return
    }


  }

  React.useEffect(() => {
    if (coachingPermitted?.required_permission) {
      getSurveyDeatils(GROW_SURVEY_IDENTIFIER)
    }
  }, [coachingPermitted])
  

  React.useEffect(() => {
    if (me && userPermission) {
      let permission = globalPermissionValidator(coaching_engagement_permission, userPermission)
      setCoachingPermitted(permission)
    }
  }, [me, userPermission])

  const addCoacheeComm = async (title,toolId,description,userId,coachingId,dueDate) =>{
    // console.log('answers given by the coach root =::', title,toolId,description,userId,coachingId,dueDate)

    // let userIdInt = getIntFromString(userId)
    let coacheeCommAdded = await createCustomUserCommitment({customUserCommitmentData :{title,toolId:34,description,userId,coachingId,dueDate}})
    if (coacheeCommAdded){
      // console.log('the commitment is posted testing coacheeCommAdded', coacheeCommAdded,title,toolId,description,userIdInt)
    }
  }

  return (
    <>
      {
        !permissionLoader&&
        <div>
          {
            !coachingPermitted?.required_permission &&!loading || (surveyid==undefined&&!loading)?
            <NoPermissionView />:
              <Spin spinning={loading} >
                {
                  identifier && (
                    <GrowSurveySelectorContainer
                      identifier={identifier}
                      closeSelector={(e) => { setIdentifier(null) }}
                      NavigateToSurvey={(e) => {
                        setIdentifier(null);
                        setsurveyid(e)
                      }}
                    />
                  )
                }
                {
                  orgSurveyById && (
                    <CreateConversationView addCoacheeComm={addCoacheeComm} createCustomUserCommitment={createCustomUserCommitment} {...props} setloading={(e) => { setloading(e) }} orgSurveyById={orgSurveyById} ></CreateConversationView>
                  )
                }

              </Spin>
          } 
        </div>
      }


    </>
  );

};

export default compose(withApollo,withAddCustomUserCommitment,withMe, withCreateConversation, withCreateActionItem)(CreateConversation);
