// @ts-nocheck
import { Row } from "antd";
import { getIntFromString } from "modules/layout/Layout";
import React from "react";
import deleteContent from '../../assets/deleteContent.svg'

const GivenKudosEditFeedback = props => {
    const { givenKudos, setGivenKudos, deleteBadgeAward, fromGiveFeedback, feedbackById, newKudosAdded,deleteUpdateKudos,
         setGivenBadgeAward, buttonStyle, setNewKudosAdded} = props

    const handleGivenKudos = async () => {

        if (givenKudos && !newKudosAdded) {
            let res = await deleteBadgeAward({ badgeAwardId: getIntFromString(feedbackById?.badgeAward?.edges[0]?.node?.id) })
            if (res) {
                setGivenKudos();
                setNewKudosAdded(false)
                if(deleteUpdateKudos){
                    deleteUpdateKudos(true)
                }
            }
        } else if (givenKudos && newKudosAdded) {
            setGivenKudos()
        } else {
            setGivenKudos()
        }
        setGivenBadgeAward(undefined)
    }

    return (
        <>

            <img src={deleteContent} style={buttonStyle ? buttonStyle : {}} className="delete-given-kudos-button-edit-feedback" onClick={(e) => handleGivenKudos(e)} />
            {
                fromGiveFeedback ?
                    <img src={givenKudos?.image} className="given-kudos-container" />
                    :
                    <img src={givenKudos?.image} className="given-kudos-container" />
            }

        </>
    );
};

export default GivenKudosEditFeedback;

