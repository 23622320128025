// @ts-nocheck
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Row,Modal } from 'antd';
import { compose } from '../../../core';
import _ from 'lodash';
import { withApollo } from '@apollo/client/react/hoc';
import { SKILLS_SET } from '../../../readiness-level/graphql/SkillList.gql'
import SkillCustomDropdown from 'modules/grow-model/components/skillCustomDropDown';
import SubCompetencyAddContainer from '../../../role_definition/containers/AddSubCompetencyContainer'


const SkillField = props => {
    const { hide_skill_list, owner, client, form, onChange,no_label,closeAfterSelection,setSkillSet,skill_detail } = props;
    const [addSkillData, setaddSkillData] = React.useState()
    const handleFilterRemove = React.useRef(() => { });
    const [selected_skill, setSelectedSkill] = React.useState()
    const [skills, setSkills] = useState()
    const [chars, setChars] = useState("")

    React.useEffect(()=>{
        if(skill_detail?.id && skill_detail?.label){
            setSelectedSkill(skill_detail)
        }
    },[skill_detail])

    React.useEffect(() => {
        const getSkill = async () => {
            // Donot change anything in this query or donot add new filters here
            const { data } = await client.query({
                query: SKILLS_SET,
                variables: { title_Icontains: chars },
            });
            if(setSkillSet){
                setSkillSet(data?.allCompetencies)
            }
            setSkills(data?.allCompetencies?.edges?.map(({ node }) => ({
                label: node.title,
                value: node.id
            })));

        }

        if (owner !== undefined) {
            getSkill()
        }
    }, [chars, props.owner])

    const onSelectFeildSearch = (s) => {
        setChars(s)
    }

    const addSkill = (data) => {
        let skill_data = { label: data?.title, value: data?.id }
        let list = [skill_data].concat(skills)
        setSkills(list)
        setSelectedSkill(skill_data)
        onChange(skill_data)
        form.setFieldsValue(_.set(form.getFieldsValue(true), "skill", data?.id))
        setaddSkillData(false)
    }
    const onChangeSkill = (e) => {
        setSelectedSkill(e)
        onChange(e)
    }

    return (
        <>

            <Modal
                visible={addSkillData}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal cp-modal-max-width'
                width={"90%"}
            >
                <SubCompetencyAddContainer {...props} onCancel={() => setaddSkillData(false)} onSuccess={(res) => { addSkill(res) }} />
            </Modal>
            <Row >
                <div style={{ display: "flex", flexDirection: "column", gap: ".5em", width: "99%" }}>
                    {no_label?null:<h4 className='coaching-engagement-form-lable'><span className="rd-input-star">*</span> Select skill</h4>}
                    <SkillCustomDropdown
                        selected_label={selected_skill?.label}
                        onSelect={(e) => { onChangeSkill(e)}}
                        onSearch={(e) => { onSelectFeildSearch(e) }}
                        placeholder={'Select skill'}
                        choice_list={hide_skill_list?.length?skills?.filter(i=>!hide_skill_list?.includes(i?.value)):skills}
                        add_btn='Add Skill'
                        on_add={(e) => { setaddSkillData(true) }}
                        remove_add={true}
                        customCss={true}
                        closeAfterSelection={closeAfterSelection}
                        id={skill_detail?.id || Math.random()}
                        name={skill_detail?.id || Math.random()}
                        {...props}
                    />

                </div>
            </Row>
        </>
    );
};

export default compose(withApollo)(SkillField);
