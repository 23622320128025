import gql from 'graphql-tag';

export const ORG_SURVEY_LIST = gql`
  query allOrgSurveysByImplicitOrgId($last: Int,$first: Int,$user: ID,$listing: Boolean) {
    allOrgSurveysByImplicitOrgId(first:$first,listing:$listing){
      pageInfo{
        hasNextPage
        endCursor
      }
      totalCount
        edges{
          node{
            id
            name
            description
            multisourceResponse
            logo
            reportAvailable
            responseSet(last:$last,user:$user){
              edges{
                node{
                  id
                }
              }
            }
          }
        }
      }
  }
`;
export const GET_SURVEY_BY_ID = gql`
query SurveyById ($id:ID!){
  SurveyById (id:$id){
    id
    name
  }
}
`