import { Button, Checkbox, Col, Dropdown, Form, Input, Menu, Modal, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import white_back_icon from '../../assets/white_back_icon.svg'
import email_icon from '../../assets/email.svg'
import '../css/SalesPageStyles.css'
import svg_log from '../../assets/new-map-logo.svg'
import up_arrow from '../../assets/up-arrow-advance-options.svg'
import user_name from '../../assets/user_name.svg'
import get_in_touch_2 from '../../assets/get_in_touch_2.png'
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import ThankyouView from './ThankYouView';

const GetInTouchView = props => {

    const { contactRequest } = props

    const history = useHistory()

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [contactNumber, setContactNumber] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [contactType, setContactType] = React.useState("");
    const [teamSize, setTeamSize] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false)
    const [selectedCountryCode, setSelectedCountryCode] = React.useState(91);
    const [selectedShort, setSelectedShort] = React.useState("IN");
    const [submissionDisabled, setSubmissionDisabled] = React.useState(true)
    const [invalidContactNumber, setInvalidContactNumber] = React.useState(false)
    const [invalidEmail, setInvalidEmail] = React.useState(false)
    const [invalidTeamSize, setInvalidTeamSize] = React.useState(false)
    const [advancedOptions, setAdvancedOptions] = React.useState(true)
    const [thankYouModalVisible, setThankYouModalVisible] = React.useState(false);
    const [isArrowClicked, setIsArrowClicked] = useState(false)
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState()

    const [form] = Form.useForm();


    let validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


    const handleFormSubmit = () => {

        let inValidInput = checkValidityOfInput()
        if (!inValidInput) {
            setLoading(true)
            const response = contactRequest({
                name: name,
                email: email,
                mobileNumber: "+" + selectedCountryCode + contactNumber,
                contactType: contactType,
                teamSize: teamSize ? teamSize : "",
                message: message
            })

            if (response) {
                setLoading(false)
                setName("")
                setContactNumber("")
                setEmail("")
                setTeamSize('');
                setMessage("")
                setContactType('')
                setInvalidContactNumber(false)
                setInvalidEmail(false)
                setInvalidTeamSize(false)
                setSubmissionDisabled(true)
                setThankYouModalVisible(true)
            }
        }

    }


    const checkValidityOfInput = () => {

        var pattern = new RegExp(/^[0-9\b]+$/);
        let validContactNumber = pattern.test(contactNumber)


        if (validContactNumber && contactNumber.length <= 10) {
            setInvalidContactNumber(false)
        } else {
            setInvalidContactNumber(true)
        }

        let inValidEmail = !validEmailRegex.test(email);
        setInvalidEmail(inValidEmail)

        return inValidEmail || !(validContactNumber && contactNumber.length <= 10)

    }


    const stateOfCheckBox = (e, type) => {

        setContactType(type)
    }

    const handleCheckBoxChange = (e, type) => {
        switch (type) {
            case "Manager":
                stateOfCheckBox(e, type)
                break;
            case "Individual":
                if (teamSize) {
                    setTeamSize(undefined);
                }
                stateOfCheckBox(e, type)
                break;
            default:
                break;
        }


    }

    console.log('contact type one ::', contactType)
    const checkDiablingOnContactType = () => {

        if (contactType === "Manager") {
            if (name && email && contactNumber && contactType && message && teamSize && selectedCountryCode) {
                setSubmissionDisabled(false)
            } else {
                setSubmissionDisabled(true)
            }
        } else if (contactType === "Individual") {
            if (name && email && contactNumber && contactType && message && selectedCountryCode) {
                setSubmissionDisabled(false)
            } else {
                setSubmissionDisabled(true)
            }
        }
    }


    React.useEffect(() => {

        if (contactType) {
            checkDiablingOnContactType()
            if (name && email && contactNumber && message && selectedCountryCode) {
                setSubmissionDisabled(false)
            } else {
                setSubmissionDisabled(true)
            }
        } else {
            setSubmissionDisabled(true)
        }


        if (invalidContactNumber || invalidEmail) {
            checkValidityOfInput()
        }

    }, [name, email, contactNumber, contactType, message, teamSize])



    const TeamSizes = ["5 - 25", "26 - 50", "51 - 100", "101 - 300", "301+"];


    const TeamSizeOptions = () => (
        <>
            <Menu className='team-size-options-menu-item' style={{ borderRight: 'none' }}>
                {
                    TeamSizes?.map((size) =>
                        <div className="team-size-menu-element" onClick={(e) => setTeamSize(size)} >{`${size} People`}</div>
                    )
                }
            </Menu>
        </>
    );



    React.useEffect(() => {

        let element = document.getElementById('get-in-touch-page-sales')
        if (element) {
            element.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' })
        }
    }, [])

    const tackleArrow = (e) => {
        setContactType("")
        e.preventDefault()
        setAdvancedOptions(!advancedOptions)
        setIsArrowClicked(!isArrowClicked)
    }

    return (
        <>
            <Spin spinning={loading}>
                <Row id='get-in-touch-page-sales' className='poppins get-in-touch-image-info-wrapper'  >

                    {/* Image container */}
                    <Col sm={24} xs={24} md={10} lg={8}>
                        <img className='get-in-touch-sales' src={get_in_touch_2} />
                        <Row justify='start' className='text-content-get-in-touch' >

                            {/* top container */}

                            <Row align='top' justify='start' style={{ flexDirection: 'column', rowGap: '20px' }}>
                                <Row align='middle' justify='start' style={{ cursor: 'pointer' }} onClick={(e) => history.goBack()}>
                                    <img className="back-icon-get-in-touch" src={white_back_icon} />
                                    <div className="info-text-get-in-touch">Back to site</div>
                                </Row>
                                <img src={svg_log} />
                            </Row>

                            {/* bottom contact info container */}
                            <div className='info-container-get-in-touch'>
                                <div className="info-text-get-in-touch contact-label-get-in-touch">Address</div>
                                <div className="info-text-get-in-touch">D-606, Solitaire co-operative Society,
                                    Dhanori-Lohegao road, Dhanori, Pune-411015, Maharashtra, India</div>
                                <div className="info-text-get-in-touch contact-label-get-in-touch">Let's Talk</div>
                                <div className="info-text-get-in-touch">+91 9920917193</div>
                                <div className="info-text-get-in-touch contact-label-get-in-touch">General Support</div>
                                <div className="info-text-get-in-touch">info@brain-ayan.com</div>
                            </div>
                        </Row>
                    </Col>


                    {/* Form container column */}
                    <Col sm={22} xs={22} md={9} lg={9} className="form-col-get-in-touch" style={{ paddingTop: '0px', paddingBottom: '80px', margin: '2em auto auto auto' }}>


                        <div className='form-header-get-in-touch'>Let's Connect!<br></br>
                            We're Eager to Hear from You!</div>
                        <div className='messgae-tag-get-in-touch'>Leave us a message and we'll get back to you within 24 hours!</div>

                        <Form className='input-form-get-in-touch' form={form} onFinish={(e) => handleFormSubmit()}>
                            <p className='form-label-get-in-touch'>Full name</p>
                            <Input autoComplete='off' value={name} suffix={<img src={user_name} />} onChange={(e) => setName(e.target.value)} placeholder='Full Name' className='input-box-get-in-touch' />

                            <p className='form-label-get-in-touch'>Email</p>
                            <Input autoComplete='off' value={email} suffix={<img src={email_icon} />} placeholder='Email address' onChange={(e) => setEmail(e.target.value)}
                                className='input-box-get-in-touch' style={{ border: invalidEmail ? '1px solid red' : 'none' }} />
                            {
                                invalidEmail &&
                                <p style={{ color: 'red', fontSize: '12px', marginTop: '-12px' }}>Please enter a valid input</p>
                            }

                            <div className='contact-number-container' style={{ marginBottom: '15px' }}>
                                <p className='form-label-get-in-touch'>Phone number</p>
                                <ConfigProvider locale={en}><CountryPhoneInput value={{ phone: contactNumber, code: selectedCountryCode, short: selectedShort }} onChange={(e) => { setSelectedCountryCode(e.code); setContactNumber(e.phone); setSelectedShort(e.short) }}
                                    style={{ border: invalidContactNumber ? '1px solid red' : 'none', cursor: 'pointer' }} /></ConfigProvider>
                                {
                                    invalidContactNumber &&
                                    <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>Please enter a valid input</p>
                                }
                            </div>

                            <p className='form-label-get-in-touch'>Your message</p>
                            <textarea value={message} onChange={(e) => setMessage(e.target.value)} className='input-box-get-in-touch textarea-get-in-touch your-msg-box' />

                            <div className='advanced-option-container'>
                                <p className='form-label-get-in-touch'>Are you a Manager or Individual contributor ?</p>
                                <Row style={{ gap: '10px', marginBottom: '1.2em' }} className='checkbox-panel-get-in-touch'>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <Row style={{cursor:'pointer'}}  className={`checkboxes-get-in-touch ${contactType === "Manager" ? "green" : ""} `} wrap={false} align='middle' justify='center' onClick={(e) => handleCheckBoxChange(e, "Manager")}>
                                            <Checkbox className='pointer' style={{ visibility: 'hidden' }}></Checkbox>
                                            <div className={`checkbox-choice-get-in-touch ${contactType === "Manager" ? "gray" : ""} `}>Manager</div>
                                            <Checkbox className='pointer' style={{ visibility: 'hidden' }}></Checkbox>

                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <Row style={{cursor:'pointer'}}  className={`checkboxes-get-in-touch ${contactType === "Individual" ? "green" : ""} `} wrap={false} onClick={(e) => handleCheckBoxChange(e, "Individual")}>
                                            <Checkbox  style={{ visibility: 'hidden' }}></Checkbox>

                                            <div className={`checkbox-choice-get-in-touch ${contactType === "Individual" ? "gray" : ""} `}>Individual Contributor</div>
                                            <Checkbox  style={{ visibility: 'hidden' }}></Checkbox>
                                        </Row>
                                    </Col>
                                </Row>

                                {
                                    contactType === "Manager" &&
                                    <div style={{marginBottom:'1em'}}>
                                        <p className='form-label-get-in-touch' >Team Size</p>
                                        <Dropdown className="team-size-dropdown-container" overlay={TeamSizeOptions} placement={"bottomCenter"}  >
                                            <Button className='team-size-drop' style={{ width: '100%', background: '#ECF1F4', border: 'none', borderRadius: '8px', height: '40px' }}>
                                                <div className='poppins sales-page-link selected-team-size' style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', fontSize: "14px" }} >{teamSize ? `${teamSize} People` : 'Select team size'}</div>
                                            </Button>
                                        </Dropdown>
                                    </div>

                                }
                                {
                                    invalidTeamSize &&
                                    <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>Please enter a valid input</p>
                                }
                            </div>



                            <Button htmlType="submit" disabled={submissionDisabled} className={submissionDisabled ? 'send-msg-button send-msg-button-disbaled' : 'send-msg-button send-msg-button-enabled'} >Send Message</Button>
                        </Form>

                    </Col>
                </Row>
            </Spin>


            {/* popup shown after submitting the form */}
            <div >
                <Modal visible={thankYouModalVisible}
                    footer={null}
                    centered
                    width={850}
                    className='thank-you-modal'
                    destroyOnClose={true}
                    onCancel={(e) => setThankYouModalVisible(false)}>
                    <ThankyouView />
                </Modal>
            </div>
        </>
    );
};

export default GetInTouchView;


