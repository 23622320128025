import { Col,Row} from "antd";
import React from "react";
import OKRTableContainer from "../container/OKRTableContainer";
import OKRVerticalBar from "./OkrVerticalBar";
import '../OkrStyles/OkrHome.css'
import { MetaTags } from "modules/look";
const OKRHome = (props) => {


  return (
    <div className="rd-root-style">
      <MetaTags title="OKR" description="OKR Home" />
      <OKRVerticalBar {...props} current_tab={
         {
          key: 'home',
          label: 'Objective key results',
        }
      }/>
      <Row className="okr-table-gap">
   
        <Col span={24}>
          <Col className='okr-tab'>
            <Col className="tab-inner" style={{ marginTop: "33px", minHeight: "550px" }}>

              <OKRTableContainer {...props} />
            </Col>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default OKRHome

