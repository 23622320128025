import React, {useEffect, useState} from 'react';
import NewFeedbackHome from '../components/NewFeedbackHome';

// imports for permissions 
import { globalPermissionValidator } from 'modules/look';
import { feedback_permission } from 'Permissions/feedback.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { compose } from 'modules/core';
import { withCreateFeedback } from './feedbackOperations';

import { withCreateBadgeAward } from 'modules/kudos-user/container/badgeAwardOperationFeedback';


const FeedbackContainer = props => {

  const { me, userPermission, permissionLoader } = props;

    // state to store permissions data
    const [feedbackPermission, setFeedbackPermission] = useState()

    // code to setup the permissions validator
    useEffect(() => {
      if (me && userPermission) {
        let permission = globalPermissionValidator(feedback_permission, userPermission);
        // @ts-ignore
        setFeedbackPermission(permission)
      }
    }, [me, userPermission])
  
    
 
    return (
      // <>
      //   <NewFeedbackHome {...props}></NewFeedbackHome>
      // </>
            <>
            {!permissionLoader ? <div>
              { feedbackPermission?.requried_permission ? <NewFeedbackHome feedbackPermission={feedbackPermission} {...props} ></NewFeedbackHome> : <NoPermissionView />}
              </div> : null}
    
          </>
    );
  }




export default compose(withCreateFeedback,withCreateBadgeAward) (FeedbackContainer);
