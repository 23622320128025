import gql from "graphql-tag";

export const EMPLOYEE_PROFILE_DETAIL = gql`
query employeeDetail($id: ID!){
    profile_detail:employeeById(id: $id) {
        id
        user{
            id
            firstName
            lastName
            profile{
              id
              profileImage
            }
          }
        memberSet(role_Isnull: false, last: 1) {
          edges {
            node {
              id
              role {
                id
                title
                employeeLevel {
                  id
                  name
                }
                jobfamilySet {
                  edges {
                    node {
                      id
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`

export const EMPLOYEE_SURVEY_DETAIL = gql`
query employeeSurvey($user:ID,$personality_survey: ID,$strength_survey: ID,$value_survey: ID,$personality_questions:[ID],$diversity_survey: ID,$diversity_questions:[ID]){
    personality: surveyResponses(user: $user, survey: $personality_survey) {
        edges {
          node {
            id
            answerSet(question_In:$personality_questions) {
              edges {
                node {
                  id
                  answer
                  sequence
                  question{
                    id
                  }
                }
              }
            }
          }
        }
      }

      strength: surveyResponses(user: $user, survey: $strength_survey,last:1) {
        edges {
          node {
            id
            answerSet(first:3) {
              edges {
                node {
                  id
                  answer
                }
              }
            }
          }
        }
      }

      value: surveyResponses(user: $user, survey: $value_survey,last:1) {
        edges {
          node {
            id
            answerSet (first:3){
              edges {
                node {
                  id
                  answer
                }
              }
            }
          }
        }
      }

      diversity: surveyResponses(user: $user, survey: $diversity_survey,last:1) {
        edges {
          node {
            id
            answerSet(question_In:$diversity_questions) {
              edges {
                node {
                  id
                  question{
                    id
                  }
                  answer
                }
              }
            }
          }
        }
      }
}
`

export const EMPLOYEE_OKR_DETAIL = gql`
query contributors($first:Int,$okr_Duedate_Range: [DateTime],$tz: String,$createdBy: ID){
  AllCascadingObjectives:contributors(role_In: ["COLLABORATOR", "ASSIGNED"],first:$first,orderBy:["-created_at"],okr_Duedate_Range:$okr_Duedate_Range,employee: $createdBy,createdBy_Ne:$createdBy,requestStatus: "ACCEPTED"){
    pageInfo{
      hasNextPage
      endCursor
    }
    totalCount
    edges{
      node{
        id
        role
        okrType
        progress
        okr{
          id
          title(tz:$tz)
          duedate
          startdate
          discardRequest(status_Iexact:"PENDING"){
            totalCount
           }
          isDiscarded{
            id   
            status
          }
        }
      }
    }
  }

  AllNonCascadingObjectives:contributors(role_In: ["OWNER"], createdBy:$createdBy, okr_Parent_Isnull:true,first:$first,orderBy:["-created_at"],okr_Duedate_Range:$okr_Duedate_Range){
    pageInfo{
      hasNextPage
      endCursor
    }
    totalCount
    edges{
      node{
        id
        role
        okrType
        progress
        okr{
          id
          title(tz:$tz)
          duedate
          startdate
          discardRequest(status_Iexact:"PENDING"){
            totalCount
           }
          isDiscarded{
            id   
            status
          }
        }
      }
    }
  }
}
`

export const ORG_SURVEY_LIST_EMPLOYEE = gql`
  query allOrgSurveysByImplicitOrgId($last: Int,$first: Int,$user: ID) {
    allOrgSurveysByImplicitOrgId(first:$first){
      pageInfo{
        hasNextPage
        endCursor
      }
      totalCount
        edges{
          node{
            id
            name
            responseSet(last:$last,user:$user){
              edges{
                node{
                  id
                  responseDate
                }
              }
            }
          }
        }
      }
  }
`;

export const EMPLOYEE_IMAPACT_NARRATIVE = gql`
query allImpactNarrative($user: ID){
    allImpactNarrative(user:$user,last:1,orderBy:["createdAt"]){
        edges{
            node{
              id
              overview
            }
          }
    }
   
  }`

export const EMPLOYEE_ASSESSMENT = gql`
query assessments($employee: ID){
  assessments(employee: $employee,last:1) {
    edges {
      node {
        id
        role {
          id
          title
          requiredSpecificCompetency {
            edges {
              node {
                id
                competencyCategory {
                  id
                }
              }
            }
          }
          optionalSpecificCompetency {
            edges {
              node {
                id
                competencyCategory {
                  id
                }
              }
            }
          }
          jobfamilySet {
            edges {
              node {
                id
                title
                genericCompetency {
                  edges {
                    node {
                      id
                      competencyCategory {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        competencyassessmentSet {
          edges {
            node {
              id
              competency {
                id
                competencyType
              }
              mainCompetency {
                id
                competencyType
              }
              assessmentbarsSet(selected: true) {
                edges {
                  node {
                    id
                    qualifyingLevelName
                    qualifyingLevelSequence
                    qualifyingLevelId
  
                    selected
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }`

  export const EMPLOYEE_KUDOS = gql`
query badgeAwards($recipient: ID,$first:Int){
  badgeAwards(recipient: $recipient,first:$first) {
    pageInfo{
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        id
        badge {
          id
          title
          image
        }
        createdAt
    		expirationDate 
        issuer {
          id
          user {
            id
            firstName
          }
        }
      }
    }
  }
  }`

  export const EMPLOYEE_PROFILE_TEAM = gql`
  query Team($id: ID!) {
    Team(id: $id) {
      id
      name
      employees {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;